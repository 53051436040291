<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         modalContentClasses="card"
         headerClasses="card-header">

      <div slot="header">
        <!-- <i class="fas fa-users fa-3x"></i>-->
        <h5 class="card-category">{{Categoria}}</h5>
        <h3 class="card-title">{{Titulo}}</h3>
        <span class="text-muted">{{Ayuda}}</span>
      </div>


      <template>

        <ValidationObserver v-slot="{ handleSubmit}">
          <form @submit.prevent="handleSubmit(nuevo)">
            <MazInput v-model="datos.etiqueta"
                      placeholder="Etiqueta"
                      :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                      class="maz-mb-3" required :loading="loading || cargando"
                      clearable>
              <template v-slot:icon-left>
                <i class="fas fa-tag"></i>
              </template>
            </MazInput>
            <MazInput v-model="datos.descripcion"
                      placeholder="Comentarios" :loading="loading || cargando"
                      :autocomplete="autocompleteComentario" :disabled="loading || cargando"
                      left-icon-name="comment"
                      textarea />


            <hr class="my-md-4" />
            <base-button v-if="!cargando" native-type="submit" slot="footer" type="primary" block>
              Registrar
            </base-button>

            <div class="text-muted text-center">

              <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
                {{ Erroneo }}
              </p>
              <div v-if="cargando" class="text-center mt-7">
                <b-spinner type="grow" label="cargando..."></b-spinner>
                Cargando...
              </div>
              <a href="#" class="d-block mt-2" @click="Cerrar()">
                <i class="fas fa-chevron-left"> </i> Cancelar
              </a>

            </div>
          </form>
        </ValidationObserver>
      </template>

  </modal>
</template>

<script>
  import Servicio from '@/api-services/tiposVisitas.service';

  export default {
    name: 'Ubicaciones-Nuevo',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Tipo de Visita' },
      Titulo: { type: String, default: 'Nuevo' },
      Ayuda: { type: String, default: 'Las ubicaciones son lugares donde se esta realizando ciertos trabajos, como es el caso de las sucursales.' },

      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {
          etiqueta: '',
          comentarios: '',
          esSucursal: true,
          confirmada: true,
        },

        Modal: false,
        cargando: false,

        Erroneo:''
      }
    },
    computed: {
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },

    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() { this.Modal = this.Mostrar;

    },
    created() {
    },
    methods: {
      nuevo() {
        Servicio.post(this, this.datos, (response) => {
          this.$emit('guardado', response.data);
         
          this.Cerrar();

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        this.datos = {
          etiqueta: '',
          descripcion: '',
          esSucursal: true,
          confirmada: true,
        };
        this.Modal = false;
      },
    }
  }
</script>

<style scoped>
</style>
