<template>
  <div class="d-flex justify-content-center">

    <div class="col-md-7 col-xl-5  px-0">
      <AuthGrupoComp  @guardado="OnGuardadoUsuario" IniciarSesion="Ya tengo una cuenta" paso="Autentificacion" :authLinkID="authLinkID" :confirmacionLinkID="confirmacionLinkID" />

    </div>

  </div>
</template>
<script>



  import Usuarios from '@/api-base/usuarios.service';

  import AuthGrupoComp from './Auth.grupo.vue';

  export default {
    data() {
      return {
        authLinkID: '',
        confirmacionLinkID: ''

      };
    },
    components: {
      AuthGrupoComp,
    },
    //https://es.vuejs.org/v2/guide/instance.html
    created: function () {
      // `this` hace referencia a la instancia vm
      console.debug('from es: ' + this.$route.query.from)
      if (this.$route.query.from === undefined)
        Usuarios.cerrarSesion();


      if (this.$route.query.authLinkID != null)
        this.authLinkID = this.$route.query.authLinkID;
      if (this.$route.params.authLinkID != null)
        this.authLinkID = this.$route.params.authLinkID;

      if (this.$route.query.confirmacionLinkID != null)
        this.confirmacionLinkID = this.$route.query.confirmacionLinkID;
      if (this.$route.params.confirmacionLinkID != null)
        this.confirmacionLinkID = this.$route.params.confirmacionLinkID;


      var Ubicacion = window.location.href;
      if (Ubicacion.indexOf("localhost") < 0 && Ubicacion.indexOf("192.") < 0
        && Ubicacion.indexOf("10.") < 0) {
        var NUrl = Ubicacion.replace("http://", "https://");
        if (NUrl != Ubicacion)
          window.location.href = NUrl;
      }
    },
    methods: {
      OnGuardadoUsuario(Parametros) {
        console.debug(Parametros);

        if (Parametros.Estado != 'Nuevo') {
          this.$router.replace(Parametros.Ruta);
          return;
        }
        //Que hacer si crea aquí un usuario???
        // this.Paso = 'Suscripcion';
      },
    },
    computed: {


    },
  };
</script>

