<template>
  <div>
    <div class="header  pb-8 pt-5 pt-lg-8 d-flex align-items-center">

      <span class="mask  " style="min-height: 600px; background-image: url(img/theme/profile-cover.jpg); background-size: cover; background-position: center top;"></span>
      <!-- Mask -->

      <span class="mask bg-gradient-success opacity-8" ></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row w-100">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white mt-5">Hola {{Nombre}}</h1>
            <p class="text-white mt-0 mb-4">Estas en la App para control de visitantes en la nube de eClock</p>

          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="container-fluid mt--7">
      <div v-for="suscripcionUsuario in Datos.usuario.suscripcionesUsuario" v-bind:key="suscripcionUsuario.suscripcionUsuarioID"
           class="row">

          <div v-if="Datos.usuario.suscripcionesUsuario.indexOf(suscripcionUsuario)==0" class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
            <UsuariosPerfilUsuario :Usuario="Datos.usuario"/>
          </div>
          <div 
               class="col-xl-8 order-xl-1">

            <UsuariosPerfilSuscripcion :suscripcionUsuario="suscripcionUsuario" />


          </div>
        </div>
      </div>

    </div>
</template>
<script>
  import Usuarios from '@/api-base/usuarios.service';


  import UsuariosPerfilSuscripcion from '@/Vistas-base/Usuarios/Perfil/Suscripcion.comp.vue';
  import UsuariosPerfilUsuario from '@/Vistas-base/Usuarios/Perfil/Usuario.comp.vue';
  export default {
    name: 'Perfil',
    data() {
      return {
        Datos: null,
        loading: true,
        errored: false,

        Nombre: '',
        Correo: '',
        Telefono: ''
      }
    },

    components: {

      UsuariosPerfilSuscripcion,
      UsuariosPerfilUsuario
    },

    mounted() {
      try {
        this.error = false;
        Usuarios.getPerfil().then((response) => {
          this.loading = false;
          console.debug(response.data);
          this.Datos = response.data;
          this.Correo = this.Datos.usuario.correo;
          this.Telefono = this.Datos.usuario.telefono;
          this.Nombre = response.data.usuario.nombre;
          if (response.data.usuario.urlFotoMiniatura != null && response.data.usuario.urlFotoMiniatura != "")
            this.FotoURL = response.data.usuario.urlFotoMiniatura;
        }).catch((error) => {
          this.loading = false;
          this.error = true;
          if (error.response.data.error === undefined) {

            console.error(error);
            alert("Error desconocido, intente nuevamente");
          }
          else {

            console.error(error.response.data.error);
            alert(error.response.data.error.mensaje);
          }
        });
      }
      catch (error) {
        this.loading = false;
        this.error = true;
        alert('Error desconocido, intente nuevamente');
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
    }
  };
</script>
<style>
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
  }
  .header-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
  }
  .bg-gradient-success {
    background: linear-gradient(87deg,#2dce89,#2dcecc) !important;
  }
</style>
