<template>
  <modal :show.sync="Modal"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
    <card type="secondary" shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">

      <template>
        <div class="text-center text-muted ">

          <h1>{{Titulo}}</h1>


        </div>
        <form role="form">
          <div v-if="MostrarNombre">
            <small v-if="nombre!=''" class="text-muted">Nombre Completo</small>
            <base-input class="input-group-alternative mb-3"
                        placeholder="Nombre Completo"
                        addon-left-icon="ni ni-hat-3"
                        v-model.trim="nombre" :valid="$v.nombre.required && $v.nombre.minLength">
            </base-input>

          </div>
          <div v-if="MostrarCorreo">
            <small class="text-muted">Correo Electrónico</small>
            <base-input class="input-group-alternative mb-3"
                        placeholder="ej. tucorreo@mail.com"
                        addon-left-icon="ni ni-email-83" type="email"
                        v-model="correo" :valid="$v.correo.required && $v.correo.minLength">
            </base-input>
          </div>
          <div v-if="MostrarTelefono">
            <small class="text-muted">Número telefonico</small>
            <base-input class="input-group-alternative mb-3"
                        placeholder="ej. 5524541600"
                        addon-left-icon="fa fa-mobile "
                        v-model="telefono" :valid="$v.telefono.required && $v.telefono.minLength">
            </base-input>
          </div>
          <div v-if="MostrarClave">
            <small v-if="passwordOld!=''" class="text-muted">Contraseña Anterior</small>
            <base-input class="input-group-alternative"
                        placeholder="Contraseña Anterior"
                        type="password"
                        addon-left-icon="ni ni-lock-circle-open"
                        v-model="passwordOld" :valid="$v.passwordOld.required && $v.passwordOld.minLength">
            </base-input>

            <small v-if="password!=''" class="text-muted">Contraseña</small>
            <base-input class="input-group-alternative"
                        placeholder="Contraseña"
                        type="password"
                        addon-left-icon="ni ni-lock-circle-open"
                        v-model="password" :valid="$v.password.required && $v.password.minLength">
            </base-input>

            <small v-if="confirmacion!=''" class="text-muted">Confirmación de contraseña</small>
            <base-input class="input-group-alternative"
                        placeholder="Confirmación de contraseña"
                        type="password"
                        addon-left-icon="ni ni-lock-circle-open"
                        v-model="confirmacion">
            </base-input>
          </div>
          <base-alert type="danger" v-if="!$v.confirmacion.sameAsPassword">
            <strong>Requerido</strong> No coinciden la contraseña y la confirmación
          </base-alert>
          <div class="text-center">
            <b-spinner v-if="Guardando" type="grow" label="Loading..."></b-spinner>
            <base-button v-else v-on:click="Guardar" class="my--2" :disabled="Guardando">
              <i class="fas fa-save mr-2"></i>
              Guardar
            </base-button>
            <div>
              <a @click="Modal = false" href="#" :disabled="Guardando">Cancelar</a>

            </div>
          </div>
        </form>
      </template>
    </card>
  </modal>
</template>
<script>
  //import LiAuth from '@/api-base/liauth.service';
  import Usuarios from '@/api-base/usuarios.service';
  import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
  export default {
    name: 'UsuariosEditarLiAuth',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      MostrarNombre: {
        type: Boolean,
        default: true,
        description: "Indica si se permitirá editar el nombre"
      },
      MostrarCorreo: {
        type: Boolean,
        default: true,
        description: "Indica si se permitirá editar el correo"
      },
      MostrarClave: {
        type: Boolean,
        default: true,
        description: "Indica si se permitirá editar el contraseña"
      },
      MostrarTelefono: {
        type: Boolean,
        default: true,
        description: "Indica si se permitirá editar el teléfono"
      },
      Titulo: {

        type: String,
        default: "Edición de datos del usuario",
        description: "titulo del mensaje"
      },
    },
    validations: {
      nombre: {
        required,
        minLength: minLength(7)
      },
      correo: {
        required,
        minLength: minLength(6)
      },
      passwordOld: {
        required,
        minLength: minLength(6)
      },
      password: {
        required,
        minLength: minLength(6)
      },
      telefono: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      confirmacion: {
        sameAsPassword: sameAs('password')
      }
    },
    data() {
      return {
        Modal: false,
        nombre: '',
        correo: '',
        telefono: '',
        passwordOld: '',
        password: '',
        confirmacion: '',
        acuerdo: false,
        Guardando: false,
      }
    },
    watch: {
      Mostrar(Mostrar) {
        if (Mostrar) {

          this.nombre = Usuarios.PerfilLocal.usuario.nombre;
          this.correo = Usuarios.PerfilLocal.usuario.correo;
          this.telefono = Usuarios.PerfilLocal.usuario.telefono;
          this.Modal = Mostrar;
        }
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    computed: {


    },
    filters: {


    },
    mounted() {

    },

    methods: {
      Guardar() {
        var Datos = {};
        if (this.MostrarNombre)
          if (this.$v.nombre.invalid) {
            this.$notify({ type: 'warning', title: 'Se requiere nombre completo' });
            return;
          }
          else
            Datos.nombre = this.nombre;
        if (this.MostrarCorreo)
          if (this.$v.correo.invalid) {
            this.$notify({ type: 'warning', title: 'Se requiere correo electrónico' });
            return;
          }
          else
            Datos.correo = this.correo;
        if (this.MostrarTelefono)
          if (this.$v.telefono.invalid) {
            this.$notify({ type: 'warning', title: 'Se requiere número teléfonico' });
            return;
          }
          else
            Datos.telefono = this.telefono;
        if (this.MostrarClave) {
          if (this.$v.passwordOld.validated) {
            this.$notify({ type: 'warning', title: 'Se requiere la contraseña anterior' });
            return;
          }
          if (this.$v.password.invalid) {
            this.$notify({ type: 'warning', title: 'Se requiere la nueva contraseña ' });
            return;
          }
          if (this.$v.confirmacion.invalid) {
            this.$notify({ type: 'warning', title: 'Se requiere la confirmación de la contraseña' });
            return;
          }
          if (!this.$v.confirmacion.sameAsPassword) {
            this.$notify({ type: 'warning', title: 'No coincide la nueva contraseña con la confirmación' });
            return;
          }
          //!$v.confirmacion.sameAsPassword
          Datos.passwordNuevo = this.password;
          Datos.passwordAnterior = this.passwordOld;
        }

        this.Guardando = true;
        Usuarios.put(Datos).then((response) => {
          this.Guardando = false;
          var Usuario = response.data;
          console.error(response.data);
          Usuarios.PerfilLocal.usuario.nombre = Usuario.nombre;
          Usuarios.PerfilLocal.usuario.correo = Usuario.correo;
          Usuarios.PerfilLocal.usuario.telefono = Usuario.telefono;
          this.Modal = false;
          this.$notify({ type: 'success', title: 'Guardado' });
          this.$emit('guardado', Usuario);


        }).catch((error) => {
          this.Guardando = false;
          console.error(error.response);
          this.errored = true;
          if (error.response != undefined && error.response.data != undefined && error.response.data.error != undefined && error.response.data.error.mensaje != undefined)
            this.$notify({ type: 'danger', title: error.response.data.error.mensaje });
          else
            this.$notify({ type: 'danger', title: 'no se pudo guardar la información, intente nuevamente' });
          this.$emit('error', error);

          //alert(error.response.data.error.mensaje);
        });
      },
    }
  }
</script>
<style>
</style>
