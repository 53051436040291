<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         bodyClasses="p-0" :autoClose="autoClose"
         modalContentClasses="card  "
         headerClasses="card-header">



    <template>
      <Invitacion v-model="value" class="mb-0" />
    </template>

    <template slot="footer">



      <div class="align-items-center text-center w-100 mb-3">
        <div>
          <small class="text-muted">Muestra este código al ingresar y salir</small>
        </div>
        <h4 v-if="value.tipoVisita" class="mb-0">{{value.tipoVisita.descripcion}}</h4>
        <base-button type="primary" @click="MostrarPreRegistro=true">
          Pre-registrarse y ahorrar tiempo
        </base-button>

        <a v-if="value.ubicacion" :href="value.ubicacion.urlMaps"
           target="_blank" class="d-block mt-2">

          <i class="fas fa-map-marker-alt"></i> ¿Cómo llegar?

          <p class="text-muted">{{value.ubicacion.direccionEtiqueta}}</p>
        </a>

        <!--
        <router-link :to="{}" class="d-block mt-2" @click.native="Cerrar()">
          <i class="fas fa-chevron-left"> </i> Cancelar
        </router-link>
  -->
      </div>
    </template>
    <PreRegistroModal v-if="MostrarPreRegistro" :Mostrar.sync="MostrarPreRegistro"  v-model="value"
                     v-on:guardado="Actualiza"></PreRegistroModal>
  </modal>
</template>

<script>
  import Servicio from '@/api-services/reuniones.service';
  import Base from '@/api-base/base';
  import Invitacion from './Invitacion.comp.vue';
  import PreRegistroModal from './PreRegistro.modal.vue';

  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');

  export default {
    components: {
      Invitacion,
      PreRegistroModal
    },
    name: 'Reuniones-Invitacion-modal',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Reuniones' },
      Titulo: { type: String, default: 'Nueva' },
      Ayuda: { type: String, default: 'Invita a personas a tu reunión.' },

      autoClose: {
        type: Boolean,
        default: true
      },

      value: { type: Object, default: false },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
      Cancelar: { type: Boolean, default: false },

      ShowPreRegistro: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará el preregistro"
      },
    },
    data() {
      return {
        Modal: false,
        MostrarPreRegistro: false,
      }
    },
    computed: {

      

    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;
      this.MostrarPreRegistro = this.ShowPreRegistro;
    },
    created() {
    },
    methods: {
      Actualiza() {

      },
      Cerrar() {
        this.datos = {
          etiqueta: '', descripcion: ''
        };
        this.Modal = false;
      },
    }
  }
</script>

<style scoped>
</style>
