<template>
  <modal :show.sync="Modal"
         class="modal-black"
         modalContentClasses="card" :quitarModalOpen="quitarModalOpen"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">{{Categoria}}</h5>
      <h3 class="card-title">{{Titulo}}</h3>
      <!--     <span class="text-muted">{{Ayuda}}</span>-->
      <div v-if="!OcultarSeleccionar" class="d-flex flex-row my-2 px-2 text-left">
        <MazSwitch v-model="MostrarTomaEnVivo" class="mr-2" />
        <h5 class="text-muted">{{MostrarTomaEnVivo?"Utilizando toma en vivo":"Utilizar toma en vivo"}}</h5>

      </div>
    </div>



    <template>
      <div v-if="MostrarTomaEnVivo" class="text-center">
        <MazBtnGroup v-model="deviceId"
                     :items="camaras"
                     outline
                     size="mini"
                     color="success" />

        <vue-web-cam v-if="hayCamaras" ref="webcam"
                     :device-id="deviceId"
                     @started="onStarted"
                     @stopped="onStopped" :width="Ancho"
                     :height="Alto"
                     @error="onError"
                     @cameras="onCameras"
                     @camera-change="onCameraChange" class="px-1 py-1 mb-1" />
        <div class="text-center">
          <base-button v-if="hayCamaras && !cargando" icon 
                       round @click="onCapture">
            <i class="fas fa-camera"></i>
          </base-button>
        </div>
      </div>
      <picture-input v-if="!MostrarTomaEnVivo" ref="pictureInput1" class="TomaFoto"
                     @change="onChange"
                     :width="Ancho"
                     :height="Alto"
                     :zIndex="zIndex"
                     :crop="false"
                     :margin="0"
                     size="10"
                     :accept="Aceptar"
                     :removable="false" :autoToggleAspectRatio="autoToggleAspectRatio" :toggleAspectRatio="toggleAspectRatio"
                     buttonClass="btn btn-sm btn-primary button"
                     :hideChangeButton="true"
                     :prefill="prefill"
                     :prefillOptions="prefillOptions"
                     :customStrings="{
                     upload: '<h1>Bummer!</h1>' ,
                     drag: 'Clic o arrastra una foto' ,
                     tap: 'Toca aquí para seleccionar una foto' ,
                     select: 'Selecciona una foto' ,
                     selected: '<p>Foto correctamente seleccionada</p>' ,
                     fileSize: 'Límite de tamaño excedido' ,
                     fileType: 'Tipo de archivo no soportado'
                     }">

      </picture-input>
      <!--  <picture-input class="TomaFoto"></picture-input>-->
      <hr class="my-md-4" />
      <base-button v-if="!cargando && HayCambio && !MostrarTomaEnVivo && !AutoGuardado" type="primary" block @click="OnGuardar">
        Guardar
      </base-button>

      <div class="text-muted text-center">

        <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
          {{ Erroneo }}
        </p>
        <div v-if="cargando" class="text-center mt-7">
          <b-spinner type="grow" label="cargando..."></b-spinner>
          Cargando...
        </div>
        <a href="#" class="d-block mt-2" @click="Cerrar()">
          <i class="fas fa-chevron-left"> </i> {{CerrarMsg}}
        </a>
      </div>
    </template>
  </modal>

</template>
<script>
  import PictureInput from 'vue-picture-input'
  import { WebCam } from "vue-web-cam";
  import { MazBtnGroup } from 'maz-ui'
  import Blobs from '@/api-base/blobs.service';
  import { MazSwitch } from 'maz-ui'


  export default {
    name: 'TomaFoto',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Aceptar: {
        type: String,
        default: 'image/jpeg,image/png,image/2q==',
        description: "tipos de archivo que aceptará",
      },

      CerrarMsg: {
        type: String,
        default: 'Cancelar',
        description: "Etiqueta ",
      },

      AutoGuardado: {
        type: Boolean,
        default: false,
        description: "Indica si se guardará automáticamente al seleccionar una imágen"
      },

      OcultarSeleccionar: {
        type: Boolean,
        default: false,
        description: "Indica si se guardará automáticamente al seleccionar una imágen"
      },

      errorMsg: {
        type: String,
        default: '',
        description: "Error a mostrar",
      },

      cargando: {
        type: Boolean,
        default: false,
        description: "muestra una animación de que esta cargando la imágen"
      },


      URL: {
        type: String,
        default: "",
        description: "Dirección URL de la imágen predeterminada a mostrar"
      },
      Cambio: {
        type: Boolean,
        default: false,
        description: "Indica si cambio la imágen desde la ultima asignación de URL"
      },
      quitarModalOpen: {
        type: Boolean,
        default: true,
        description: "Indica si removerá el modal-open"
      },
      value: String,
      Categoria: { type: String, default: 'Personal' },
      Titulo: { type: String, default: 'Asignación de foto' },
      Ayuda: { type: String, default: '.' },

    },
    components: {
      PictureInput,
      "vue-web-cam": WebCam,
      MazBtnGroup,
      MazSwitch
    },
    validations: {


    },
    data() {
      return {
        MostrarTomaEnVivo: false,
        Modal: false,
        Imagen: null,
        Guardando: false,

        img: null,
        camera: null,
        deviceId: null,
        devices: [],
        camaras: [],


        hayCamaras: true,

        autoToggleAspectRatio: true,
        toggleAspectRatio: true,

        prefill: "",//"https://konvajs.org/assets/yoda.jpg",
        prefillOptions: {
          //fileType:'jpg',
          mediaType: 'image/png'
        },
        HayCambio: false,
        Ancho: 300,
        Alto: 300,
        zIndex: 10000,



      }
    },
    watch: {
      MostrarTomaEnVivo(valor) {
        if (valor == false) {
          this.deviceId = null;
        }
      },
      camera: function (id) {
        this.deviceId = id;
      },
      devices: function () {
        // Once we have a list select the first one
        const [first, ...tail] = this.devices;
        if (first) {
          this.camera = first.deviceId;
          this.deviceId = first.deviceId;
        }
        console.debug(tail);
      },
      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          if (this.OcultarSeleccionar)
            this.MostrarTomaEnVivo = true;
          this.Modal = true;
          this.$nextTick(() => {
            //Parche: Para que se muestre la imágen
            window.dispatchEvent(new Event('resize'));
            //    this.$refs.pictureInput1.onResize();

          });

          //          window.dispatchEvent(new Event('resize'));
        }
        else
          this.Modal = false;
        //   this.$refs.pictureInput1.onResize();
      },
      Modal(Modal) {
        // if (!Modal)
        this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      },
      value(Valor) {
        console.debug(Valor);
        this.ActualizaUrl(Valor);
      },
      URL(Valor) {
        console.debug(Valor);
        this.ActualizaUrl(Valor);
      },
      Imagen(Valor) {

      },
      HayCambio(Valor) {
        this.$emit('cambio', this.HayCambio);
      }
    },
    computed: {
      Erroneo() {
        if (this.errorMsg != null && this.errorMsg != "" && !this.MostrarTomaEnVivo)
          return this.errorMsg;
        if (this.MostrarTomaEnVivo && !this.hayCamaras)
          return "No se encontraron cámaras conectadas";
        return "";

      },
      device: function () {
        return this.devices.find(n => n.deviceId === this.deviceId);
      }
    },
    filters: {


    },
    mounted() {
      this.Modal = this.Mostrar;
      if (this.OcultarSeleccionar)
        this.MostrarTomaEnVivo = true;

      /* console.debug('New picture mounted!');
       this.$refs.pictureInput1.canvasWidth  = 300;
       this.$refs.pictureInput1.canvasHeight = 300;
       this.$refs.pictureInput1.onResize ();*/
      this.ActualizaUrl(this.value);
      // this.Alto = 301;
    },

    methods: {

      ActualizaUrl(Url) {
        console.debug(`ActualizaUrl(${Url})`);
        //  this.prefill = Url;
        this.Imagen = null;
        this.$refs.pictureInput1.removeImage();
        //  this.$refs.pictureInput1.onResize();
        this.HayCambio = false;


        /*
        const image = new window.Image();
        image.src = Url;
        //  console.debug(this.URL);
        this.Imagen = image;

        image.crossOrigin = 'Anonymous';
        image.onload = () => {
            let canvas = document.createElement('canvas');
            canvas.width = image.naturalWidth
            canvas.height = image.naturalHeight
            canvas.getContext('2d').drawImage(image, 0, 0)
            //callback(canvas.toDataUrl('image/png').replace(/^data:image\/(png|jpg);base64,/, ''))
            var B64 = canvas.toDataURL('image/png');
          //  console.debug(B64);
            this.prefill = B64;
            //this.$refs.pictureInput1.image = B64;
        };*/
      },
      visibilityChanged() {
        console.debug(`visibilityChanged`);
        window.dispatchEvent(new Event('resize'));
      },
      Cerrar() {
        this.Modal = false;
      },
      onCapture() {
        console.debug(`onCapture`);
        this.Imagen = this.$refs.webcam.capture();
        this.prefill = this.Imagen;
        this.HayCambio = true;
        if (!this.OcultarSeleccionar)
          this.MostrarTomaEnVivo = false;
        if (this.AutoGuardado)
          this.OnGuardar();

      },
      onStarted(stream) {
        console.debug("On Started Event", stream);
      },
      onStopped(stream) {
        console.debug("On Stopped Event", stream);
      },
      onStop() {
        this.$refs.webcam.stop();
      },
      onStart() {
        this.$refs.webcam.start();
      },
      onError(error) {
        console.debug("On Error Event", error);
        this.hayCamaras = false;
      },
      onCameras(cameras) {
        console.debug("On Cameras Event", cameras);
        this.devices = cameras;
        this.camaras = [];
        this.devices.forEach((device) => {
          var Dispositivo = { value: `${device.deviceId}`, label: `${device.label}` };
          console.debug(Dispositivo);

          if (Dispositivo.label.includes("Surface IR"))
            return;
          if (Dispositivo.label.includes("frontal") || Dispositivo.label.includes("Front") || Dispositivo.label.includes("front")) {
            Dispositivo.label = "Frontal";

          }
          if (device.label.includes("Rear") || Dispositivo.label.includes("trasera") || Dispositivo.label.includes("back")) {
            Dispositivo.label = "Trasera";

          }
          this.camaras.push(Dispositivo);
        });

      },
      onCameraChange(deviceId) {
        this.deviceId = deviceId;
        this.camera = deviceId;
        console.debug("On Camera Change Event", deviceId);
      },
      onChange(Imagen) {
        console.debug('New picture selected!')
        if (Imagen) {
          console.debug('Picture loaded.')
       /*   
            EXIF.getData(this.$refs.pictureInput.file, function () {
              console.log('image info', this)
              console.log('exif data', this.exifdata)
            })*/
          this.$refs.pictureInput1.setOrientation  (0);

          this.Imagen = Imagen;
          // this.value = "";
          /*
                              this.$emit('BlobsEditarImgUrl', "Cambio");

                              this.$emit('blobseditarimgurl', "Cambio");
                              this.$emit('uRL', "Cambio");*/
          this.HayCambio = true;
          if (this.AutoGuardado)
            this.OnGuardar();
        } else {
          console.debug('FileReader API not supported: use the <form>, Luke!')
        }
      },
      Borrar() {

      },
      OnGuardar() {
        var sFoto = null;
        if (this.Imagen != null)
          sFoto = Blobs.Imageb64toBlob(this.Imagen);

        this.$emit('guardar', sFoto, this.Imagen)
      },
    }
  }
</script>
<style>
  .TomaFoto {
    height: 300px
  }

  .preview-container {
    height: 300px;
    width: 300px;
  }
</style>
