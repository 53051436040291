<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5 ml-auto">
        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <i class="tim-icons icon-tap-02"></i>
          </div>
          <div class="description">
            <h3 class="info-title">Seguridad y biometría</h3>
            <p class="description">
              Tu información esta segura, además validamos la identidad de tu personal por diversos métodos biométricos.
            </p>
          </div>
        </div>

        <div class="info-area info-horizontal ">
          <div class="icon icon-warning">
            <i class="tim-icons icon-world"></i>
          </div>
          <div class="description">
            <h3 class="info-title">Solución en la nube</h3>
            <p class="description">
              Administra la puntualidad de tu persona sin importar en que sucursal se encuentre.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-primary">
            <i class="tim-icons icon-refresh-02"></i>
          </div>
          <div class="description">
            <h3 class="info-title">En Tiempo Real</h3>
            <p class="description">
              Toda tu información al momento para ahorrarte tiempo que es dinero.
            </p>
          </div>
        </div>

      </div>

      <div class="col-md-7 mr-auto">

        <card class="card-register card-white">
          <template slot="header" class="pb-2">
            <img class="card-img" src="img/card-primary.png" alt="Card image" />
            <h4 class="card-title text-dark ">Registro</h4>
          </template>
          <!--
                  <div  class="text-center">
                    <b-spinner type="grow" label="Loading..."></b-spinner>
                  </div>
          -->
          <div v-if="estado=='capturando'">
            <ValidationObserver v-slot="{ handleSubmit}">
              <form @submit.prevent="handleSubmit(registrarse)">

                <ValidationProvider name="fullname"
                                    rules="required"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="fullname"
                              placeholder="Nombre completo"
                              addon-left-icon="tim-icons icon-single-02"
                              type="text"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

                <ValidationProvider name="whatsapp"
                                    rules="required|min:10"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="whatsapp"
                              placeholder="Whatsapp"
                              addon-left-icon="tim-icons icon-mobile"
                              type="phone"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

                <ValidationProvider name="email"
                                    rules="required|email"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="email"
                              placeholder="Correo electrónico"
                              addon-left-icon="tim-icons icon-email-85"
                              type="email"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>



                <ValidationProvider name="password"
                                    rules="required|min:5"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="password"
                              placeholder="Contraseña"
                              addon-left-icon="tim-icons icon-lock-circle"
                              type="password"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

                <base-checkbox class="text-left" v-model="terminos">
                  Estoy de acuerdo con los <a href="#something">terminos y condiciones</a>.
                </base-checkbox>

                <!-- <base-button native-type="submit" slot="footer" type="primary" round block size="lg">
                  Registrarse
                </base-button>
                 -->
                <base-button v-if="!trabajando" native-type="submit" :disabled="!terminos" slot="footer" type="primary" round block size="lg">
                  Registrarse
                </base-button>

              </form>
            </ValidationObserver>



          </div>

          <div v-if="estado=='validandoCorreo'" class="text-center">

            <b-icon icon="envelope" variant="primary" font-scale="7.5"></b-icon>

            <p class="text-dark mt-3">
              Se te ha enviado un correo a {{email}} con tu código de verificación, revisa correo no deseado. <a href="#" @click="corregirCorreo=true">Cambiar correo</a>
            </p>

            <modal :show.sync="corregirCorreo"
                   body-classes="p-0"
                   modal-classes="modal-dialog-centered modal-sm">
              <card type="white"
                    header-classes="bg-white pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="mb-0 text-center">
                <template>
                  <h2 class="text-dark mb-4">Corrección de correo electrónico</h2>
                  <ValidationObserver v-slot="{ handleSubmit}">
                    <form @submit.prevent="handleSubmit(correccionCorreo)">
                      <ValidationProvider name="email"
                                          rules="required|email"
                                          v-slot="{ passed, failed, errors }">
                        <base-input required
                                    v-model="nuevoEmail"
                                    placeholder="Correo electrónico"
                                    type="email"
                                    :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                      <base-alert v-if="error" type="danger">
                        <strong>Error:</strong> {{error}}
                      </base-alert>

                      <b-spinner v-if="trabajando" type="grow" label="Loading..."></b-spinner>
                      <base-button v-if="!trabajando" type="primary" native-type="submit" class="my-4">Enviar nuevo código</base-button>

                    </form>
                  </ValidationObserver>
                  <a href="#" @click="corregirCorreo=false">Cancelar</a>
                </template>
              </card>
            </modal>
            <ValidationObserver v-slot="{ handleSubmit}">
              <form @submit.prevent="handleSubmit(validarCorreo)">
                <ValidationProvider name="codigoCorreo"
                                    rules="required|min:5"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="codigoCorreo"
                              placeholder="Ingresa el código"
                              addon-left-icon="tim-icons icon-lock-circle"
                              type="phone"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

                <base-button v-if="!trabajando" native-type="submit" slot="footer" type="primary" round block size="lg">
                  Validar
                </base-button>
              </form>
            </ValidationObserver>


          </div>
          <div v-if="estado=='validandoWhatsapp'" class="text-center">
            <b-icon icon="phone" variant="primary" font-scale="7.5"></b-icon>


            <!-- <h1 class="text-dark ">SMS</h1>-->
            <p class="text-dark  mt-3">
              Se te envio un SMS a {{whatsapp}} con tu código de verificación. <a href="#" @click="corregirWhatsapp=true">Cambiar número o reenviar código</a>
            </p>
            <modal :show.sync="corregirWhatsapp"
                   body-classes="p-0"
                   modal-classes="modal-dialog-centered modal-sm">
              <card type="white"
                    header-classes="bg-white pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="mb-0 text-center">
                <template>
                  <h2 class="text-dark mb-4">Corrección de número teléfonico</h2>

                  <ValidationObserver v-slot="{ handleSubmit}">
                    <form @submit.prevent="handleSubmit(correccionWhatsapp)">
                      <ValidationProvider name="whatsapp"
                                          rules="required|min:10"
                                          v-slot="{ passed, failed, errors }">
                        <base-input required
                                    v-model="nuevoWhatsapp"
                                    placeholder="número telefónico"
                                    type="phone"
                                    :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                      <base-alert v-if="error" type="danger">
                        <strong>Error:</strong> {{error}}
                      </base-alert>

                      <b-spinner v-if="trabajando" type="grow" label="Loading..."></b-spinner>
                      <p v-if="segundosPendientes>0">Espere {{segundosPendientes}} segundos</p>
                      <base-button v-if="!trabajando && segundosPendientes<=0" type="primary" native-type="submit" class="my-4">Enviar nuevo código</base-button>

                    </form>

                  </ValidationObserver>
                  <a href="#" @click="corregirWhatsapp=false">Cancelar</a>
                </template>
              </card>
            </modal>
            <ValidationObserver v-slot="{ handleSubmit}">
              <form @submit.prevent="handleSubmit(validarTelefono)">
                <ValidationProvider name="codigoWhatsapp"
                                    rules="required|min:5"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="codigoWhatsapp"
                              placeholder="Ingresa el código"
                              addon-left-icon="tim-icons icon-lock-circle"
                              type="phone"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>
                <base-button v-if="!trabajando" native-type="submit" slot="footer" type="primary" round block size="lg">
                  Validar
                </base-button>
              </form>
            </ValidationObserver>


          </div>


          <div class="text-center">
            <div v-if="estado=='usuarioPreviamenteRegistrado' || estado=='registroErroneo'">
              <i class="fas fa-times-circle fa-7x"></i>
            </div>
            <div v-if="estado=='registroErroneo'">
              <p>No se pudo registrar, intente nuevamente o contacte a soporte técnico.</p>
              <a href="#" @click="confirmarRegistro">Reintentar registro</a>
            </div>
          </div>

          <div v-if="trabajando" class="text-center">
            <b-spinner type="grow" label="Loading..."></b-spinner>
          </div>




          <base-alert v-if="error" type="danger">
            <strong>Error:</strong> {{error}}
          </base-alert>



          <div v-if="estado=='capturando' || estado=='usuarioPreviamenteRegistrado'" slot="footer">

            <div class="pull-left">
              <h6>
                <router-link class="link footer-link" to="/login">
                  Iniciar sesión
                </router-link>
              </h6>
            </div>

            <div class="pull-right">
              <h6>
                <router-link class="link footer-link" to="/recuperar">
                  Recuperar contraseña
                </router-link>

              </h6>
            </div>
          </div>
        </card>

      </div>



    </div>
  </div>
</template>
<script>

  import { extend } from "vee-validate";
  import { required, email,min } from "vee-validate/dist/rules";
  import Usuarios from '@/api-base/usuarios.service';
  import { localize } from "vee-validate";

  extend("email", email);
  //extend("phone", phone);
  extend("required", required);
  extend("min", min);
  localize({
    es: {
      messages: {
        // generic rule messages...
      },
      fields: {
        codigoCorreo: {

          required: 'Requerimos el código enviado a tu correo electrónico',

          min: 'Debe ser mínimo de {length} caracteres'
        },
        codigoWhatsapp: {

          required: 'Requerimos el código enviado a tu teléfono',
          min: 'Debe ser mínimo de {length} caracteres'

        },
      }
    }
  });


  export default {

/*    components: {
      Modal,
      BaseCheckbox,
      BaseAlert
    },*/
    data() {
      return {
        email: '',
        nuevoEmail: '',
        fullname: '',
        password: '',
        whatsapp: '',
        nuevoWhatsapp: '',
        // whatsappFinal:'',
        terminos: false,
        codigoCorreo: '',
        codigoWhatsapp: '',

        capturando: true,
        validandoCorreo: false,
        validandoWhatsapp: false,

        corregirCorreo: false,
        corregirWhatsapp: false,

        error: '',
        trabajando: false,
        estado: 'capturando',

        ultimoSMS: (new Date()).getTime(),
        segundosReintento: 60,
        segundosPasaron: 0,

      };
    },
    computed: {
      segundosPendientes() {
        if (this.segundosPasaron > this.segundosReintento)
          return 0;
        return this.segundosReintento - this.segundosPasaron;


      }

    },
    mounted() {
      this.startTimer();
    },
    methods: {
      startTimer() {
        this.timerInterval = setInterval(() => (
          this.segundosPasaron += 1), 1000);
      },
      submit() {
        alert("Form has been submitted!");
      },
      registrarse() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.ValidacionPost({ nombre: this.fullname, correo: this.email, telefono: this.whatsapp }).then((response) => {
          console.debug(response.data);
          this.nuevoEmail = this.email = response.data.correo;
          this.nuevoWhatsapp = this.whatsapp = response.data.telefono;
          this.trabajando = false;
          this.estado = "validandoCorreo";
          this.ultimoSMS = new Date();
          this.segundosPasaron = 0;
        }).catch((error) => {
          this.trabajando = false;
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "Intente nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }
        }
        );
      },
      correccionCorreo() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.ValidacionPost({ nombre: this.fullname, correo: this.nuevoEmail }).then((response) => {
          console.debug(response.data);
          this.nuevoEmail = this.email = response.data.correo;
          this.corregirCorreo = false;
          this.trabajando = false;
        }).catch((error) => {
          this.trabajando = false;
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "Intente nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }
        }
        );
      },
      validarCorreo() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.ValidacionPut({ verificacionID: this.email, codigo: this.codigoCorreo }).then((response) => {

          this.trabajando = false;
          this.estado = "validandoWhatsapp";

        }).catch((error) => {
          this.trabajando = false;
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "El código no corresponde, intenta nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }
        }
        );
      },
      correccionWhatsapp() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.ValidacionPost({ nombre: this.fullname, telefono: this.nuevoWhatsapp }).then((response) => {
          console.debug(response.data);
          this.nuevoWhatsapp = this.whatsapp = response.data.telefono;
          this.segundosPasaron = 0;
          this.corregirWhatsapp = false;
          this.trabajando = false;
        }).catch((error) => {
          this.trabajando = false;
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "Intente nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }
        }
        );
      },
      validarTelefono() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.ValidacionPut({ verificacionID: this.whatsapp, codigo: this.codigoWhatsapp }).then((response) => {

          this.trabajando = false;
          this.confirmarRegistro();
          //this.estado = "registroSatisfactorio";


        }).catch((error) => {
          this.trabajando = false;
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "El código no corresponde, intenta nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }
        }
        );
      },
      confirmarRegistro() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.postUsuario({ nombre: this.fullname, correo: this.email, telefono: this.whatsapp, password: this.password }).then((response) => {

          this.trabajando = false;
          this.estado = "registroSatisfactorio";
          localStorage.sesion = response.data;
          //localStorage.usuario = JSON.stringify(response.data.resultado.usuario);
          if (this.$route.query.from === undefined)
            this.$router.push('/');
          else
            this.$router.push(this.$route.query.from);

        }).catch((error) => {
          this.trabajando = false;

          this.estado = "registroErroneo";
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {
            this.error = "Intenta nuevamente o contacte a soporte técnico";
          }
          else {

            console.error(error.response.data.error);
            if (error.response.status == 409) {
              this.error = `${error.response.data.error.mensaje}, intente recuperar la contraseña`;
              this.estado = "usuarioPreviamenteRegistrado";
            }
            else
              this.error = error.response.data.error.mensaje;
          }
        }
        );
      }
    }
  };
</script>
<style></style>
