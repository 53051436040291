<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         footerClasses="w-100" :showClose="false"
         bodyClasses="align-items-center text-center w-100 "
         modalContentClasses="card  " :autoClose="autoClose"
         headerClasses="card-header">


    <div v-if="Paso!=5" slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">Autoregistro</h5>
      <h1 class="card-title">Incremento de seguridad</h1>
    </div>
    <div v-if="Paso==5" slot="header" class="card">
      <div class="card-header p-2">
        <div>
          <img :src="datos.urlEnrolamiento" class="rounded-circle float-left mr-2 mt-0" width="56px" style="height:56px">
          <h3 class="mb-1 pb-0  text-truncate"> {{datos.etiqueta}}</h3>
          <router-link :to="{}" @click.native="NoSoyYo()">
            No soy yo
          </router-link>
        </div>
      </div>
    </div>
    <template>
      <div class="cuerpo-wizard-pre-registro">
        <img v-if="Paso==1" class="w-50 py-5" src="/img/Seguridad.svg" alt="..." />
        <div v-if="Paso==4" class="px-0 py-1">
          <h4 class="text-muted">Tus datos serán <b>validados</b> para ingresar al complejo.</h4>

          <MazInput v-model="datos.nombre"
                    placeholder="Nombre(s)"
                    autocomplete="autocompleteNombre"
                    required :loading="cargando" class="mb-2"
                    clearable>
            <!--    <i slot="icon-left"
            class="tim-icons icon-single-02"></i>-->
          </MazInput>
          <div class="row px-3 mb-2">
            <div class="col px-0">
              <MazInput v-model="datos.apellidoP"
                        placeholder="A. Paterno"
                        autocomplete="autocompleteApellidoP"
                        required :loading="cargando"
                        clearable>
              </MazInput>
            </div>
            <div class="col  px-0">
              <MazInput v-model="datos.apellidoM"
                        placeholder="A. Materno"
                        autocomplete="autocompleteApellidoM"
                        :loading="cargando"
                        clearable>
              </MazInput>
            </div>
          </div>

          <ValidationProvider name="email"
                              rules="email|required"
                              v-slot="{ passed, failed, errors }">
            <MazInput v-model="datos.correo" class=" mb-2"
                      placeholder="Correo personal"
                      autocomplete="autocompleteCorreo" :loading="cargando"
                      type="email" :error="failed" required
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </MazInput>

          </ValidationProvider>

          <MazPhoneNumberInput v-model="datos.telefono" v-if="!cargando"
                               :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                               :preferred-countries="MazPhoneNumber.PaisesPreferidos" required
                               showCodeOnList @update="telefonoValido = $event" :error="telefonoValido!=null && datos.telefono != null && datos.telefono != '' && !telefonoValido.isValid"
                               :loading="cargando" placeholder="# Teléfono" class="mb-2" />

          <MazInput v-if="CapturaEmpresa" v-model="datos.empresa"
                    placeholder="Empresa de trabajo"
                    autocomplete="autocompleteEmpresa"
                    required :loading="cargando" class="mb-0"
                    clearable>
            <!--    <i slot="icon-left"
            class="tim-icons icon-single-02"></i>-->
          </MazInput>

        </div>

        <img v-if="Paso==2" class="w-50 pt-5" :src="ImgSelfie" alt="..." @click="MostrarTomarSelfie=true" />
        <div v-if="Paso==2">
          <base-button icon :loading="cargando" @click="MostrarTomarSelfie=true"
                       round>
            <i class="fas fa-camera"></i>
          </base-button>
        </div>

        <img v-if="Paso==3" class="w-50 pt-5" :src="ImgID" alt="..." @click="MostrarTomarID=true" />
        <div v-if="Paso==3">
          <base-button icon :loading="cargando" @click="MostrarTomarID=true"
                       round>
            <i class="fas fa-camera"></i>
          </base-button>
        </div>

        <img v-if="Paso==5&&cargando" class="w-50 pt-5" src="/img/Analizando.svg" alt="..." />
        <div v-if="Paso==5&&!cargando&&Coincide" class="text-center ">
          <vue-qr :text="furlPersona(datos)" width="70%" logoSrc="img/e512transparente.png" qid="testid" :correctLevel="3" :size="2600"></vue-qr>
        </div>
        <!--<img v-if="Paso==5&&!cargando&&Coincide" class="w-50 pt-5" src="/img/Coincide.svg" alt="..." />-->
        <img v-if="Paso==5&&!cargando&&!Coincide" class="w-50 pt-5" src="/img/NoCoincide.svg" alt="..." />
      </div>
    </template>

    <div class="pt-4 align-bottom  text-center pasos-wizard-pre-registro ">
      <div class=" ">
        <h4 v-if="Paso==1">Para un <b>acceso seguro</b> te registraremos <b>validando tu identidad</b>.</h4>
        <base-checkbox v-if="Paso==4" class="text-left pb-2" v-model="terminos">
          <p> Mis <b>datos son correctos</b> y estoy de acuerdo con los terminos y condiciones (<a href="https://www.eclock.com.mx/aviso-de-privacidad" target="_blank"><b>Leerlos</b></a>).</p>
        </base-checkbox>

        <!--<h4 v-if="Paso==2">Tus datos serán <b>validados</b> para ingresar al complejo.</h4>-->
        <h4 v-if="Paso==2">Tómate una <b>foto clara</b> y sin sombras.</h4>
        <h4 v-if="Paso==3">Toma una foto de tu identificación <b>oficial</b> donde se vea claramente tu <b>nombre completo</b> y <b>rostro</b>.</h4>

        <h4 v-if="Paso==5&&cargando">Espera <b>analizando datos</b>.</h4>
        <h4 v-if="Paso==5&&!cargando&&Coincide">Se te pedirá el <b>QR</b> para tener acceso.</h4>
        <h4 v-if="Paso==5&&!cargando&&!Coincide">Los datos no coinciden <b>intenta nuevamente</b>.</h4>
      </div>
      <div class="  d-flex justify-content-center">
        <MazStepper v-model="Paso" class="p-0 w-25 " disabled-previous-steps disabled-next-steps
                    :steps="Pasos" />
      </div>
    </div>
    <template slot="footer">




      <div v-if="!Finalizar" class="w-100 p-2 d-flex justify-content-between">

        <base-button :disabled="Paso<=1" link @click="Regresar()" class="text-muted">
          < Regresar
        </base-button>

        <base-button type="primary" @click="Siguiente()" :disabled="!PuedeContinuar" :loading="loading||cargando">
          Continuar >
        </base-button>

      </div>
      <div v-else class="w-100 p-2 d-flex justify-content-end">
        <base-button type="primary" @click="OnNuevo()" :disabled="!PuedeContinuar" :loading="loading||cargando">
          Registrar a alguien más
        </base-button>
      </div>
      <!--
      <div class="align-items-center text-center w-100 mb-3">
        <base-button type="primary" @click="">
          Continuar
        </base-button>
        <router-link :to="{}" class="d-block mt-2" @click.native="Cerrar()">
          <i class="fas fa-chevron-left"> </i> Cancelar
        </router-link>

      </div>
      -->
    </template>

    <TomarFoto v-if="MostrarTomarSelfie" :Mostrar.sync="MostrarTomarSelfie" Titulo="Captura de foto" Categoria="Pre-registro" OcultarSeleccionar AutoGuardado
               v-on:guardado="OnGuardarFoto" v-on:guardar="OnGuardarFoto" :quitarModalOpen="false"></TomarFoto>

    <TomarFoto v-if="MostrarTomarID" :Mostrar.sync="MostrarTomarID" Titulo="Captura de foto" Categoria="Pre-registro" OcultarSeleccionar AutoGuardado
               v-on:guardado="OnGuardarFoto" v-on:guardar="OnGuardarFotoID" :quitarModalOpen="false"></TomarFoto>

  </modal>
</template>

<script>
  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";
  import Constantes from '@/api-services/Constantes';
  import Personas from '@/api-base/personas.service';
  import Base from '@/api-base/base';
  import Invitacion from './Invitacion.comp.vue';
  import TomarFoto from '@/Vistas-base/Blobs/TomaFoto.comp.vue';
  import moment from 'moment'
  import VueQr from 'vue-qr'

  //var moment = require('moment');
  moment.locale('es-mx');
  import { MazStepper } from 'maz-ui'

  extend("email", email);
  //extend("phone", phone);
  extend("required", required);
  extend("min", min);

  export default {
    components: {
      TomarFoto,
      Invitacion,
      MazStepper,
      VueQr,
    },
    name: 'Reuniones-AutoRegistro-modal',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Reuniones' },
      Titulo: { type: String, default: 'Nueva' },
      Ayuda: { type: String, default: 'Invita a personas a tu reunión.' },
      autoClose: {
        type: Boolean,
        default: true
      },

      value: { type: Object, default: null },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
      Cancelar: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {
          id: '',
          nombre: '',
          apellidoP: '',
          apellidoM: '',
          correo: '',
          telefono: '',
          llave: '',
          empresa: ''
        },
        telefonoValido: false,
        Modal: false,
        Paso: 1,
        Pasos: 5,
        cargando: false,
        errorMsg: '',
        MostrarTomarSelfie: false,
        MostrarTomarID: false,
        ImgSelfie: '/img/FaceID.svg',
        ArchivoSelfie: null,
        ImgID: '/img/ID.svg',
        ArchivoID: null,
        Coincide: false,
        terminos: false,
        campos: {},
      }
    },
    computed: {
      CapturaEmpresa() {
        if (this.campos.empresa != undefined && this.campos.empresa)
          return true;
        if (this.value == null)
          return false;
        if (this.value.ubicacion == null)
          return false;
        if (this.value.ubicacion.campos == null)
          return false;
        var R = this.value.ubicacion.campos.filter(x => x == "Empresa");
        return (R.length > 0)

      },
      MazPhoneNumber() {
        return Base.MazPhoneNumber;
      },
      PuedeContinuar() {
        switch (this.Paso) {
          case 4:
            if (this.datos.nombre == "")
              return false;
            if (this.datos.apellidoP == "")
              return false;
            if (!this.telefonoValido.isValid)
              return false;
            if (!email.validate(this.datos.correo))
              return false;
            if (this.terminos == false)
              return false;
            break;
          case 2:
            if (this.ArchivoSelfie == null)
              return false;
            break;
          case 3:
            if (this.ArchivoID == null)
              return false;
            break;
        }
        return true;
      },
      Finalizar() {
        if (!this.PuedeContinuar)
          return false;
        if (this.cargando)
          return false;
        return (this.Paso == this.Pasos);
      },
      autocompleteNombre() {
        return `${this.$options.name}-Nombre`;
      },
      autocompleteApellidoP() {
        return `${this.$options.name}-ApellidoP`;
      },
      autocompleteApellidoM() {
        return `${this.$options.name}-ApellidoM`;
      },
      autocompleteCorreo() {
        return `${this.$options.name}-Correo`;
      },
      autocompleteEmpresa() {
        return `${this.$options.name}-Empresa`;
      },
    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;
      /*  var Persona = Personas.Persona;
        if (Persona != null && Persona.id != "") {
          this.datos = Persona;
        }
        */
      Personas.getCampos(this, (response) => {
        this.campos = response.data;
      }, (MsgError, Error) => {

      }, () => {

      });
    },
    created() {
    },
    methods: {
      furlPersona(Persona) {
        return Constantes.URLPersona(Persona.id);
      },
      OnGuardarFotoID(Archivo, Imagen) {
        console.debug("OnGuardarFoto");
        this.ImgID = Imagen;
        this.ArchivoID = Archivo;
        this.MostrarTomarID = false;
      },
      OnGuardarFoto(Archivo, Imagen) {
        console.debug("OnGuardarFoto");
        this.ImgSelfie = Imagen;
        this.ArchivoSelfie = Archivo;
        this.MostrarTomarSelfie = false;
      },
      Regresar() {

        this.Paso--;
      },
      CargaFoto() {
        if (this.datos.id == "")
          return;
        Personas.SelfiePatch(this, this.datos.id, 0, this.datos.llave, this.ArchivoSelfie, (response) => {
          //this.datos.id = response.data.id;
          Personas.Persona = this.datos;
          console.debug(response.data);
        }, (MsgError, Error) => {
          this.Paso = 3;
        }, () => {

        });
      },
      Siguiente() {
        switch (this.Paso) {
          case 4:
            Personas.put(this, this.datos.id, this.datos, (response) => {
              //Personas.Persona = this.datos;
              Personas.AgregaPersonaID(this.datos.id);
            }, (MsgError, Error) => {
              //console.debug(MsgError);
              //console.debug(Error);
              if (Error.response.status == 409) {
                console.debug(Error.response.data);
                MsgError = `Registro previo (${Error.response.data})`;
              }
              this.Paso = 4;
              return MsgError;
            }, () => {

            });
            break;
          case 2:
            this.datos = {}
            this.Coincide = false;
            //NULL Es crear uno nuevo
            Personas.SelfiePatch(this, "NULL", 0, {
              cPersonasIDs: Personas.PersonasIDs.join(","),
              buscarCoincidencia: true
            }, this.ArchivoSelfie, (response) => {
              //this.datos.id = response.data.id;
              this.datos = response.data;
              Personas.Persona = this.datos;
              if (!this.datos.temporal) {
                this.Coincide = true;
                if (this.datos.etiqueta == "") {
                  this.Paso = 4;
                }
                else
                  this.Paso = 5;
              }
              console.debug(response.data);
            }, (MsgError, Error) => {
              this.Paso = 2;
            }, () => {

            });
            /*
            Personas.post(this, this.datos, (response) => {
              //Personas.Persona = this.datos;
              this.datos.id = response.data.id;
              this.datos.llave = response.data.llave;
              this.CargaFoto();
            }, (MsgError, Error) => {

              if (Error.response.status == 409) {
                console.debug(Error.response.data);
                MsgError = `Registro previo (${Error.response.data})`;
              }
              this.Paso = 2;
              return MsgError;
            }, () => {

            });*/
            break;
          case 3:
            if (this.datos.id == "")
              return;
            var TempPersonaID = this.datos.id;
            var TempLlave = this.datos.llave;
            Personas.IdentificacionPatchParams(this, this.datos.id, 0, {
              llave: this.datos.llave,
              cPersonasIDs: Personas.PersonasIDs.join(",")
            }, this.ArchivoID, (response) => {
              //this.datos.id = response.data.id;

              console.debug(response.data);
              this.datos = response.data;
              if (this.datos.id != TempPersonaID) {
                this.datos.tempPersonaID = TempPersonaID;
                this.datos.tempLlave = TempLlave;
              }

              this.Coincide = true;
              Personas.Persona = this.datos;


              /*
              this.Coincide = true;
              Personas.Persona = this.datos;

              Servicio.PostPersona(null, this.value.id, {
                personaID: this.datos.id,
                llave: this.datos.llave,
              }, (response) => { }, (MsgError, Error) => {
                //this.Paso = 3;
              }, () => {

              });
              */
            }, (MsgError, Error) => {
              if (MsgError == "No Coincide") {
                this.Coincide = false;
                this.Paso = 3;
                return;
              }
              this.Paso = 3;
            }, () => {

            });
            break;
          case 4:

            break;
        }
        this.Paso++;
      },
      Cerrar() {
        this.datos = {
          etiqueta: '', descripcion: ''
        };
        this.Modal = false;
      },
      OnNuevo() {
        this.datos.id = "";
        this.datos.tempPersonaID = null;
        this.datos.tempLlave = null;
        this.ImgSelfie = '/img/FaceID.svg';
        this.ArchivoSelfie = null;
        this.ImgID = '/img/ID.svg';
        this.ArchivoID = null;
        this.terminos = false;
        this.Paso = 2;
      },
      NoSoyYo() {
        Personas.NoSoyYo(this, this.datos.id, { llave: this.datos.llave }, (response) => {
          this.datos = response.data;
          
          this.Paso = 3;
          this.ImgSelfie = this.datos.urlEnrolamiento;
          //Personas.Persona = this.datos;
        }, (MsgError, Error) => {
        }, () => {

        });
      }
    }
  }
</script>

<style lang="scss">
  .cuerpo-wizard-pre-registro {
    min-height: 250px;
  }

  .pasos-wizard-pre-registro {
    min-height: 125px;
  }
</style>
