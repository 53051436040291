<template>
  <div class="d-flex flex-wrap mb-3 ">
    <!-- <base-radio name="Desconocido" v-model="ElPerfil" disabled class="mx-2">Desconocido</base-radio>-->
    <base-radio name="Vigilante" v-model="ElPerfil" class="mx-2">Vigilante</base-radio>
    <base-radio name="UsuarioRegular" v-model="ElPerfil" class="mx-2">Usuario Regular</base-radio>
    <!--<base-radio name="UsuarioAvanzado" v-model="ElPerfil" class="mx-2">Usuario Avanzado</base-radio>-->
    <base-radio name="Administrador" v-model="ElPerfil" class="mx-2">Administrador</base-radio>

  </div>
</template>

<script>
  import { BaseRadio } from 'src/components/index';


  export default {
    components: {
      BaseRadio,

    },
    name: 'Dispositivos-Tipo-comp',
    props: {


      value: { type: String, default: 'UsuarioRegular' },
    },
    data() {
      return {

        ElPerfil: '',

      }
    },
    computed: {



    },

    watch: {
      value(Perfil) {
        this.ElPerfil = Perfil;
      },
      ElPerfil(Perfil) {
        this.$nextTick(() => { this.$emit('input', Perfil);  });
      }
    },
    mounted() {

      this.ElPerfil = this.value;
    },
    created() {
    },
    methods: {

    }
  }
</script>

<style scoped>
</style>
