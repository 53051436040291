<template>
  <div>
    <base-header class="header py-5 d-flex align-items-center mb-3"
                 style="height: 40vh;  background-image: url(img/home-fondo.jpg); background-size: cover; background-position: center top; ">
      <!-- Mask -->
      <!--  <div class="mask bg-gradient-gray-dark opacity-4" />
    <!-- <div class="mask bg-gradient-success opacity-4"></div>
    -->
      <!-- Header container -->
      <div class=" align-items-center ">
        <div class="row">
          <div class="col-lg-7 col-md-10  mt-5">

            <!--   <h3 class="display-3 text-white mt-5 m-0 p-0">Lista de reuniones</h3>-->
            <p class="text-white mt-5 ">Eventos programados con autorización de ingreso a las personas asignadas</p>

            <!-- <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
        <b-row align-h="end" class="m-2">
          <base-button v-if="esAdministrador" icon
                       round :loading="cargando" @click="$router.push('/tipos-visitas')">
            <i class="fas fa-user-tag"></i>
          </base-button>
          <base-button icon
                       round :loading="cargando" @click="Actualiza">
            <i class="fas fa-sync"></i>
          </base-button>

          <base-button icon @click="MostrarFiltrar=true"
                       round :loading="cargando || MsgError.length>0">
            <i class="fas fa-filter"></i>
          </base-button>


        </b-row>
      </div>

    </base-header>
    <!--
  <base-header type="gradient-success" class="pb-6 pb-8 pt-2 pt-md-8">
    <div class="container-fluid p-3">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="mb-0">
            Reuniones
          </h1>
        </div>
        <div class="col text-right">

          <b-row align-h="end" class="m-2">
            <base-button icon
                         round :loading="cargando" @click="Actualiza">
              <i class="fas fa-sync"></i>
            </base-button>

            <base-button icon @click="MostrarFiltrar=true"
                         round :loading="cargando || MsgError.length>0">
              <i class="fas fa-filter"></i>
            </base-button>


          </b-row>
        </div>
      </div>
    </div>
  </base-header>
    -->
    <Main-Cargando :cargando="cargando" :MsgError="MsgError" class="mx-2">
      <div v-if=" info " class="card-columns-listado ">
        <div v-for="registro in info.listado" v-bind:key="registro.id" class="card   mb-3" :style="`border-left: 10px solid ${registro.color};`">
          <router-link :to="{}" class="pull-right p-3" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fa fa-times"></i></router-link>
          <router-link :to="{}" @click.native="Editar(registro)">
            <div class="card-header px-3 pt-3">
              <img :src="registro.usuaria | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
              <h3 class="mb-0 pb-0 text-break ">{{registro.etiqueta}}</h3>
              <h5 v-if="registro.usuaria" class="mb-0 pb-0 text-break ">{{registro.usuaria.etiqueta}}</h5>
              <h4 class="mb-0 pb-0 text-break ">{{comienzo(registro)}}</h4>


              <p class="mb-0 pb-0">{{registro.descripcion}}</p>
              <p v-if="registro.ubicacion" class="mb-0 pb-0">Ubicación: <b>{{registro.ubicacion.etiqueta}}</b> </p>
            </div>
            <!--      <div class="px-3 pb-3">
            <hr class="my-2" />
            <router-link :to="{}" class="float-right px-2" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fas fa-external-link-alt fa-2x"></i></router-link>
            <router-link :to="{}" class="float-right px-2" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fas fa-external-link-alt fa-2x"></i></router-link>
            <div class="text-muted pt-2">Invitados: </div><i class="fas fa-share"></i>
            <badge v-for="invitados in registro.invitados" type="info" class="mr-2">{{invitados}}</badge>



          </div>-->
            <hr class="mt-2" />
            <div class="text-center ">
              <vue-qr :text="furlInvitacion(registro)" width="70%" logoSrc="img/e512transparente.png" qid="testid" :correctLevel="3" :size="2600"></vue-qr>
            </div>
            <hr class="mt-3 mb-1" />
            <div class="px-3 pb-3">
              <!--<hr class="my-2" />-->
              <!--Se usa para que queden alineados el boton y la palabra invitados-->
              <hr class="my-0" width="0px" />

              <!--

  -->
              <router-link v-if="!EsProduccion" :to="{}" class="float-right px-0  align-bottom" data-effect="fadeOut" @click.native.stop="Ver(registro)"><i class="fas fa-eye fa-2x"></i></router-link>
              <router-link :to="{}" class="float-right p-2  align-bottom" data-effect="fadeOut" @click.native.stop="Compartir(registro)">Compartir <i class="fas fa-share"></i> </router-link>

              <div class="text-muted mt-3">Invitados: </div>
              <badge v-for="invitados in registro.invitados" type="info" class="mr-2">{{invitados}}</badge>
              <h4 v-if="registro.tipoVisita" class="mb-0">{{registro.tipoVisita.etiqueta}}</h4>
              <h5 v-if="registro.tipoVisita" class="mb-0">{{registro.tipoVisita.descripcion}}</h5>
            </div>
          </router-link>
        </div>
      </div>
      <base-pagination v-if="!cargando && info && info.totalPaginas>1" :pageCount="info.totalPaginas" v-model="Pagina"></base-pagination>
    </Main-Cargando>
    <div class="fixed-bottom text-center  mb-2" style="width:100%">
      <base-button size="lg" icon type="primary" @click="MostrarNuevo=true"
                   round>
        <i class="fas fa-plus fa-3x"></i>
      </base-button>
    </div>

    <modal :show.sync="MostrarCompartir" type="mini"
           centered>
      <p class="text-left text-muted mb-3">Comparte la reunión a los invitados por:</p>
      <!--
    <p  slot="header" class="modal-title text-left" id="modal-title-default" >Comparte la reunión a los invitados por:</p>
     <div class="dropdown-divider"></div>-->
      <a class="dropdown-item" :href="WhatsApp(Seleccionado)" target="_blank"> <i class="fab fa-whatsapp mr-2" aria-hidden="true"></i>Whatsapp</a>
      <a class="dropdown-item" :href="Correo(Seleccionado)" target="_blank"><i class="fa fa-envelope mr-2" aria-hidden="true"></i>Correo electrónico</a>
      <a class="dropdown-item" href="#" @click="Copiar(Seleccionado)"><i class="fas fa-copy mr-2" aria-hidden="true"></i>Copiar</a>

    </modal>


    <EdicionModal v-if="MostrarEdicion" :Mostrar.sync="MostrarEdicion" :Ayuda="Ayuda" v-model="Seleccionado"
                  v-on:guardado="Actualiza"></EdicionModal>

    <FiltrarModal v-if="MostrarFiltrar" :Mostrar.sync="MostrarFiltrar" Categoria="Grupos" v-model="BaseGetsListado.filtro"
                  v-on:filtrar="OnFiltrar"></FiltrarModal>

    <NuevoModal v-if="MostrarNuevo" :Mostrar.sync="MostrarNuevo" :Ayuda="Ayuda"
                v-on:guardado="Actualiza"></NuevoModal>

    <InvitacionModal v-if="MostrarInvitacion" :Mostrar.sync="MostrarInvitacion" :Ayuda="Ayuda" v-model="Seleccionado"
                v-on:guardado="Actualiza"></InvitacionModal>


  </div>
</template>
<script>


  import VueQr from 'vue-qr'


  import Referencias from '@/api-base/referencias.service';
  import Usuarias from '@/api-services/usuarias.service';
  import Servicio from '@/api-services/reuniones.service';
  import Base from '@/api-base/base';
  import BaseHeader from 'src/components/BaseHeader';
  import NuevoModal from './Nuevo.modal.vue';
  import EdicionModal from './Edicion.modal.vue';
  import FiltrarModal from '@/Vistas-base/Main/Filtrar.modal.vue';
  import swal from 'sweetalert2';
  import { Badge } from 'src/components'
  import InvitacionModal from './Invitacion.modal.vue';

  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  const isProd = process.env.NODE_ENV === "production";

  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');

  
  export default {
    components: {
      BaseHeader,
      FiltrarModal,
      EdicionModal,
      NuevoModal,
      Badge,
      VueQr,
      InvitacionModal
    },

    name: 'Reuniones',
    data() {
      return {

        MostrarFiltrar: false,
        MostrarNuevo: false,
        MostrarEdicion: false,

        BaseGetsListado: {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 50,
          filtro: '',
        },

        Seleccionado: null,

        Ayuda: 'Autoriza el ingreso a un evento',


        info: null,
        loading: true,
        errored: false,
        cargando: false,
        MsgError: '',
        MostrarCompartir: false,
        MostrarInvitacion: false,
        esAdministrador: false,
      }
    },
    computed: {
      EsProduccion() {
        return isProd;
      }
    },
    filters: {
      Foto(Usuario) {
        return Usuarias.FotoURL(Usuario);
      },
      FormatEditar(value) {
        return `/reuniones/edicion?i=${value.reunionAlternoID}`;
      },

      Fp() {
        return copiar();
      },
      urlInvitacion(value) {
        return furlInvitacion(value);
      },
      Contenido(value) {
        return fContenido(value);
      },


      rango(value) {

        var desde = Base.fecha(value.comienzo);
        var hasta = Base.fecha(value.finaliza);
        return `${desde.toLocaleString()} - ${hasta.toLocaleString()}`;
      }
    },
    mounted() {
      this.Actualiza();
      var SU = SuscripcionesUsuarios.getSuscripcionUsuario();
      this.esAdministrador = SU.esAdministrador;
      /*  Reuniones.gets(this, {}, (response) => {
          if (response.data === undefined) {
            this.errored = true;
            return;
          }
          console.log(response.data);
          this.info = response.data;
          this.loading = false;
  
          // this.$router.push('/reuniones');
        }, (error) => {
          console.error(error.response);
          this.errored = true;
          //alert(error.response.data.error.mensaje);
        });*/

    },
    methods: {
      furlInvitacion(value) {
        return Referencias.ObtenUrlLinkID(value.linkID);
      },
      SoloContenido(value) {
        if (value == null)
          return "";
        //console.log()
        return "Te mando los datos de la reunión del " + Servicio.FFechaHora(value.comienzo) + ", puedes ver los detalles en: " + this.furlInvitacion(value);
      },
      fContenido(value) {
        return encodeURI(this.SoloContenido(value));
      },
      Contenido(value) {
        return this.fContenido(value);
      },
      Correo(value) {
        return "mailto:?subject=Invitación&body=" + this.fContenido(value);
      },
      WhatsApp(value) {
        return "https://wa.me/?text=" + encodeURI(this.SoloContenido(value).replace("/#/", "/"));
      },
      Copiar(value) {
        return this.copiarPortapapeles(this.SoloContenido(value));
      },
            
      comienzo(seleccionado) {
        return Servicio.FFechaHora(seleccionado.comienzo);
      },
      Borrar(seleccionado) {
        //console.debug(id);
        swal.fire({
          title: '¿Estas seguro?',
          text: `Estas por borrar una reunión (${seleccionado.etiqueta}).`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn  btn-danger ',
            cancelButton: 'btn '
          },
          confirmButtonText: 'Si, Borrarlo',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then((resultado) => {
          console.debug(resultado);
          if (resultado.isConfirmed)

            Servicio.delete(this, seleccionado.id, (response) => {
              //console.debug(response.data);
              swal.fire({
                title: 'Borrado',
                text: 'El registro se ha borrado satisfactoriamente.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-fill'
                },
                buttonsStyling: false
              });
              this.Actualiza();
            });

        });
      },
      Editar(seleccionado) {
        //  console.debug(seleccionado);
        this.MostrarEdicion = false;
        this.Seleccionado = seleccionado;

        this.MostrarEdicion = true;
      },
      Ver(seleccionado) {
        //  console.debug(seleccionado);
        this.MostrarInvitacion = false;
        this.Seleccionado = seleccionado;

        this.MostrarInvitacion = true;
      },
      Compartir(seleccionado) {
     //   console.debug(seleccionado);
        this.MostrarCompartir = false;
        this.Seleccionado = seleccionado;

        this.MostrarCompartir = true;
      },
      OnFiltrar(filtro) {
        //     this.BaseGetsListado.Filtro = filtro;
        this.Actualiza();
      },
      Actualiza() {

        Servicio.gets(this, this.BaseGetsListado, (response) => {
          this.info = response.data;

        }, (MsgError, error) => {

        }, () => {
          this.cargando = false;

        });

      },
      copiarPortapapeles(ACompiar) {
     //   console.log(ACompiar)
        this.$copyText(ACompiar);
        this.$notify({
          type: 'success',
          title: 'Se ha copiado al portapapeles'
        })
      },
      copiar(reunio) {
        return "copiado" + JSON.stringify(reunio);
      },
      onCopy() {
        this.$notify({
          type: 'success',
          title: 'Se ha copiado al portapapeles'
        })
      }
    }
  }</script>


<style lang="scss" scoped>

  @media (min-width: 40em) {
    .card-columns-listado {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 60em) {
    .card-columns-listado {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 80em) {
    .card-columns-listado {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 100em) {
    .card-columns-listado {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
      orphans: 1;
      widows: 1;
    }
  }



  .card {
    display: inline-block; /*    // Don't let them vertically span multiple columns */
    width: 100%; /*    // Don't let their width change*/
  }
</style>

