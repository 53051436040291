<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         footerClasses="w-100"
         bodyClasses="align-items-center text-center w-100 "
         modalContentClasses="card  "
         headerClasses="card-header">


    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">Incremento de seguridad</h5>
      <h1 class="card-title">Registro de ingreso</h1>
    </div>
    <template>
      <div class="cuerpo-wizard-registro">
        <h4 v-if="Paso==1">Captura el <b>QR</b>.</h4>
        <qrcode-stream v-if="Paso==1" @decode="codeArrived" @init="onInit">
          <div class="loading-indicator" v-if="loadingQR">
            Iniciando...
          </div>
        </qrcode-stream>
        <Main-Cargando v-if="Paso==2" :cargando="cargando" :MsgError="MsgError">
          <div class="card-header px-3 pt-3">
            <h6>Nueva invitación por: {{Anfitrion}}</h6>
            <h3 class="mb-0 pb-0 text-break ">{{invitacion.etiqueta}}</h3>
            <h4 class="mb-0 pb-0 text-break" v-if="EsFechaValida">{{comienzo(invitacion)}}</h4>
            <badge v-if="!EsFechaValida" type="danger">{{comienzo(invitacion)}}</badge>
            <p class="mb-0 pb-0">Nota: {{invitacion.descripcion}}</p>

            <h4 v-if="invitacion.tipoVisita" class="mb-0">{{invitacion.tipoVisita.etiqueta}}</h4>
            <h5 v-if="invitacion.tipoVisita" class="mb-0">{{invitacion.tipoVisita.descripcion}}</h5>
          </div>
          <div class="  px-3 pt-3">
            <div class="text-muted ">Invitados: </div>
            <badge v-for="invitados in invitacion.invitados" type="info" class="mr-2">{{invitados}}</badge>
          </div>
        </Main-Cargando>

        <div v-if="Paso==4" class="px-0 py-1">
          <h4 class="text-muted">Capture unicamente los datos que se conozcan y verifique el nombre completamente.</h4>

          <MazInput v-model="datos.nombre"
                    placeholder="Nombre(s)"
                    autocomplete="autocompleteNombre"
                    required :loading="cargando" class="mb-2"
                    clearable>
            <!--    <i slot="icon-left"
      class="tim-icons icon-single-02"></i>-->
          </MazInput>
          <div class="row px-3 mb-2">
            <div class="col px-0">
              <MazInput v-model="datos.apellidoP"
                        placeholder="A. Paterno"
                        autocomplete="autocompleteApellidoP"
                        required :loading="cargando"
                        clearable>
              </MazInput>
            </div>
            <div class="col  px-0">
              <MazInput v-model="datos.apellidoM"
                        placeholder="A. Materno"
                        autocomplete="autocompleteApellidoM"
                        :loading="cargando"
                        clearable>
              </MazInput>
            </div>
          </div>

          <ValidationProvider name="email"
                              rules="email"
                              v-slot="{ passed, failed, errors }">
            <MazInput v-model="datos.correo" class=" mb-2"
                      placeholder="Correo personal"
                      autocomplete="autocompleteCorreo" :loading="cargando"
                      type="email" :error="failed"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </MazInput>

          </ValidationProvider>

          <MazPhoneNumberInput v-if="!cargando" v-model="datos.telefono" class=" mb-2"
                               :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                               :preferred-countries="MazPhoneNumber.PaisesPreferidos"
                               showCodeOnList @update="telefonoValido = $event" :error="telefonoValido!=null && datos.telefono != null && datos.telefono != '' && !telefonoValido.isValid"
                               :loading="cargando" placeholder="# Teléfono" />

          <MazInput v-if="CapturaEmpresa" v-model="datos.empresa"
                    placeholder="Empresa de trabajo"
                    autocomplete="autocompleteEmpresa"
                    required :loading="cargando"
                    clearable />
        </div>

        <img v-if="Paso==3" class="w-50 pt-5" :src="ImgSelfie" alt="..." @click="MostrarTomarSelfie=true" />
        <div v-if="Paso==3">
          <base-button icon :loading="cargando" @click="MostrarTomarSelfie=true"
                       round>
            <i class="fas fa-camera"></i>
          </base-button>
        </div>

        <img v-if="Paso==5" class="w-50 pt-5" :src="ImgID" alt="..." @click="MostrarTomarID=true" />
        <div v-if="Paso==5">
          <base-button icon :loading="cargando" @click="MostrarTomarID=true"
                       round>
            <i class="fas fa-camera"></i>
          </base-button>
        </div>

        <img v-if="Paso==6&&cargando" class="w-50 pt-5" src="/img/Analizando.svg" alt="..." />
        <img v-if="Paso==6&&!cargando&&Coincide&&invitacion.id" class="w-50 pt-5" src="/img/Coincide.svg" alt="..." />
        <img v-if="Paso==6&&!cargando&&!invitacion.id" class="w-50 pt-5" :src="datos.urlEnrolamiento" alt="..." />
        <h6 v-if="Paso==6&&!cargando&&!invitacion.id">{{datos.nombre}} {{datos.apellidoP}}</h6>
        <img v-if="Paso==6&&!cargando&&!Coincide&&invitacion.id" class="w-50 pt-5" src="/img/NoCoincide.svg" alt="..." />
      </div>
    </template>

    <div class="pt-4 align-bottom  text-center pasos-wizard-registro ">
      <div class=" ">

        <h4 v-if="Paso==2&&EsFechaValida">Solicita su <b>identificación oficial</b> y <b>verifica</b> que el invitado se encuentre en la lista.</h4>
        <h4 v-if="Paso==2&&!EsFechaValida">La <b>fecha de la invitación</b> no es válida.</h4>
        <base-checkbox v-if="Paso==22" class="text-left pb-2" v-model="terminos">
          <p> Mis <b>datos son correctos</b> y estoy de acuerdo con los <a href="https://www.eclock.com.mx/aviso-de-privacidad" target="_blank">terminos y condiciones</a>.</p>
        </base-checkbox>

        <!--<h4 v-if="Paso==2">Tus datos serán <b>validados</b> para ingresar al complejo.</h4>-->
        <h4 v-if="Paso==3">Tómale una <b>foto clara</b> y sin sombras.</h4>
        <h4 v-if="Paso==5">Toma una foto de su identificación <b>oficial</b> donde se vea claramente el <b>nombre completo</b> y <b>rostro</b>.</h4>

        <h4 v-if="Paso==6&&cargando">Espera <b>analizando datos</b>.</h4>
        
        <h4 v-if="Paso==6&&!cargando&&Coincide">¿Qué acción desea hacer?.</h4>
        <h4 v-if="Paso==6&&!cargando&&!Coincide">Los datos no coinciden <b>intenta nuevamente</b>.</h4>
      </div>
      <div class="  d-flex justify-content-center">
        <MazStepper v-model="Paso" class="p-0 w-25 " disabled-previous-steps disabled-next-steps
                    :steps="Pasos" />
      </div>
    </div>
    <template slot="footer">




      <div v-if="!Finalizar||!Coincide" class="w-100 p-2 d-flex justify-content-between">
        <base-button v-if="Paso==1" link @click="Cerrar()" class="text-muted">
          < Cancelar
        </base-button>
        <base-button v-else link @click="Regresar()" class="text-muted">
          < Regresar
        </base-button>

        <base-button type="primary" @click="Siguiente()" :disabled="!PuedeContinuar" :loading="loading||cargando">
          Continuar >
        </base-button>

      </div>
      <div v-else class="w-100 p-2 d-flex justify-content-between">
        <base-button type="primary" @click="RegistrarSalida()" :disabled="!PuedeContinuar" :loading="loading||cargando">
          Registrar Salida
        </base-button>


        <base-button type="primary" @click="RegistrarEntrada()" :disabled="!PuedeContinuar" :loading="loading||cargando">
          Registrar Entrada
        </base-button>

      </div>
      <!--
      <div class="align-items-center text-center w-100 mb-3">
        <base-button type="primary" @click="">
          Continuar
        </base-button>
        <router-link :to="{}" class="d-block mt-2" @click.native="Cerrar()">
          <i class="fas fa-chevron-left"> </i> Cancelar
        </router-link>

      </div>
      -->
    </template>

    <TomaFoto v-if="MostrarTomarSelfie" :Mostrar.sync="MostrarTomarSelfie" Titulo="Captura de foto" Categoria="registro" OcultarSeleccionar AutoGuardado
                 v-on:guardado="OnGuardarFoto" v-on:guardar="OnGuardarFoto" :quitarModalOpen="false"></TomaFoto>

    <TomaFoto v-if="MostrarTomarID" :Mostrar.sync="MostrarTomarID" Titulo="Captura de foto" Categoria="registro" OcultarSeleccionar AutoGuardado
             v-on:guardado="OnGuardarFoto" v-on:guardar="OnGuardarFotoID" :quitarModalOpen="false"></TomaFoto>

    <MenuModal v-if="MostrarMenuModal" :Mostrar.sync="MostrarMenuModal" Ayuda="" v-model="Menu" @click="OnClickMenu"/>
  </modal>
</template>

<script>
  //import { QrcodeStream } from 'vue-qrcode-reader'
  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";
  import Servicio from '@/api-services/reuniones.service';
  import Accesos from '@/api-services/accesos.service';
  import Ubicaciones from '@/api-services/ubicaciones.service';
  import Personas from '@/api-base/personas.service';
  import Base from '@/api-base/base';
  import Invitacion from './Invitacion.comp.vue';
  import TomaFoto from '@/Vistas-base/Blobs/TomaFoto.comp.vue';
  import { Badge } from 'src/components'
  const MenuModal = () => import(/* webpackChunkName: "accesos" */ '@/Vistas/Ubicaciones/Menu.modal.vue');
  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');
  import { MazStepper } from 'maz-ui'

  extend("email", email);
  //extend("phone", phone);
  extend("required", required);
  extend("min", min);

  export default {
    components: {
      TomaFoto,
      Invitacion,
      MazStepper,
      Badge,
      MenuModal,
      //    QrcodeStream
    },
    name: 'Reuniones-Registro-modal',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Reuniones' },
      Titulo: { type: String, default: 'Nueva' },
      Ayuda: { type: String, default: 'Invita a personas a tu reunión.' },


      value: { type: Object, default: null },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
      Cancelar: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {
          id: '',
          nombre: '',
          apellidoP: '',
          apellidoM: '',
          correo: '',
          telefono: '',
          llave: '',
          empresa: ''
        },
        persona: {},
        selfie: {},
        invitacion: {},
        ubicacion: {},


        loadingQR: false,
        scanned: '',

        Cargado: false,

        telefonoValido: false,
        Modal: false,
        Paso: 1,
        Pasos: 6,
        cargando: false,
        MsgError: '',
        errorMsg: '',
        MostrarTomarSelfie: false,
        MostrarTomarID: false,
        ImgSelfie: '/img/FaceID.svg',
        ArchivoSelfie: null,
        ImgID: '/img/ID.svg',
        ArchivoID: null,
        Coincide: false,
        terminos: false,

        MostrarMenuModal: false,
        Menu: [],

      }
    },
    computed: {
      CapturaEmpresa() {
        if (this.invitacion == null)
          return false;
        if (this.invitacion.ubicacion == null)
          return false;
        if (this.invitacion.ubicacion.campos == null)
          return false;
        var R = this.invitacion.ubicacion.campos.filter(x => x == "Empresa");
        return (R.length > 0)

      },
      Cubrebocas() {
        return this.TieneCubrebocas(this.selfie);
      },
      Anfitrion() {

        if (this.invitacion.usuaria == null)
          return "-";
        return this.invitacion.usuaria.etiqueta;
      },
      LinkID() {
        var Ruta = this.scanned.split("?")[0];
        var Partes = Ruta.split("/");
        var ID = Partes[Partes.length - 1];
        return ID;
      },
      PersonaID() {
        let url = new URL(this.scanned);
        let params = new URLSearchParams(url.search);
        return params.get("PersonaID");
      },
      MazPhoneNumber() {
        return Base.MazPhoneNumber;
      },
      EsFechaValida() {
        return Servicio.EsFechaValida(this.invitacion.comienzo);
      },
      PuedeContinuar() {
        switch (this.Paso) {
          case 1:
            return false;
          case 2:
            return this.EsFechaValida;
            break;
          case 3:
            if (this.ArchivoSelfie == null)
              return false;
            break;
          case 4:
            if (this.datos.nombre == "" || this.datos.nombre == null)
              return false;
            if (this.datos.apellidoP == "" || this.datos.apellidoP == null)
              return false;
            if (!this.telefonoValido.isValid && this.datos.telefono != "" && this.datos.telefono != null)
              return false;
            if (!email.validate(this.datos.correo) && this.datos.correo != "" && this.datos.correo != null)
              return false;
            break;
          case 5:
            if (this.ArchivoID == null)
              return false;
            break;
        }
        return true;
      },
      Finalizar() {
        if (!this.PuedeContinuar)
          return false;
        if (this.cargando)
          return false;
        return (this.Paso == this.Pasos);
      },
      autocompleteNombre() {
        return `${this.$options.name}-Nombre`;
      },
      autocompleteApellidoP() {
        return `${this.$options.name}-ApellidoP`;
      },
      autocompleteApellidoM() {
        return `${this.$options.name}-ApellidoM`;
      },
      autocompleteCorreo() {
        return `${this.$options.name}-Correo`;
      },
    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;

    },
    created() {
    },
    methods: {
      codeArrived(event) {
        console.log(event);
        if (event.detail === undefined)
          this.scanned = event;
        else
          this.scanned = event.detail[0];
        this.Siguiente();
        //window.location.href = this.scanned;
        //this.$router.push(this.scanned);
      },
      async onInit(promise) {
        this.loadingQR = true
        try {
          await promise
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            this.error = "ERROR: you need to grant camera access permission"
          } else if (error.name === 'NotFoundError') {
            this.error = "ERROR: no camera on this device"
          } else if (error.name === 'NotSupportedError') {
            this.error = "ERROR: secure context required (HTTPS, localhost)"
          } else if (error.name === 'NotReadableError') {
            this.error = "ERROR: is the camera already in use?"
          } else if (error.name === 'OverconstrainedError') {
            this.error = "ERROR: installed cameras are not suitable"
          } else if (error.name === 'StreamApiNotSupportedError') {
            this.error = "ERROR: Stream API is not supported in this browser"
          } else if (error.name === 'InsecureContextError') {
            this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
          } else {
            this.error = `ERROR: Camera error (${error.name})`;
          }
        } finally {
          this.loadingQR = false
        }
      },
      OnGuardarFotoID(Archivo, Imagen) {
        console.debug("OnGuardarFoto");
        this.ImgID = Imagen;
        this.ArchivoID = Archivo;
        this.MostrarTomarID = false;
      },
      OnGuardarFoto(Archivo, Imagen) {
        console.debug("OnGuardarFoto");
        this.ImgSelfie = Imagen;
        this.ArchivoSelfie = Archivo;
        this.MostrarTomarSelfie = false;
      },
      Regresar() {
        this.Paso--;
      },
      Siguiente() {
        switch (this.Paso) {
          case 1:
            {
              var LinkID = this.LinkID;
              if (LinkID.substring(0, 3) == "Pe.") {
                //la predeterminada
                Ubicaciones.get(this, "NULL", (response) => {

                  this.ubicacion = response.data;

                }, () => { }, () => { });
                Personas.get(this, LinkID, (response) => {
                  this.invitacion = {
                  };
                  this.persona = this.datos = response.data;
                  this.Paso = 6;
                  this.Coincide = true;
                }, () => { }, () => { });
              }
              else
                Servicio.getConsulta(this, " ", LinkID, (response) => {
                  this.invitacion = response.data;

                }, () => { }, () => { });
            }
            break;
          case 2:

            break;
          case 3:
            if (this.invitacion.id == "")
              return;
            Servicio.SelfieIdentificarPatch(this, this.invitacion.id, this.PersonaID, this.ArchivoSelfie, (response) => {
              //this.datos.id = response.data.id;
              //var Telefono = response.data.telefono;
              //this.datos.telefono = response.data.telefono;
              this.datos = response.data.persona;
              //this.datos.telefono = Telefono;
              this.Cargado = true;
              this.persona = response.data.persona;
              this.selfie = response.data.selfie;


            }, (MsgError, Error) => {
              this.Paso = 3;
            }, () => {

            });
            break;
          case 4:
            //Si ya tiene INE
            if (this.datos.urlIdentificacion != null && this.datos.urlIdentificacion != "") {
              this.Paso++;
              this.Coincide = true;
            }
            else
              this.Coincide = false;
            Personas.put(this, this.datos.id, this.datos, (response) => {
              //Personas.Persona = this.datos;
              /*this.datos.id = response.data.id;
              this.datos.llave = response.data.llave;
              */
            }, (MsgError, Error) => {
              this.Paso = 4;
            }, () => {

            });
            break;
          case 5:
            if (this.datos.id == "")
              return;
            Personas.IdentificacionPatch(this, this.datos.id, 0, this.datos.llave, this.ArchivoID, (response) => {
              //this.datos.id = response.data.id;
              this.Coincide = true;
              // Personas.Persona = this.datos;

              Servicio.PostPersona(null, this.invitacion.id, {
                personaID: this.datos.id,
                llave: this.datos.llave,
              }, (response) => { }, (MsgError, Error) => {
                //this.Paso = 3;
              }, () => {

              });

            }, (MsgError, Error) => {
              if (MsgError == "No Coincide") {
                this.Coincide = false;
                return;
              }
              this.Paso = 5;
            }, () => {

            });
            break;
          case 6:

            break;
        }
        this.Paso++;
      },
      Cerrar() {
        this.datos = {
          etiqueta: '', descripcion: ''
        };
        this.Modal = false;
      },
      OnFinalizar() {
        this.Cerrar();
      },
      TieneCubrebocas(Rostro) {
        if (Rostro == null || Rostro.rostros == null)
          return false;
        var R = false;
        Rostro.rostros.forEach(rostro => {
          if (rostro.faceAttributes.mask != null && rostro.faceAttributes.mask.type == "faceMask")
            return true;
          if (rostro.faceAttributes.accessories != null)
            rostro.faceAttributes.accessories.forEach(a => {
              if (a.type == "mask" && a.confidence > 0.6) {
                R = true;
              }
            });
        });
        return R;
      },
      Registrar(TipoAcceso, Menu) {
        var Nombre = "";
        if (Menu != null)
          Nombre = Menu.nombre;
        var Selfie = this.selfie;
        if (Selfie == null) {
          Selfie = this.persona.enrolamiento;
        }
        if (this.invitacion.id == undefined) {
          Accesos.post(this, {
            personaID: this.persona.id,
            tipoAcceso: TipoAcceso,
            fechaHora: moment().format(),
            ubicacionID: this.ubicacion.id,
            menu: Nombre
          }, (response) => {

            if (Menu != null && Menu.url != null && Menu.url != "") {
              const link = document.createElement('a');
              link.href = Menu.url;
              link.target = "_blank";
              document.body.appendChild(link);
              link.click();
            }
            this.Cerrar();
          }, (MsgError, Error) => {
            /*if (MsgError == "No Coincide") {
              this.Coincide = false;
              return;
            }
            this.Paso = 5;*/
          }, () => {

          });
        }
        else
          Servicio.PostAccion(this, this.invitacion.id, {
            personaID: this.persona.id,
            tipoAcceso: TipoAcceso,
            urlFoto: Selfie.urlThumb,
            urlCrop: Selfie.urlCrop,
            fechaHora: moment().format(),
            cubrebocas: this.TieneCubrebocas(Selfie)
          }, (response) => {
            this.Cerrar();
          }, (MsgError, Error) => {
            /*if (MsgError == "No Coincide") {
              this.Coincide = false;
              return;
            }
            this.Paso = 5;*/
          }, () => {

          });
      },
      RegistrarEntrada() {
        var ME = this.ubicacion.menuEntrada;
        console.debug(ME);
        if (ME != null && ME.length > 0) {
          this.MostrarMenuModal = true;
          this.Menu = ME;
          return;
        }
        this.Registrar("Entrada");
      },
      RegistrarSalida() {
        this.Registrar("salida");
      },
      comienzo(seleccionado) {
        return Servicio.FFechaHora(seleccionado.comienzo);
      },
      OnClickMenu(Opcion) {
        console.debug(Opcion);
        this.Registrar("Entrada", Opcion);
        this.MostrarMenuModal = false;
      }
    }
  }
</script>

<style lang="scss">
  .cuerpo-wizard-registro {
    min-height: 250px;
  }

  .pasos-wizard-registro {
    min-height: 125px;
  }
</style>

<style scoped>
button {
  margin-bottom: 20px;
}

.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
</style>
