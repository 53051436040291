<template>
  <div>

    <base-header class="header py-5 d-flex align-items-center mb-3"
                 style="height: 40vh;  background-image: url(img/home-fondo.jpg); background-size: cover; background-position: center top; ">
      <!-- Mask -->
      <!--  <div class="mask bg-gradient-gray-dark opacity-4" />
    <!-- <div class="mask bg-gradient-success opacity-4"></div>
    -->
      <!-- Header container -->
      <div class=" align-items-center ">
        <div class="row">
          <div class="col-lg-7 col-md-10">

            <h3 class="display-3 text-white mt-5 m-0">Listado de Usuarios </h3>
            <p class="text-white mt-0 ">Contiene la lista de las personas que podrán crear reuniones, invitar a otros o validar su ingreso</p>

            <!-- <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
        <b-row align-h="end" class="m-2">
          <base-button icon
                       round :loading="cargando" @click="Actualiza">
            <i class="fas fa-sync"></i>
          </base-button>

          <base-button icon @click="MostrarFiltrar=true"
                       round :loading="cargando || MsgError.length>0">
            <i class="fas fa-filter"></i>
          </base-button>



        </b-row>
      </div>

    </base-header>

    <Main-Cargando :cargando="cargando" :MsgError="MsgError" class="mx-2">
      <div v-if=" info " class="card-columns-listado ">
        <div v-for="registro in info" v-bind:key="registro.usuarioID" class="card   mb-3"  >

          <router-link :to="{}" class="pull-right p-3" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fa fa-times"></i></router-link>
          <router-link :to="{}" @click.native="Editar(registro)">
            <div class="card-header px-3 pt-3">
              <img :src="registro | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
              <div class="">
                <h3 class="mb-0 pb-0">{{registro.nombre}}</h3>
                <h5 class="mb-0 pb-0">{{registro.suscripcionesUsuario | FormatSuscrip}}</h5>
              </div>
            </div>

            <hr class="mt-2" />
            <div class="text-center ">
            </div>
            <hr class="mt-3 mb-1" />
            <div class="px-3 pb-3">
              <!--<hr class="my-2" />-->
              <!--Se usa para que queden alineados el boton y la palabra invitados-->
              <hr class="my-0" width="0px" />

              <!-- <router-link :to="{}" class="float-right px-0  align-bottom" data-effect="fadeOut" @click.native.stop="Ver(registro)"><i class="fas fa-eye fa-2x"></i></router-link>
          -->
              <router-link :to="{}" class="float-right px-0  align-bottom" data-effect="fadeOut" @click.native.stop="Compartir(registro)"><i class="fas fa-share fa-2x"></i></router-link>

              <div class="text-muted mt-3">Invitados: </div>
              <badge v-for="invitados in registro.invitados" type="info" class="mr-2">{{invitados}}</badge>
            </div>
          </router-link>
        </div>
      </div>
      <base-pagination v-if="!cargando && info && info.totalPaginas>1" :pageCount="info.totalPaginas" v-model="Pagina"></base-pagination>
    </Main-Cargando>
    <div class=" mt-2">
      <section v-if="errored">
        <p>Lo sentimos, no es posible obtener la información en este momento, por favor intente nuevamente mas tarde</p>
      </section>
      <section v-else>
        <div v-if="loading">Cargando...</div>
        <div v-else class="row">
          <div v-for="usuario in info" v-bind:key="usuario.usuarioID"
               class="usuario col-xl-3 col-lg-6 mb-2 p-1">


            <div class="card  shadow ">
              <div class="card-header border-0   p-2">
                <!-- <div class="row">
                  <div class="col-lg-3 col-sm-2 col-3">
                      <img :src="usuario | Foto" class="rounded-circle " width="100%">
                  </div>
                  <div class="col-lg-9 col-sm-10 col-9">
                      <h3 class="mb-0 pb-0">{{usuario.nombre}}</h3>
                      <h5 class="mb-0 pb-0">{{usuario.suscripcionesUsuario | FormatSuscrip}}</h5>
                  </div>
              </div>
                 -->
                <img :src="usuario | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
                <div class="">
                  <h3 class="mb-0 pb-0">{{usuario.nombre}}</h3>
                  <h5 class="mb-0 pb-0">{{usuario.suscripcionesUsuario | FormatSuscrip}}</h5>
                </div>
              </div>
              <div class="card-body p-2">

                <div class="text-muted">


                  <span class="text-truncate mr-2 ">
                    <i class="fa fa-mobile" aria-hidden="true" />
                    {{ usuario.telefono }}
                  </span>
                  <span class="text-truncate" style="display: block">
                    <i class="ni ni-email-83 " />

                    {{ usuario.correo }}
                  </span>

                </div>
                <div class="d-flex justify-content-end">
                  <base-button size="sm" type="danger" @click="Borrar(usuario)">Borrar</base-button>
                </div>
              </div>


            </div>

          </div>
        </div>

      </section>
    </div>

    <div class="fixed-bottom text-center  mb-2" style="width:100%">

      <base-dropdown size="lg" title-classes="btn btn-round btn-icon btn-fab btn-primary my-1 btn-lg" icon="fas fa-plus fa-3x" type="primary"
                     :loading="cargando" menuOnRight direction="up">

        <router-link class="dropdown-item " :to="{ path: 'usuarios/nuevo', query: { perfilID: 1 }}">
          <i class="fa fa-user-plus" aria-hidden="true"></i>Administrador
        </router-link>

        <router-link class="dropdown-item " :to="{ path: 'usuarios/nuevo', query: { perfilID: 2 }}">
          <i class="fa fa-user-plus" aria-hidden="true"></i>Impresor
        </router-link>
        <router-link class="dropdown-item " :to="{ path: 'usuarios/nuevo', query: { perfilID: 3 }}">
          <i class="fa fa-user-plus" aria-hidden="true"></i>Diseñador
        </router-link>
        <router-link class="dropdown-item " :to="{ path: 'usuarios/nuevo', query: { perfilID: 4 }}">
          <i class="fa fa-user-plus" aria-hidden="true"></i>Capturista
        </router-link>
        <router-link class="dropdown-item " :to="{ path: 'usuarios/nuevo', query: { perfilID: 5 }}">
          <i class="fa fa-user-plus" aria-hidden="true"></i>AutoRegistro
        </router-link>
      </base-dropdown>
    </div>

    <UsuariosBorrar :Mostrar.sync="BorrarUsuarioMostrar"
                    v-on:guardado="OnBorrado" v-on:error="OnError"
                    :UsuarioID="UsuarioID"
                    :SuscripcionID="SuscripcionID"
                    :SuscripcionEtiqueta="SuscripcionEtiqueta"></UsuariosBorrar>

  </div>
</template>
<script>
  import Usuarios from '@/api-base/usuarios.service';
  import UsuariosBorrar from '@/Vistas-base/Usuarios/Borrar.comp.vue';

  import BaseHeader from 'src/components/BaseHeader';

  export default {


    name: 'UsuariosListado',
    data() {
      return {
        BorrarUsuarioMostrar: false,
        UsuarioID: -1,
        SuscripcionID: -1,
        SuscripcionEtiqueta: '',

        MsgError: '',
        Ayuda: '',

        info: null,
        loading: true,
        cargando: false,
        errored: false
      }
    },
    components: {
      UsuariosBorrar,
      BaseHeader
    },
    filters: {
      Foto(Usuario) {
        return Usuarios.FotoUsuarioURL(Usuario);
      },
      /*  /// <summary>
        /// Puede hacer todo lo del sistema
        /// </summary>
        Administrador,
        /// <summary>
        /// Solo puede imprimir registros con los diseños previamente creados
        /// </summary>
        Impresor,
        /// <summary>
        /// Crea y Edita diseños
        /// </summary>
        Diseñador,
        /// <summary>
        /// Puede capturar fotos y datos de otros
        /// </summary>
        Capturista,
        /// <summary>
        /// solo podrá capturar sus datos
        /// </summary>
        AutoRegistro,
        /// <summary>
        /// Usuario con solo privilegios para ser usado como printserver
        /// </summary>
        PrintServer*/
      FormatSuscrip(SuscripcionesUsuario) {
        var R = "";
        switch (SuscripcionesUsuario[0].perfilID) {
          default:
            R = "Desconocido";
            break;
          case 1:
            R = "Administrador";
            break;
          case 2:
            R = "Impresor";
            break;
          case 3:
            R = "Diseñador";
            break;
          case 4:
            R = "Capturista";
            break;
          case 5:
            R = "AutoRegistro";
            break;
          case 6:
            R = "PrintServer";
            break;
        }
        return `${R} - ${SuscripcionesUsuario[0].suscripcion.etiqueta}`;
      },
    },
    mounted() {
      this.Actualiza();
    },
    methods: {
      Actualiza() {
        try {

          Usuarios.getUsuarios().then((response) => {
            if (response.data === undefined) {
              this.errored = true;
              return;
            }
            console.debug(response.data);
            this.info = response.data;
            this.loading = false;

          }).catch((error) => {
            if (error.response != undefined && error.response.data != undefined && error.response.data.error != undefined && error.response.data.error.mensaje != undefined)
              this.$notify({ type: 'danger', title: error.response.data.error.mensaje });
            else
              this.$notify({ type: 'danger', title: 'no se pudo cargar la información, intente nuevamente' });
            console.error(error);
            this.errored = true;
          });
        }
        catch (error) {
          this.$notify({ type: 'danger', title: 'no se pudo cargar la información, intente nuevamente' });
          console.error(error);
          this.errored = true;
        }
      },
      Borrar(Usuario) {
        // this.BorrarUsuarioMostrar = false;
        this.UsuarioID = Usuario.usuarioID;
        this.SuscripcionID = Usuario.suscripcionesUsuario[0].suscripcionID;
        this.SuscripcionEtiqueta = Usuario.suscripcionesUsuario[0].suscripcion.etiqueta;
        this.BorrarUsuarioMostrar = true;
        //console.debug(Usuario);
      },
      OnBorrado() {
        this.Actualiza();
      },
      OnError() {

      },
    }
  }</script>
<style></style>
