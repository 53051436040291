<template>

    <card class="card-user mb-2" footerClasses="py-0">

      <div class="card-text ">
        <div class="d-flex justify-content-end ">


        </div>
      </div>
      <div class="author">

        <div class="block block-one"></div>
        <div class="block block-two"></div>
        <div class="block block-three"></div>
        <div class="block block-four">

        </div>
        <router-link :to="{}" class="" @click.native.stop="$emit('abrir',value)">
          <img class="avatar avatarColaboradorList mb-0" :src="mFotoURL" alt="..." />

          <h4 class=" mb-1">
            {{value.etiqueta}}
            <router-link v-if="!value.borrado" :to="{}" class=" p-2" data-effect="fadeOut" @click.native.stop="$emit('borrar',value)"><i class="fa fa-times"></i></router-link>
            <router-link v-if="value.borrado" :to="{}" class=" p-2" data-effect="fadeOut" @click.native.stop="$emit('activar',value)"><i class="fas fa-check"></i></router-link>
          </h4>
          <h4 class="description mb-0">    <small>    {{value.tipoUsuaria | FormatoTipoUsuaria}}</small></h4>
        </router-link>

        <a :href="`mailto:${value.correo}`" v-if="value.correo">
          <h5  class="pb-2">
            <i class="fas fa-envelope mr-2"></i>
            <span>{{value.correo}}</span>
          </h5>
        </a>
        <a :href="`tel:${value.telefono}`" v-if="value.telefono" >
          <h5  class="pb-2">
            <i class="fas fa-mobile mr-2"></i>
            <span>{{value.telefono}}</span>
          </h5>
        </a>
 
        <!-- <router-link :to="{}" class=" p-2" data-effect="fadeOut" @click.native.stop="LeerMas=!LeerMas">{{LeerMas?'Leer menos':'Leer más'}}</router-link>-->


      </div>

      <div v-if="LeerMas" slot="footer" class="button-container text-left">

        <!--<h5 v-if="value.puesto && value.puesto.etiqueta" class=" @click.native.stop="LeerMas=!LeerMas" mb-0">Puesto: {{value.puesto.etiqueta}}</h5>-->
 



      </div>

    </card>

</template>
<script>

  import { Badge } from 'src/components'
  import Servicio from '@/api-services/usuarias.service';

  export default {
    props: {
      value: Object

    },
    components: {
      Badge,
    },
    data() {
      return {
        Datos: null,
        loading: true,
        cargando: false,

        errored: false,

        errorMsg: "",

        LeerMas: true,
      }
    },
    watch: {


    },
    methods: {

      OnError() {

      },
      OnBorrado() {
        this.$router.push({ name: 'login', query: { from: this.$route.query.from } });
      }
      ,

    },
    filters: {
      Tipo(Registro) {
        if (Registro.reglaVacaciones) {
          if (Registro.saldoVacaciones > 0 && Registro.saldoVacaciones > Registro.reglaVacaciones.saldoMinimo)
            return 'success';
          if (Registro.saldoVacaciones <= Registro.reglaVacaciones.saldoMinimo)
            return 'danger';
          return 'warning';
        }
        if (Registro.saldoVacaciones > 0)
          return 'success';
        return 'warning';
      },
 
        Foto(Usuario) {
          return Servicio.FotoURL(Usuario);
        },

        FormatoTipoUsuaria(TipoUsuaria) {
          return Servicio.sTipoUsuaria2Etiqueta(TipoUsuaria);

        },
      },
    computed: {
      mFotoURL() {
        return Servicio.FotoURL(this.value);;
      },
      PTEtiqueta() {
        var PTCompletado = this.PTCompletado;
        if (PTCompletado <= 40) {
          return "Perfil Básico";
        }
        if (PTCompletado <= 60) {
          return "Perfil Incompleto";
        }
        if (PTCompletado <= 80) {
          return "Perfil Util";
        }
        return "Perfil Compelto :)";
      },
      PTTipo() {
        var PTCompletado = this.PTCompletado;
        if (PTCompletado <= 40) {
          return "danger";
        }
        if (PTCompletado <= 60) {
          return "warning";
        }
        if (PTCompletado <= 80) {
          return "primary";
        }
        return "success";
      },
      UrlCorreo() {
        return `mailto:${this.value.correo}`;
      },
      UrlTelefono() {
        return `tel:${this.value.telefono}`;
      },

      TelefonoFormat() {
        return this.value.Telefono;
        //return Base.formatPhoneNumber(this.Telefono);
      }
    },
  };
</script>
<style lang="scss" scoped>
  .avatarColaboradorList {
    width: 150px !important;
    height: 150px !important;
  }

  .author {
      h5 {
        margin-bottom: 0px !important;
      }
  }
</style>
