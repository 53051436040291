<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         footerClasses="w-100"
         bodyClasses="align-items-center text-center w-100 "
         modalContentClasses="card  "
         headerClasses="card-header">


    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">Preregistro para ahorrar tiempo</h5>
      <h1 class="card-title">Incremento de seguridad</h1>
    </div>
    <template>
      <div class="cuerpo-wizard-pre-registro">
        <img v-if="Paso==1" class="w-50 py-5" src="/img/Seguridad.svg" alt="..." />
        <div v-if="Paso==2" class="px-0 py-1">
          <h4 class="text-muted">Tus datos serán <b>validados</b> para ingresar al complejo.</h4>

          <MazInput v-model="datos.nombre"
                    placeholder="Nombre(s)"
                    autocomplete="autocompleteNombre"
                    required :loading="cargando" class="mb-2"
                    clearable>
            <!--    <i slot="icon-left"
    class="tim-icons icon-single-02"></i>-->
          </MazInput>
          <div class="row px-3 mb-2">
            <div class="col px-0">
              <MazInput v-model="datos.apellidoP"
                        placeholder="A. Paterno"
                        autocomplete="autocompleteApellidoP"
                        required :loading="cargando"
                        clearable>
              </MazInput>
            </div>
            <div class="col  px-0">
              <MazInput v-model="datos.apellidoM"
                        placeholder="A. Materno"
                        autocomplete="autocompleteApellidoM"
                        :loading="cargando"
                        clearable>
              </MazInput>
            </div>
          </div>

          <ValidationProvider name="email"
                              rules="email|required"
                              v-slot="{ passed, failed, errors }">
            <MazInput v-model="datos.correo" class=" mb-2"
                      placeholder="Correo personal"
                      autocomplete="autocompleteCorreo" :loading="cargando"
                      type="email" :error="failed" required
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </MazInput>

          </ValidationProvider>

          <MazPhoneNumberInput v-model="datos.telefono"
                               :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                               :preferred-countries="MazPhoneNumber.PaisesPreferidos" required
                               showCodeOnList @update="telefonoValido = $event" :error="telefonoValido!=null && datos.telefono != null && datos.telefono != '' && !telefonoValido.isValid"
                               :loading="cargando" placeholder="# Teléfono" class="mb-2"/>

          <MazInput v-if="CapturaEmpresa" v-model="datos.empresa"
                    placeholder="Empresa de trabajo"
                    autocomplete="autocompleteEmpresa"
                    required :loading="cargando" 
                    clearable>
            <!--    <i slot="icon-left"
    class="tim-icons icon-single-02"></i>-->
          </MazInput>

        </div>

        <img v-if="Paso==3" class="w-50 pt-5" :src="ImgSelfie" alt="..." @click="MostrarTomarSelfie=true" />
        <div v-if="Paso==3">
          <base-button icon :loading="cargando" @click="MostrarTomarSelfie=true"
                       round>
            <i class="fas fa-camera"></i>
          </base-button>
        </div>

        <img v-if="Paso==4" class="w-50 pt-5" :src="ImgID" alt="..." @click="MostrarTomarID=true" />
        <div v-if="Paso==4">
          <base-button icon :loading="cargando" @click="MostrarTomarID=true"
                       round>
            <i class="fas fa-camera"></i>
          </base-button>
        </div>

        <img v-if="Paso==5&&cargando" class="w-50 pt-5" src="/img/Analizando.svg" alt="..." />
        <img v-if="Paso==5&&!cargando&&Coincide" class="w-50 pt-5" src="/img/Coincide.svg" alt="..." />
        <img v-if="Paso==5&&!cargando&&!Coincide" class="w-50 pt-5" src="/img/NoCoincide.svg" alt="..." />
      </div>
    </template>

    <div class="pt-4 align-bottom  text-center pasos-wizard-pre-registro ">
      <div class=" ">
        <h4 v-if="Paso==1">Para un <b>acceso seguro</b> te registraremos <b>validando tu identidad</b>.</h4>
        <base-checkbox v-if="Paso==2" class="text-left pb-2" v-model="terminos">
          <p> Mis <b>datos son correctos</b> y estoy de acuerdo con los <a href="https://www.eclock.com.mx/aviso-de-privacidad" target="_blank">terminos y condiciones</a>.</p>
        </base-checkbox>

        <!--<h4 v-if="Paso==2">Tus datos serán <b>validados</b> para ingresar al complejo.</h4>-->
        <h4 v-if="Paso==3">Tómate una <b>foto clara</b> y sin sombras.</h4>
        <h4 v-if="Paso==4">Toma una foto de tu identificación <b>oficial</b> donde se vea claramente tu <b>nombre completo</b> y <b>rostro</b>.</h4>

        <h4 v-if="Paso==5&&cargando">Espera <b>analizando datos</b>.</h4>
        <h4 v-if="Paso==5&&!cargando&&Coincide">Tus datos se han <b>guardado</b>, si existe alguna novedad te contactaremos.</h4>
        <h4 v-if="Paso==5&&!cargando&&!Coincide">Los datos no coinciden <b>intenta nuevamente</b>.</h4>
      </div>
      <div class="  d-flex justify-content-center">
        <MazStepper v-model="Paso" class="p-0 w-25 " disabled-previous-steps disabled-next-steps
                    :steps="Pasos" />
      </div>
    </div>
    <template slot="footer">




      <div v-if="!Finalizar" class="w-100 p-2 d-flex justify-content-between">
        <base-button v-if="Paso==1" link @click="Cerrar()" class="text-muted">
          < Cancelar
        </base-button>
        <base-button v-else link @click="Regresar()" class="text-muted">
          < Regresar
        </base-button>

        <base-button type="primary" @click="Siguiente()" :disabled="!PuedeContinuar" :loading="loading||cargando">
          Continuar >
        </base-button>

      </div>
      <div v-else class="w-100 p-2 d-flex justify-content-end">
        <base-button type="primary" @click="OnFinalizar()" :disabled="!PuedeContinuar" :loading="loading||cargando">
          Finalizar
        </base-button>
      </div>
      <!--
      <div class="align-items-center text-center w-100 mb-3">
        <base-button type="primary" @click="">
          Continuar
        </base-button>
        <router-link :to="{}" class="d-block mt-2" @click.native="Cerrar()">
          <i class="fas fa-chevron-left"> </i> Cancelar
        </router-link>

      </div>
      -->
    </template>

    <TomaFoto v-if="MostrarTomarSelfie" :Mostrar.sync="MostrarTomarSelfie" Titulo="Captura de foto" Categoria="Pre-registro" OcultarSeleccionar AutoGuardado
                 v-on:guardado="OnGuardarFoto" v-on:guardar="OnGuardarFoto"  :quitarModalOpen="false"></TomaFoto>

    <TomaFoto v-if="MostrarTomarID" :Mostrar.sync="MostrarTomarID" Titulo="Captura de foto" Categoria="Pre-registro" OcultarSeleccionar AutoGuardado
             v-on:guardado="OnGuardarFoto" v-on:guardar="OnGuardarFotoID" :quitarModalOpen="false"></TomaFoto>

  </modal>
</template>

<script>
  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";
  import Servicio from '@/api-services/reuniones.service';
  import Personas from '@/api-base/personas.service';
  import Base from '@/api-base/base';
  import Invitacion from './Invitacion.comp.vue';
  import TomaFoto from '@/Vistas-base/Blobs/TomaFoto.comp.vue';
  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');
  import { MazStepper } from 'maz-ui'

  extend("email", email);
  //extend("phone", phone);
  extend("required", required);
  extend("min", min);

  export default {
    components: {
      TomaFoto,
      Invitacion,
      MazStepper,

    },
    name: 'Reuniones-PreRegistro-modal',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Reuniones' },
      Titulo: { type: String, default: 'Nueva' },
      Ayuda: { type: String, default: 'Invita a personas a tu reunión.' },


      value: { type: Object, default: null },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
      Cancelar: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {
          id: '',
          nombre: '',
          apellidoP: '',
          apellidoM: '',
          correo: '',
          telefono: '',
          llave: '',
          empresa:''
        },
        telefonoValido: false,
        Modal: false,
        Paso: 1,
        Pasos: 5,
        cargando: false,
        errorMsg: '',
        MostrarTomarSelfie: false,
        MostrarTomarID: false,
        ImgSelfie: '/img/FaceID.svg',
        ArchivoSelfie: null,
        ImgID: '/img/ID.svg',
        ArchivoID: null,
        Coincide: false,
        terminos: false,
        campos: {},
      }
    },
    computed: {
      CapturaEmpresa() {
        if (this.campos.empresa != undefined && this.campos.empresa)
          return true;
        if (this.value == null)
          return false;
        if (this.value.ubicacion == null)
          return false;
        if (this.value.ubicacion.campos == null)
          return false;
        var R = this.value.ubicacion.campos.filter(x => x == "Empresa");
        return (R.length > 0)

      },
      MazPhoneNumber() {
        return Base.MazPhoneNumber;
      },
      PuedeContinuar() {
        switch (this.Paso) {
          case 2:
            if (this.datos.nombre == "")
              return false;
            if (this.datos.apellidoP == "")
              return false;
            if (!this.telefonoValido.isValid)
              return false;
            if (!email.validate(this.datos.correo))
              return false;
            if (this.terminos == false)
              return false;
            break;
          case 3:
            if (this.ArchivoSelfie == null)
              return false;
            break;
          case 4:
            if (this.ArchivoID == null)
              return false;
            break;
        }
        return true;
      },
      Finalizar() {
        if (!this.PuedeContinuar)
          return false;
        if (this.cargando)
          return false;
        return (this.Paso == this.Pasos);
      },
      autocompleteNombre() {
        return `${this.$options.name}-Nombre`;
      },
      autocompleteApellidoP() {
        return `${this.$options.name}-ApellidoP`;
      },
      autocompleteApellidoM() {
        return `${this.$options.name}-ApellidoM`;
      },
      autocompleteCorreo() {
        return `${this.$options.name}-Correo`;
      },
      autocompleteEmpresa() {
        return `${this.$options.name}-Empresa`;
      },
    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;
      var Persona = Personas.Persona;
      if (Persona != null && Persona.id != "") {
        this.datos = Persona;
      }

      Personas.getCampos(this,  (response) => {
        this.campos = response.data;
      }, (MsgError, Error) => {
         
      }, () => {

      });
    },
    created() {
    },
    methods: {
      OnGuardarFotoID(Archivo, Imagen) {
        console.debug("OnGuardarFoto");
        this.ImgID = Imagen;
        this.ArchivoID = Archivo;
        this.MostrarTomarID = false;
      },
      OnGuardarFoto(Archivo, Imagen) {
        console.debug("OnGuardarFoto");
        this.ImgSelfie = Imagen;
        this.ArchivoSelfie = Archivo;
        this.MostrarTomarSelfie = false;
      },
      Regresar() {
        this.Paso--;
      },
      Siguiente() {
        switch (this.Paso) {
          case 2:
            if (this.datos.id != "")
              Personas.put(this, this.datos.id, this.datos, (response) => {
                Personas.Persona = this.datos;
                this.datos.id = response.data.id;
                this.datos.llave = response.data.llave;

              }, (MsgError, Error) => {
                this.Paso = 2;
              }, () => {

              });
            else
              Personas.post(this, this.datos, (response) => {
                Personas.Persona = this.datos;
                this.datos.id = response.data.id;
                this.datos.llave = response.data.llave;

              }, (MsgError, Error) => {
                //console.debug(MsgError);
                //console.debug(Error);
                if (Error.response.status == 409) {
                  console.debug(Error.response.data);
                  MsgError = `Registro previo (${Error.response.data})`;
                }
                this.Paso = 2;
                return MsgError;
              }, () => {

              });
            break;
          case 3:
            if (this.datos.id == "")
              return;
            Personas.SelfiePatch(this, this.datos.id, 0, this.datos.llave, this.ArchivoSelfie, (response) => {
              //this.datos.id = response.data.id;
              Personas.Persona = this.datos;
            }, (MsgError, Error) => {
              this.Paso = 3;
            }, () => {

            });
            break;
          case 4:
            if (this.datos.id == "")
              return;
            Personas.IdentificacionPatch(this, this.datos.id, 0, this.datos.llave, this.ArchivoID, (response) => {
              //this.datos.id = response.data.id;
              this.Coincide = true;
              Personas.Persona = this.datos;

              Servicio.PostPersona(null, this.value.id, {
                personaID: this.datos.id,
                llave: this.datos.llave,
              }, (response) => { }, (MsgError, Error) => {
                //this.Paso = 3;
              }, () => {

              });

            }, (MsgError, Error) => {
              if (MsgError == "No Coincide") {
                this.Coincide = false;
                return;
              }
              this.Paso = 4;
            }, () => {

            });
            break;
        }
        this.Paso++;
      },
      Cerrar() {
        this.datos = {
          etiqueta: '', descripcion: ''
        };
        this.Modal = false;
      },
      OnFinalizar() {
        this.Cerrar();
      }
    }
  }
</script>

<style lang="scss">
  .cuerpo-wizard-pre-registro {
    min-height: 250px;
  }

  .pasos-wizard-pre-registro {
    min-height: 125px;
  }
</style>
