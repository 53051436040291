<template>
  <card shadow type="secondary">
    <div slot="header" class="border-0">
      <!--  <div class="row align-items-center">
         <div class="col-8">
           <h3 class="mb-0">
             Empresa - {{suscripcionUsuario.suscripcion.etiqueta}}

           </h3>
         </div>
  <div class="col-4 text-right">
       <router-link to="/usuarios/configuracion" class="btn btn-sm btn-primary">
         Configuración
       </router-link>
     </div>

    </div>-->
      <h3 class="mb-0">
        Suscripción - {{suscripcionUsuario.suscripcion.etiqueta}}

      </h3>
    </div>
    <template>
      <form @submit.prevent>
        <h6 class="heading-small text-muted mb-4">Datos Generales</h6>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-2">
              <span>Contrato</span>
              <h4>{{suscripcionUsuario.suscripcion.contrato}}</h4>
            </div>
            <div class="col-lg-3">
              <span>Empresa</span>
              <h4>{{suscripcionUsuario.suscripcion.etiqueta}}</h4>

            </div>
            <div class="col-lg-7">
              <span>Razón Social</span>
              <h4>{{suscripcionUsuario.suscripcion.razonSocial}}</h4>

            </div>
          </div>
          <div class="row">
            <div class="col-lg-8">
              <span>Contacto</span>
              <h4>{{suscripcionUsuario.suscripcion.contacto}}</h4>
            </div>
            <div class="col-lg-4">
              <span>No. Teléfonico</span>
              <h4>{{suscripcionUsuario.suscripcion.telefono}}</h4>

            </div>
          </div>
        </div>
        <hr class="my-4" />
        <!-- Address -->
        <h6 class="heading-small text-muted mb-4">Domicilio de la empresa</h6>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-md-8">
              <span>Calle</span>
              <h4>{{suscripcionUsuario.suscripcion.dDireccion.dirCalle}}</h4>
            </div>
            <div class="col-md-2">
              <span>No.</span>
              <h4>{{suscripcionUsuario.suscripcion.dDireccion.dirCalleNo}}</h4>
            </div>
            <div class="col-md-2">
              <span>Interior</span>
              <h4>{{suscripcionUsuario.suscripcion.dDireccion.dirInterior}}</h4>

            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <span>Colonia</span>
              <h4>{{suscripcionUsuario.suscripcion.dDireccion.colonia}}</h4>

            </div>
            <div class="col-lg-6">
              <span>Municipio</span>
              <h4>{{suscripcionUsuario.suscripcion.dDireccion.municipio}}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <span>Ciudad</span>
              <h4>{{suscripcionUsuario.suscripcion.dDireccion.ciudad}}</h4>

            </div>
            <div class="col-lg-4">
              <span>Pais</span>
              <h4>{{suscripcionUsuario.suscripcion.dDireccion.pais}}</h4>

            </div>
            <div class="col-lg-4">
              <span>Código Postal</span>
              <h4>{{suscripcionUsuario.suscripcion.dDireccion.cP}}</h4>

            </div>
          </div>
        </div>
      </form>
    </template>
  </card>
</template>
<script>
  export default {
    props: {
      suscripcionUsuario: Object
    },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
<style></style>
