<template>
  <div>
    <base-header class="header pb-0 pt-0  d-flex align-items-center"
                 style="min-height: 100vh;  background-image: url(img/home-fondo.jpg); background-size: cover; background-position: center top; ">
      <!--   <span class="mask bg-gradient-success opacity-8"></span> Mask -->


    </base-header>
    <!--
  <InvitacionModal v-if="MostrarInvitacion" :Mostrar.sync="MostrarInvitacion" v-model="datos" :autoClose="false" :ShowPreRegistro.sync="ShowPreRegistro"
                   v-on:guardado="Actualiza"></InvitacionModal>-->

    <InvitacionModal v-if="MostrarInvitacion" :Mostrar.sync="MostrarInvitacion" v-model="datos" :autoClose="false"
                     v-on:guardado="Actualiza"></InvitacionModal>

    <AutoRegistroModal v-if="ShowPreRegistro" :Mostrar.sync="ShowPreRegistro" v-model="datos" :autoClose="false"
                     v-on:guardado="Actualiza"></AutoRegistroModal>
  </div>
</template>
<script>
  import BaseHeader from 'src/components/BaseHeader';
  //   import VueQriously from 'vue-qriously'
  import Servicio from '@/api-services/reuniones.service';
  import Dispositivos from '@/api-services/dispositivos.service';
  import InvitacionModal from './Invitacion.modal.vue';
  import AutoRegistroModal from './AutoRegistro.modal.vue';
  /*  import vue2Img from 'vue-2-img'
    import 'vue-2-img/dist/vue-2-img.css'

    Vue.use(VueQriously)*/

  export default {
    name: 'Reuniones-Invitacion',
    components: {
      BaseHeader,
      InvitacionModal,
      AutoRegistroModal
    },
    data() {
      return {
        datos: {

        },
        MostrarInvitacion: false,
        cargando: false,
        ShowPreRegistro: false,
        id: '',
      }
    },
    methods: {
      Actualiza() {

      },
      Abrir: function (Salida) {
        if (this.selected.length <= 0)
          return;


        var AEnviar = {
          InvitadoNombre: JSON.stringify(this.selected), ReunionID: this.info.reunionID,
          DispositivoID: Salida ? this.info.ubicacion.dispositivoSalidaID : this.info.ubicacion.dispositivoEntradaID
          //, suscripcionID: 1
        };
        console.log(AEnviar);
        this.Abriendo = true;
        if (Salida)
          Dispositivos.Salida(AEnviar.DispositivoID, AEnviar).then((response) => {
            console.log(response.data);
            this.ModalAcceso = false;
            this.Abriendo = false;
            // this.$router.push('/reuniones');
          }).catch((error) => {

            console.error(error.response);
            this.errorAbrir = true;
            this.Abriendo = false;
            //alert(error.response.data.error.mensaje);
          });
        else
          Dispositivos.Entrada(AEnviar.DispositivoID, AEnviar).then((response) => {
            console.log(response.data);
            this.ModalAcceso = false;
            this.Abriendo = false;
            // this.$router.push('/reuniones');
          }).catch((error) => {

            console.error(error.response);
            this.errorAbrir = true;
            this.Abriendo = false;
            //alert(error.response.data.error.mensaje);
          });
      }
    },
    mounted() {
      this.id = this.$route.params.id;

      if (this.id == "AutoRegistro") {
        this.datos = {};
        this.ShowPreRegistro = true;
        this.MostrarInvitacion = false;
        return;
      }

      var LinkID = this.$route.query.LinkID
      console.log(this.id);
      //this.url = window.location.href.replace("&p=true", "");
      //this.editar = `/reuniones/edicion?i=${this.$route.query.i}`;
      Servicio.getConsulta(this, this.id, LinkID, (response) => {
        this.datos = response.data;
        this.MostrarInvitacion = true;
      }, () => { }, () => { });
      /*

        this.$route.query.i).then((response) => {
        console.log(response.data);
        this.info = response.data;
        this.loading = false;
        this.invitadoX = this.info.reunionesUsuarios[0].usuario.nombre;
        this.invitadoXTelefono = this.info.reunionesUsuarios[0].usuario.telefono;
        this.direccion = Ubicaciones.linea1(this.info.ubicacion.dDireccion);
        this.hRefDireccion = this.info.ubicacion.urlMaps;
        this.ubicacion = this.info.ubicacion.nombre;
        this.rango = Base.periodo(this.info.comienzo, this.info.finaliza);
        this.LInvitados = JSON.parse(this.info.invitados);
        for (var i = 0; i < this.LInvitados.length; i++) {
          this.LInvitadosOB.push({ text: this.LInvitados[i], name: `Obt_${i}` });
        }
        var comienzo = Base.fecha(this.info.comienzo);
        console.log(comienzo);
        var finaliza = Base.fecha(this.info.finaliza);
        console.log(finaliza);
        var today = new Date();
        console.log(today);
        this.Expirado = comienzo > today || finaliza < today;
        console.log(this.LInvitadosOB);
        for (const t of SuscripcionesUsuarios.Suscripciones) {
          ///Si es administrador, usuario avanzado o vigilante
          if (t.perfilID == 1 || t.perfilID == 2 || t.perfilID == 4) {
            this.Accesos = !this.Expirado;
          }
        }

        if (this.$route.query.p == "true") {
          console.log('p es: ' + this.$route.query.p);
          var v = this;

          Vue.nextTick(function () {
            v.Imagen();
          })

        }
        // this.$router.push('/reuniones');
      }).catch((error) => {
        if (error.response !== undefined)
          console.error(error.response);
        else
          console.error(error);
        this.errored = true;
        //alert(error.response.data.error.mensaje);
      });*/
    },
  };
</script>
<style></style>
