<template>
  <div>

    <base-header class="header py-5 d-flex align-items-center mb-3"
                 style="height: 40vh;  background-image: url(img/home-fondo.jpg); background-size: cover; background-position: center top; ">
      <!-- Mask -->
      <!--  <div class="mask bg-gradient-gray-dark opacity-4" />
    <!-- <div class="mask bg-gradient-success opacity-4"></div>
    -->
      <!-- Header container -->
      <div class=" align-items-center ">
        <div class="row">
          <div class="col-lg-7 col-md-10 mt-5">

            <!--     <h3 class="display-3 text-white mt-5 m-0">Listado de Usuarios </h3>-->
            <p class="text-white mt-5 ">Contiene la lista de las personas que podrán crear reuniones, invitar a otros o validar su ingreso</p>

            <!-- <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
        <b-row align-h="end" class="m-2">
          <base-button icon
                       round :loading="cargando" @click="Actualiza">
            <i class="fas fa-sync"></i>
          </base-button>

          <base-button icon @click="MostrarFiltrar=true"
                       round :loading="cargando || MsgError.length>0">
            <i class="fas fa-filter"></i>
          </base-button>



        </b-row>
      </div>

    </base-header>

    <Main-Cargando :cargando="cargando" :MsgError="MsgError" class="mx-2">
      <div v-if=" info " class="card-columns-listado ">
        <UsuariaList v-for="(registro, pos) in info.listado" v-bind:key="registro.id"
                     v-model="info.listado[pos]"
                     @borrar="Borrar(registro)" @activar="Activar(registro)" @abrir="Abrir(registro)"
                     class="card  " />
        <!--
        <div v-for="registro in info.listado" v-bind:key="registro.usuarioID" class="card   mb-3">

          <router-link :to="{}" class="pull-right p-3" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fa fa-times"></i></router-link>
          <router-link :to="{}" @click.native="Editar(registro)">
            <div class="card-header px-3 pt-3">
              <img :src="registro | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
              <div class="">
                <h3 class="mb-0 pb-0">{{registro.nombre}}</h3>
                <h5 class="mb-0 pb-0">{{registro.suscripcionesUsuario | FormatoTipoUsuaria}}</h5>
              </div>
            </div>

            <hr class="mt-2" />
            <div class="text-center ">
            </div>
            <hr class="mt-3 mb-1" />
            <div class="px-3 pb-3">
              <hr class="my-0" width="0px" />

              <router-link :to="{}" class="float-right px-0  align-bottom" data-effect="fadeOut" @click.native.stop="Compartir(registro)"><i class="fas fa-share fa-2x"></i></router-link>

              <div class="text-muted mt-3">Invitados: </div>
              <badge v-for="invitados in registro.invitados" type="info" class="mr-2">{{invitados}}</badge>
            </div>
          </router-link>
        </div>
      -->
      </div>
      <base-pagination v-if="!cargando && info && info.totalPaginas>1" :pageCount="info.totalPaginas" v-model="Pagina"></base-pagination>
    </Main-Cargando>


    <div class="fixed-bottom text-center  mb-2" style="width:100%">
      <base-button size="lg" icon type="primary" @click="MostrarNuevo=true"
                   round>
        <i class="fas fa-plus fa-3x"></i>
      </base-button>
    </div>

    <NuevoModal v-if="MostrarNuevo" :Mostrar.sync="MostrarNuevo" :Ayuda="Ayuda"
                v-on:guardado="Actualiza"></NuevoModal>

    <EdicionModal v-if="MostrarEdicion" :Mostrar.sync="MostrarEdicion" :Ayuda="Ayuda" v-model="seleccionado"
                v-on:guardado="Actualiza"></EdicionModal>

    <FiltrarModal v-if="MostrarFiltrar" :Mostrar.sync="MostrarFiltrar" Categoria="Usuarios" v-model="BaseGetsListado.filtro"
                  :SoloBorrados.sync="BaseGetsListado.Borrados"
                  v-on:filtrar="OnFiltrar"></FiltrarModal>

  </div>
</template>
<script>
  import swal from 'sweetalert2';
  //const UsuariaList = () => import( /* webpackChunkName: "usuarios" */ './List.comp.vue');
  import UsuariaList from './List.comp.vue';
  import Servicio from '@/api-services/usuarias.service';

  import FiltrarModal from '@/Vistas-base/Main/Filtrar.modal.vue';
  import BaseHeader from 'src/components/BaseHeader';
  import EdicionModal from './Edicion.modal.vue';
  import NuevoModal from './Nuevo.modal.vue';
  export default {


    name: 'UsuariosListado',
    data() {
      return {

        MostrarFiltrar: false,
        MostrarNuevo: false,
        MostrarEdicion: false,

        BaseGetsListado: {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 50,
          filtro: '',
        },

        MsgError: '',
        Ayuda: '',

        info: null,
        loading: true,
        cargando: false,
        errored: false,

        seleccionado:null,
 
      }
    },
    components: {
      NuevoModal,
      BaseHeader,
      EdicionModal,
      UsuariaList,
      FiltrarModal
    },
    filters: {
      Foto(Usuario) {
        return Servicio.FotoURL(Usuario);
      },

      FormatoTipoUsuaria(TipoUsuaria) {
        return Servicio.sTipoUsuaria2Etiqueta(TipoUsuaria);
       
      },
    },
    mounted() {
      this.Actualiza();
    },
    methods: {
      Actualiza() {

        Servicio.gets(this, this.BaseGetsListado, (response) => {
          this.info = response.data;

        }, (MsgError, error) => {

        }, () => {
          this.cargando = false;

        });

      },
      Activar(seleccionado) {
        Servicio.patch(this, seleccionado.id, {}, (response) => {
          console.debug(response.data);
          swal.fire({
            title: 'Reactivado',
            text: 'El registro se ha reactivado satisfactoriamente.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-fill'
            },
            buttonsStyling: false
          });
          this.Actualiza();
        });
      },
      Borrar(seleccionado) {

        swal.fire({
          title: '¿Estas seguro?',
          text: `Estas por dar de baja un usuario (${seleccionado.etiqueta}), ingresar salvo que sea reactivado.`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn  btn-danger ',
            cancelButton: 'btn '
          },
          confirmButtonText: 'Si, Borrarlo',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then((resultado) => {
          console.debug(resultado);
          if (resultado.isConfirmed)

            Servicio.delete(this, seleccionado.id, (response) => {
              //console.debug(response.data);
              swal.fire({
                title: 'Borrado',
                text: 'El registro se ha borrado satisfactoriamente.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-fill'
                },
                buttonsStyling: false
              });
              this.Actualiza();
            });

        });
      },
      Abrir(registro) {
        console.debug(registro);
        this.seleccionado = registro;
        this.MostrarEdicion = true;
        //this.$router.push(`/usuarias/${registro.id}`);
      },
      OnBorrado() {
        this.Actualiza();
      },
      OnError() {

      },
      OnFiltrar(filtro) {
        //     this.BaseGetsListado.Filtro = filtro;
        this.Actualiza();
      },
    }
  }</script>
<style></style>


<style lang="scss" scoped>



  @media (min-width: 35em) {
    .card-columns-listado {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 55em) {
    .card-columns-listado {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 80em) {
    .card-columns-listado {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 97em) {
    .card-columns-listado {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 114em) {
    .card-columns-listado {
      -webkit-column-count: 6;
      -moz-column-count: 6;
      column-count: 6;
      orphans: 1;
      widows: 1;
    }
  }

  .card {
    display: inline-block; /*    // Don't let them vertically span multiple columns */
    width: 100%; /*    // Don't let their width change*/
    min-height: 4.6em
  }
</style>

