import Axios from 'axios';
import LiAuth from '@/api-base/liauth.service'
import Base from '@/api-base/base'
const USUARIOSINVITACIONES = `${Base.URL}/usuariosinvitaciones`;

export default {

    ///Obtiene el usuario invitación por AlternoID
    getAlternoID(AlternoID) {
        return Axios.get(USUARIOSINVITACIONES + `/AlternoID/${AlternoID}`, LiAuth.header());
    },
    Aceptar(UsuarioInvitacionID) {
        return Axios.post(USUARIOSINVITACIONES + `/${UsuarioInvitacionID}/Aceptar`, {}, LiAuth.header());
    },
    AceptarAlternoID(AlternoID) {
        return Axios.post(USUARIOSINVITACIONES + `/AlternoID/${AlternoID}/Aceptar`, {}, LiAuth.header());
    },
    PostUsuarioInvitacion(data) {
        return Axios.post(USUARIOSINVITACIONES, data, LiAuth.header());
    },
    putUsuario(reunionID, data) {
        return Axios.put(`${USUARIOSINVITACIONES}/${reunionID}`, data, LiAuth.header());
    },
};