<template>
  <div class="mx-3">

    <h3 class="mb-1">Gracias a ti, seremos los mejores.</h3>
    <p class="text-muted">Es por ello que tus comentarios son muy importantes para nosotros.</p>
    <hr class="my-md-4" />
    <p class="text-muted">¿Sobre que es el comentario?</p>

    <div class="mx-5 mb-3 ">


      <base-radio name="App" v-model="datos.destinoComentario">App</base-radio>
      <base-radio name="Empresa" v-model="datos.destinoComentario">Empresa</base-radio>
      <base-radio name="Otro" v-model="datos.destinoComentario">Otro</base-radio>
    </div>
    <MazInput v-model="datos.comentario"
              placeholder="Tus comentarios"
              autocomplete="new-comment" required
                :loading="cargando"
              textarea>
      <template v-slot:icon-left>
        <i class="far fa-comment"></i>
      </template>

    </MazInput>



    <div class="text-muted text-center mt-3">
      <div v-if="cargando" class="text-center mt-7">
        <b-spinner type="grow" label="cargando..."></b-spinner>
        Cargando...
      </div>

      <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
        {{ Erroneo }}
      </p>
      <base-button v-if="!cargando" type="primary" @click="OnEnviar">
        Enviar comentario
      </base-button>
    </div>
  </div>
</template>

<script>
  import swal from 'sweetalert2';
  import Servicio from '@/api-base/suscripciones.service';
  import { BaseRadio } from 'src/components/index';
  export default {
    name: 'Main-Comentarios',
    components: {
      BaseRadio
    },
    data() {
      return {
        datos: {
          destinoComentario: 'App',
          comentario: '',

        },

        Modal: false,
        cargando: false,

        Erroneo: ''
      }
    },
    mounted() {


    },
    methods: {
      OnEnviar() {
        this.$appInsights.trackEvent({ name: this.$options.name }, { datos: this.datos });

        this.Erroneo = ''
        if (this.datos.comentario=='') {
          this.Erroneo = 'Requiere el comentario';
          return;
        }
        Servicio.postComentarios(this, this.datos, () => {
          swal.fire({
            title: 'Gracias',
            text: 'Listo, enviamos tus comentarios.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-fill'
            },
            buttonsStyling: false
          }).then(() => {
            this.$router.push("/");
          });
        }, (ErrorMsg) => {
            this.Erroneo = ErrorMsg;
        });
      }
    }
  };
</script>

<style scoped>
</style>
