<template>
    <div class="col-md-4">

        <modal :show.sync="Modal"
               gradient="danger"
               modal-classes="modal-danger modal-dialog-centered">
            <h6 slot="header" class="modal-title" id="modal-title-notification">Mucha Atención</h6>

            <div class="py-3 text-center">
                <i class="ni ni-bell-55 ni-3x"></i>
                <h4 class="heading mt-4">¿Esta seguro?</h4>
                <p v-if="UsuarioID==-1">
                    ¿Desea eliminar la cuenta?, no tendrá acceso nuevamente
                </p>
                <p v-else>
                    ¿Desea eliminar el usuario de la suscripción ({{SuscripcionEtiqueta}})?
                </p>
            </div>

            <template slot="footer" class="p-2">
                <base-button  
                             v-on:click="Guardar" :disabled="Guardando">Si, borrar</base-button>
                <base-button type="link"
                             text-color="white"
                             class="ml-auto"
                             @click="Modal = false">
                  Cancelar
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    //import LiAuth from '@/api-base/liauth.service';
    import Usuarios from '@/api-base/usuarios.service';
    export default {
        name: 'UsuariosBorrar',
        props: {
            Mostrar: {
                type: Boolean,
                default: false,
                description: "Indica si se mostrará"
            },
            UsuarioID: {
                type: Number,
                default: -1,
                description: "Identificador del usuario a borrar"
            },
            SuscripcionID: {
                type: Number,
                default: -1,
                description: "Identificador de la suscripcion a borrar"
            },
            SuscripcionEtiqueta: {
                type: String,
                default: "",
                description: "Etiqueta de la suscripción"
            },
        },
        validations: {

        },
        data() {
            return {
                Modal: false,

                Guardando: false,
            }
        },
        watch: {
            Mostrar(Mostrar) {
                console.debug(Mostrar);
                if (Mostrar)
                    this.Modal = true;
            },
            Modal(Modal) {
                if (!Modal)
                    this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
            }
        },
        computed: {


        },
        filters: {


        },
        mounted() {

        },

        methods: {
            Guardar() {

                /*    this.$emit('guardado', {});
                },
                GuardarViejo() {
                */
                var Metodo = this.UsuarioID < 0 ? Usuarios.delete() : Usuarios.deleteSuscripcion(this.UsuarioID, this.SuscripcionID);
                Metodo.then((response) => {

                    this.Modal = false;
                    console.debug(response);
                    this.$notify({ type: 'success', title: 'Guardado' });
                    this.$emit('guardado', response.data);


                }).catch((error) => {
                    console.error(error.response);
                    this.errored = true;
                    if (error.response != undefined && error.response.data != undefined && error.response.data.error != undefined && error.response.data.error.mensaje != undefined)
                        this.$notify({ type: 'danger', title: error.response.data.error.mensaje });
                    else
                        this.$notify({ type: 'danger', title: 'no se pudo guardar la información, intente nuevamente' });
                    this.$emit('error', error);

                    //alert(error.response.data.error.mensaje);
                });
            },
        }
    }
</script>
<style>
</style>
