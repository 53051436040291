<template>
  <div class="card ">
    
    <div class="card-header px-3 pt-3">
      <h6>Nueva invitación por: {{Anfitrion}}</h6>
      <img :src="registro.usuaria | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
      <h3 class="mb-0 pb-0 text-break ">{{registro.etiqueta}}</h3>
      <h4 class="mb-0 pb-0 text-break ">{{comienzo(registro)}}</h4>
      <p class="mb-0 pb-0">Nota: {{registro.descripcion}}</p>
      <p v-if="registro.ubicacion" class="mb-0 pb-0">Ubicación: {{registro.ubicacion.etiqueta}}</p>
    </div>
    <!--      <div class="px-3 pb-3">
    <hr class="my-2" />
    <router-link :to="{}" class="float-right px-2" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fas fa-external-link-alt fa-2x"></i></router-link>
    <router-link :to="{}" class="float-right px-2" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fas fa-external-link-alt fa-2x"></i></router-link>
    <div class="text-muted pt-2">Invitados: </div><i class="fas fa-share"></i>
    <badge v-for="invitados in registro.invitados" type="info" class="mr-2">{{invitados}}</badge>



  </div>-->
    <hr class="mt-2" />
    <div class="text-center ">
      <vue-qr :text="furlInvitacion(registro)" width="70%" logoSrc="img/e512transparente.png" qid="testid" :correctLevel="3" :size="2600"></vue-qr>
    </div>
    <hr class="mt-3 mb-1" />

  </div>
</template>

<script>
  import Servicio from '@/api-services/reuniones.service';
  import Base from '@/api-base/base';

  import Usuarias from '@/api-services/usuarias.service';
  import VueQr from 'vue-qr'

  import Referencias from '@/api-base/referencias.service';
  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');

  export default {
    components: {
      VueQr
    },
    name: 'Reuniones-Invitacion-comp',
    props: {


      value: { type: Object, default: false },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
      Cancelar: { type: Boolean, default: false },
    },
    data() {
      return {




        cargando: false,
        Formato: 'YYYY-MM-DD hh:mm a',
        Erroneo: '',
        MsgError: ''
      }
    },
    computed: {

      registro() {
        return this.value;
      },

      Anfitrion() {
        if (this.registro.usuaria == null)
          return "-";
        return this.registro.usuaria.etiqueta;
      }

    },

    watch: {

    },
    mounted() {


    },
    filters: {
      Foto(Usuario) {
        return Usuarias.FotoURL(Usuario);
      },
    },
    created() {
    },
    methods: {
      comienzo(seleccionado) {
        return Servicio.FFechaHora(seleccionado.comienzo);
      },
      furlInvitacion(value) {

        return Referencias.ObtenUrlLinkID(value.linkID);
      },
    }
  }
</script>

<style scoped>
</style>
