<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-2 pt-md-8">
            <div class="container-fluid p-3">
                <div class="row align-items-center">
                    <div class="col">
                        <h1 class="mb-0" v-if="nuevo">
                            Nueva reunión
                        </h1>
                        <h1 class="mb-0" v-else>
                            Edición de reunión
                        </h1>
                    </div>

                </div>
            </div>
            <section v-if="errored">
                <p>Lo sentimos, no es posible obtener la información en este momento, por favor intente nuevamente mas tarde</p>
            </section>
            <section v-else>
                <div v-if="loading">Cargando...</div>
                <div v-else>
                    <form role="form">
                        <div class="container p-3">
                            <div class="row align-items-center">
                                <div class="col-4 text-right">
                                    <h3>Titulo</h3>
                                </div>
                                <div class="col-8">
                                    <base-input class="input-group-alternative mb-3" addon-left-icon="ni ni-tag"
                                                placeholder="Titulo de la reunion"
                                                v-model.trim="titulo" :valid="$v.titulo.required && $v.titulo.minLength">
                                    </base-input>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-4 text-right">
                                    <h3>Descripcion</h3>
                                </div>
                                <div class="col-8">
                                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                                    <MazPicker v-model="comienza" :loading="cargando"
                                               nowTranslation="Hoy"
                                               autoClose
                                               :required="true"
                                               placeholder="Fecha y hora" :disabled="false"
                                               :formatted="formatted" />



                                  </base-input>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-4 text-right">
                                    <h3>Comienza</h3>
                                </div>
                                <div class="col-8">
 
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-4 text-right">
                                    <h3>Finaliza</h3>
                                </div>
                                <div class="col-8">
                                    <base-input addon-left-icon="ni ni-calendar-grid-58">
                                        <flat-pickr v-model="finaliza" class="form-control datepicker" slot-scope="{focus, blur}"
                                                    @on-open="focus" @on-close="blur"
                                                    :config="{ enableTime: true,minDate:comienza}"></flat-pickr>

                                    </base-input>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-4 text-right">
                                    <h3>Invitados</h3>
                                </div>
                                <div class="col-8">

                                  <MazInputTags v-model="tag"
                                                placeholder="Nombre de cada invitado (enter p/confirmar)" size="sm"
                                                :loading="cargando||loading" class="mb-3"
                                                clearable />



                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-4 text-right">

                                </div>
                                <div class="col-8">

                                    <h6>Escriba el nombre de cada invitado, seguido de confirmación</h6>


                                </div>
                            </div>
                            <!--
                            <div class="row align-items-center">
                                <div class="col-4 text-right">
                                    <h3>Ubicación</h3>
                                </div>
                                <div class="col-8">
                                    <base-input class="input-group-alternative mb-3" addon-left-icon="fa fa-map-marker"
                                                placeholder="Ubicación"
                                                v-model.trim="ubicacion" :valid="$v.ubicacion.required && $v.ubicacion.minLength">
                                    </base-input>
                                </div>
                            </div>-->

                        </div>

                        <div class="container-fluid p-3">
                            <div class="row align-items-center">
                                <div class="col">
                                    <a href="javascript:history.go(-1)">
                                        <!--  <router-link :to="-1">
                                            <base-button type="alternative" class="my-4">Regresar</base-button>
                                        </router-link>
                                        -->
                                        <base-button type="alternative" class="my-4">Regresar</base-button>
                                    </a>
                                </div>
                                <div class="col text-right">
                                    <base-button type="primary" v-on:click="crear" class="my-4" :disabled="!$v.titulo.required || !$v.comienza.required || !$v.finaliza.required">Guardar reunion</base-button>
                                </div>
                            </div>
                        </div>



                    </form>
                </div>
            </section>

        </base-header>

    </div>
</template>
<script>
  import Base from '@/api-base/base';
    import Reuniones from '@/api-services/reuniones.service';
    import { required, minLength } from 'vuelidate/lib/validators';
  import { MazInputTags, MazPicker } from 'maz-ui'

    export default {
        name: 'NuevaReunion',
        data() {
            return {
                tag: '',
                tags: [],
                separadores: [13, ',', ';', ':'],
                titulo: '',
                descripcion: '',
                comienza: new Date(),
                finaliza: new Date(),
                ubicacion: '',
                reunionID: -1,
                errored: false,
                loading: true,
                nuevo : true,
            }
        },
        components: {
          MazInputTags,
          MazPicker
        },
        validations: {
            titulo: {
                required,
                minLength: minLength(5)
            },
            comienza: {
                required,
            },
            finaliza: {
                required,
            },
        },
        methods: {
            crear: function () {
                var Elementos = [];
                for (const t of this.tags) {
                    Elementos.push(t.text);
                }
                if (this.tag !== undefined && this.tag !== '')
                    Elementos.push(this.tag);
                var Invitados = JSON.stringify(Elementos);
                //console.log(Invitados);
                var N = 2;
                if (N == 1)
                    return;
                if (this.$v.$invalid) {
                    // this.modals.modal2 = true
                    return;
                }
                var AEnviar = {
                    ubicacionID: 2, titulo: this.titulo, descripcion: this.descripcion,
                    comienzo: new Date(this.comienza).toISOString(), finaliza: new Date(this.finaliza).toISOString(),
                    invitados: Invitados
                    //, suscripcionID: 1
                };
                console.log(AEnviar);
                try {
                    if (this.reunionID <= 0)
                        Reuniones.postReunion(AEnviar).then((response) => {
                            console.log(response.data);
                            this.$router.push('/reuniones');
                        }).catch((error) => {
                            if (error.response.data == undefined) {
                                console.error(error.response);
                                alert("Error desconocido");
                            }
                            else {
                                console.error(error.response.data);
                                alert(error.response.data.error.mensaje);
                            }
                        });
                    else {
                        Reuniones.putReunion(this.reunionID, AEnviar).then((response) => {
                            console.log(response.data);
                            this.$router.push('/reuniones');
                        }).catch((error) => {
                            if (error.response.data == undefined) {
                                console.error(error.response);
                                alert("Error desconocido");
                            }
                            else {
                                console.error(error.response.data);
                                alert(error.response.data.error.mensaje);
                            }
                        });
                    }
                }
                catch (error) {
                    alert('Error desconocido, intente nuevamente');
                    console.error(error);
                    // expected output: ReferenceError: nonExistentFunction is not defined
                    // Note - error messages will vary depending on browser
                }
            }
        },
        mounted() {
            //  console.log('i es: ' + this.$route.query.i);
            if (this.$route.query.i !== undefined) {
                this.nuevo = false;
                Reuniones.getAlternoID(this.$route.query.i).then((response) => {
                    console.log(response.data);
                    this.info = response.data;
                    this.reunionID = this.info.reunionID;
                    this.titulo = this.info.titulo;
                    this.descripcion = this.info.descripcion;
                    this.comienza = Base.fecha(this.info.comienzo);
                    //  console.log(this.comienza);
                    this.finaliza = Base.fecha(this.info.finaliza);
                    //this.Invitados = [];
                    if (this.info.invitados !== undefined && this.info.invitados !== null && this.info.invitados !== "") {
                        var LInvitados = JSON.parse(this.info.invitados);
                        for (const t of LInvitados) {
                            this.tags.push({ text: t });
                        }
                    }
                    this.loading = false;

                    // this.$router.push('/reuniones');
                }).catch((error) => {
                    if (error.response === undefined)
                        console.error(error);
                    else
                        console.error(error.response);
                    this.errored = true;
                    //alert(error.response.data.error.mensaje);
                });
            }
            else
                this.loading = false;
        },
    };</script>
<style></style>
