var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"modal-black",attrs:{"show":_vm.Modal,"centered":"","appendToBody":false,"modalContentClasses":"card","headerClasses":"card-header"},on:{"update:show":function($event){_vm.Modal=$event}}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h5',{staticClass:"card-category"},[_vm._v(_vm._s(_vm.Categoria))]),_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.Titulo))]),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.Ayuda))])]),[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.guardar)}}},[_c('MazInput',{staticClass:"maz-mb-2",attrs:{"error":_vm.datos.etiqueta=='',"placeholder":"Ej. Cumple de Andy","autocomplete":_vm.autocompleteEtiqueta,"disabled":_vm.loading || _vm.cargando,"required":"","loading":_vm.loading || _vm.cargando,"clearable":""},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c('i',{staticClass:"fas fa-tag"})]},proxy:true}],null,true),model:{value:(_vm.datos.etiqueta),callback:function ($$v) {_vm.$set(_vm.datos, "etiqueta", $$v)},expression:"datos.etiqueta"}}),_c('MazInput',{staticClass:"maz-mb-2",attrs:{"placeholder":"Ej. Traer regalo","loading":_vm.loading || _vm.cargando,"autocomplete":_vm.autocompleteComentario,"disabled":_vm.loading || _vm.cargando,"left-icon-name":"comment","textarea":""},model:{value:(_vm.datos.descripcion),callback:function ($$v) {_vm.$set(_vm.datos, "descripcion", $$v)},expression:"datos.descripcion"}}),_c('MazPicker',{ref:"comienzo",staticClass:"maz-mb-2",attrs:{"loading":_vm.loading || _vm.cargando,"nowTranslation":"Hoy","minuteInterval":15,"format":_vm.Formato,"formatted":"ddd, DD/MMM/YY, h:mm a","required":"","error":!_vm.ComienzoValido,"placeholder":"Fecha y hora del evento"},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c('i',{staticClass:"fas fa-calendar"})]},proxy:true}],null,true),model:{value:(_vm.FHComienzo),callback:function ($$v) {_vm.FHComienzo=$$v},expression:"FHComienzo"}}),_c('MazInputTags',{ref:"invitados",staticClass:"mb-2",attrs:{"error":_vm.InvitadosInValido,"required":"","placeholder":"Nombre de cada invitado (enter p/confirmar)","size":"sm","loading":_vm.cargando||_vm.loading,"clearable":""},model:{value:(_vm.datos.invitados),callback:function ($$v) {_vm.$set(_vm.datos, "invitados", $$v)},expression:"datos.invitados"}}),_c('ValidationProvider',{attrs:{"name":"TipoVisitaID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('TipoVisitaSelect',{staticClass:"maz-mb-2",attrs:{"placeholder":"Tipo de visita","loading":_vm.cargando,"todas":true,"position":"top","seleccionarUno":"","required":"","clearable":false,"search":false},model:{value:(_vm.datos.tipoVisitaID),callback:function ($$v) {_vm.$set(_vm.datos, "tipoVisitaID", $$v)},expression:"datos.tipoVisitaID"}})]}}],null,true)}),(_vm.MostrarUbicaciones)?_c('ValidationProvider',{attrs:{"name":"UbicacionID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('UbicacionesSelect',{staticClass:"maz-mb-2",attrs:{"placeholder":"Sucursal / Ubicación","loading":_vm.cargando,"todas":true,"position":"top","seleccionarUno":"","required":"","clearable":false,"search":false},model:{value:(_vm.datos.ubicacionID),callback:function ($$v) {_vm.$set(_vm.datos, "ubicacionID", $$v)},expression:"datos.ubicacionID"}})]}}],null,true)}):_vm._e(),_c('hr',{staticClass:"my-md-4"}),_c('Main-Cargando',{attrs:{"cargando":_vm.cargando,"MsgError":_vm.MsgError}},[_c('base-button',{attrs:{"native-type":"submit","type":"primary","block":""}},[_vm._v(" Guardar ")])],1),(_vm.Cancelar)?_c('div',{staticClass:"text-muted text-center"},[_c('router-link',{staticClass:"d-block mt-2",attrs:{"to":{}},nativeOn:{"click":function($event){return _vm.Cerrar()}}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" Cancelar ")])],1):_vm._e()],1)]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }