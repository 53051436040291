<template>
  <div>
    <div class="pt-2 pt-md-8">

      <div class="row">
        <div class="col-lg-7 col-md-9 d-inline-flex ">
          <i class="fas fa-building fa-3x mr-2"></i>
          <p>{{Ayuda}}</p>

        </div>
        <div class="col-lg-5 col-md-3 ">
          <b-row align-h="end" class="m-2">
            <base-button icon
                         round :loading="cargando" @click="Actualiza">
              <i class="fas fa-sync"></i>
            </base-button>

            <base-button icon @click="MostrarFiltrar=true"
                         round :loading="cargando || MsgError.length>0">
              <i class="fas fa-filter"></i>
            </base-button>


            <base-button icon type="primary" @click="MostrarNuevo=true"
                         round>
              <i class="fas fa-plus"></i>

            </base-button>

          </b-row>
        </div>
      </div>



    </div>

    <div class="mt-2">
      <Main-Cargando :cargando="cargando" :MsgError="MsgError" />
      <div v-if="!MsgError && !cargando && info " class="card-columns-listado ">
        <div v-for="registro in info.listado" v-bind:key="registro.id" class="card   mb-3" :style="`border-left: 10px solid ${registro.color};`">
          <router-link :to="{}" class="pull-right p-2" data-effect="fadeOut" @click.native="Borrar(registro)"><i class="fa fa-times"></i></router-link>
          <router-link :to="{}"  @click.native="Editar(registro)">
            <div class="card-header p-2">
              <h4 class="mb-0 pb-0 text-break ">{{registro.etiqueta}}</h4>

              <h5 class="mb-0 pb-0">{{registro.descripcion}}</h5>
            </div>
          </router-link>
        </div>
      </div>
      <base-pagination v-if="!cargando && info.totalPaginas>1" :pageCount="info.totalPaginas" v-model="Pagina"></base-pagination>
    </div>

    <EdicionModal v-if="MostrarEdicion" :Mostrar.sync="MostrarEdicion" :Ayuda="Ayuda" v-model="Seleccionado"
                  v-on:guardado="Actualiza"></EdicionModal>

    <FiltrarModal v-if="MostrarFiltrar" :Mostrar.sync="MostrarFiltrar" Categoria="Tipos de Visitas" v-model="BaseGetsListado.filtro"
                  v-on:filtrar="OnFiltrar"></FiltrarModal>

    <NuevoModal v-if="MostrarNuevo" :Mostrar.sync="MostrarNuevo" :Ayuda="Ayuda"
                v-on:guardado="Actualiza"></NuevoModal>

  </div>
</template>
<script>
  import Servicio from '@/api-services/tiposVisitas.service';
  import NuevoModal from './Nuevo.modal.vue';
  import EdicionModal from './Edicion.modal.vue';
  import FiltrarModal from '@/Vistas-base/Main/Filtrar.modal.vue';
  import swal from 'sweetalert2';

  export default {
    name: 'Tipos-Visitas-listado',
    data() {
      return {
        MostrarFiltrar: false,
        MostrarNuevo: false,
        MostrarEdicion: false,

        BaseGetsListado: {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 50,
          filtro: '',
        },

        Seleccionado: null,

        Ayuda: 'Los tipos de visitas son categorías o etiquetas que se le darán a los visitantes del complejo, utilies para mandar un mensaje.',
        info: null,
        cargando: true,
        MsgError: ''
      }
    },
    components: {
      FiltrarModal,
      EdicionModal,
      NuevoModal,
    },
    filters: {
      Foto(Registro) {
        return Servicio.FotoURL(Registro);
      },

    },
    mounted() {
      this.Actualiza();
    },
    computed: {
      Pagina: {
        get: function () {

          return this.BaseGetsListado.PaginaNo + 1;
        },
        set: function (valor) {
          this.BaseGetsListado.PaginaNo = valor - 1;
          this.Actualiza();
        }
      },
    },
    methods: {
      Borrar(seleccionado) {
        //console.debug(id);
        swal.fire({
          title: '¿Estas seguro?',
          text: `Estas por borrar una ubicación (${seleccionado.etiqueta}).`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn  btn-danger ',
            cancelButton: 'btn '
          },
          confirmButtonText: 'Si, Borrarlo',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then((resultado) => {
          console.debug(resultado);
          if (resultado.isConfirmed)

            Servicio.delete(this, seleccionado.id, (response) => {
              //console.debug(response.data);
              swal.fire({
                title: 'Borrado',
                text: 'El registro se ha borrado satisfactoriamente.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-fill'
                },
                buttonsStyling: false
              });
              this.Actualiza();
            });

        });
      },
      Editar(seleccionado) {
        this.MostrarEdicion = false;
        this.Seleccionado = seleccionado;

        this.MostrarEdicion = true;
      },
      OnFiltrar(filtro, borrados) {
        //     this.BaseGetsListado.Filtro = filtro;
        this.BaseGetsListado.Borrados = borrados;
        this.Actualiza();
      },
      Actualiza() {

        Servicio.gets(this, this.BaseGetsListado, (response) => {
          this.info = response.data;

        }, (MsgError, error) => {

        }, () => {
          this.cargando = false;

        });

      },

    }
  }</script>



<style lang="scss" scoped>



  @media (min-width: 35em) {
    .card-columns-listado {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 55em) {
    .card-columns-listado {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 80em) {
    .card-columns-listado {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 97em) {
    .card-columns-listado {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 114em) {
    .card-columns-listado {
      -webkit-column-count: 6;
      -moz-column-count: 6;
      column-count: 6;
      orphans: 1;
      widows: 1;
    }
  }

  .card {
    display: inline-block; /*    // Don't let them vertically span multiple columns */
    width: 100%; /*    // Don't let their width change*/
    /*  min-height: 4.6em*/
  }
</style>


