<template>
  <card class="card-user">
    <div class="card-text ">
    </div>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <router-link :to="{}" @click.native="MostrarTomaFoto=true">
        <img class="avatar avatarUsuarioComp" :src="FotoURL" alt="..." />
        <h4 class="title">{{Etiqueta}}</h4>
      </router-link>
      <div class="card-text d-flex justify-content-center mb-3">
        <!-- <base-button size="sm" class="mr-4" @click="EditarUsuarioPassword = true">Contraseña</base-button>-->
        <base-button size="sm" class="float-right" type="danger" @click="BorrarUsuarioMostrar = true">Borrar mi cuenta</base-button>
      </div>
      <!--<p class="description">Ceo/Co-Founder</p>-->
      <router-link :to="{}" @click.native="EditarUsuarioCorreo = true">
        <h5>
          <i class="fas fa-envelope mr-2 py-2"></i>
          <span>{{Correo}}</span>
        </h5>
      </router-link>
      <router-link :to="{}" @click.native="EditarUsuarioTelefono = true">
        <h5>
          <i class="fas fa-mobile mr-2 py-2"></i>
          <span>{{Telefono}}</span>
        </h5>
      </router-link>

    </div>

    <div slot="footer" class="button-container">


    </div>
    <TomaFotoComp :Mostrar.sync="MostrarTomaFoto" v-on:guardar="OnGuardarFoto" :cargando="cargando" :errorMsg="errorMsg" />
    <UsuariosBorrar :Mostrar.sync="BorrarUsuarioMostrar"
                    v-on:guardado="OnBorrado" v-on:error="OnError"></UsuariosBorrar>
    <UsuariosEditarLiAuth :Mostrar.sync="EditarUsuarioMostrar" :MostrarNombre="EditarUsuarioNombre" :MostrarCorreo="EditarUsuarioCorreo"
                          :MostrarClave="EditarUsuarioPassword" :MostrarTelefono="EditarUsuarioTelefono" ref="UsuarioEditar"
                          v-on:guardado="OnGuardado" v-on:error="OnError"></UsuariosEditarLiAuth>
  </card>
</template>
<script>
  import Usuarios from '@/api-base/usuarios.service';
  import Base from '@/api-base/base';
  import UsuariosEditarLiAuth from '@/Vistas-base/Usuarios/EditarLiAuth.comp.vue';
  import UsuariosBorrar from '@/Vistas-base/Usuarios/Borrar.comp.vue';
  import TomaFotoComp from '@/Vistas-base/Blobs/TomaFoto.comp.vue';

  export default {
    props: {

      Usuario: Object
    },
    components: {
      UsuariosEditarLiAuth,
      UsuariosBorrar,
      TomaFotoComp,
    },
    data() {
      return {
        Datos: null,
        cargando: false,
        errored: false,
        errorMsg: '',



        BorrarUsuarioMostrar: false,
        EditarUsuarioMostrar: false,
        EditarUsuarioPassword: false,
        EditarUsuarioNombre: false,
        EditarUsuarioCorreo: false,
        EditarUsuarioTelefono: false,

        MostrarTomaFoto: false,
      }
    },
    watch: {
      EditarUsuarioTelefono(EditarUsuarioTelefono) {
        if (EditarUsuarioTelefono)
          this.EditarUsuarioMostrar = true;
      },
      EditarUsuarioNombre(EditarUsuarioNombre) {
        if (EditarUsuarioNombre)
          this.EditarUsuarioMostrar = true;
      },
      EditarUsuarioPassword(EditarUsuarioPassword) {
        if (EditarUsuarioPassword)
          this.EditarUsuarioMostrar = true;
      },
      EditarUsuarioCorreo(EditarUsuarioCorreo) {
        if (EditarUsuarioCorreo)
          this.EditarUsuarioMostrar = true;
      },
      EditarUsuarioMostrar(EditarUsuarioMostrar) {
        if (!EditarUsuarioMostrar) {
          this.EditarUsuarioPassword = false;
          this.EditarUsuarioNombre = false;
          this.EditarUsuarioTelefono = false;
          this.EditarUsuarioCorreo = false;
        }
      },

    },
    methods: {
      OnGuardarFoto(LaFoto) {
        this.errorMsg = "";
        console.debug("OnGuardarFoto");
        let formData = new FormData();
        formData.append('Foto', LaFoto);
        var URL = `${Base.URL}/Usuarios/Foto?Orden=${0}`;

        Base.patch(this, URL, formData, {

          headers: {
            'Content-Type': 'multipart/form-data',
          },
      //    onUploadProgress: OnUploadProgress
        }, (response) => {
          console.debug(response.data);
          this.MostrarTomaFoto = false;
          var ND = new Date();
          this.Usuario.urlFotoMiniatura = `${response.data.urlThumb}?rnd=${(ND.getTime())}`;
          //this.NoCorrectos++;
        }, (Msg, error) => {
          if (error.response != null && error.response.status == 400)
            this.errorMsg = "No se guardo, debe contener un rostro visible";
          else
            this.errorMsg = Msg;
          //this.Erroneos.push(Nombre);
        }, () => {

        });
        /*
        Usuarios.FotoPatch(this,  0, LaFoto, (Progreso) => { }, (response) => {
          console.debug(response.data);
          this.MostrarTomaFoto = false;
          var ND = new Date();
          this.Usuario.urlFotoMiniatura = `${response.data.urlThumb}?rnd=${(ND.getTime())}`;
          //this.NoCorrectos++;
        }, (Msg, error) => {
          if (error.response != null && error.response.status == 400)
            this.errorMsg = "No se guardo, debe contener un rostro visible";
          else
            this.errorMsg = Msg;
          //this.Erroneos.push(Nombre);
        }, () => {

        });*/
      },
      OnGuardado() {
        this.Nombre = Usuarios.PerfilLocal.usuario.nombre;
        this.correo = Usuarios.PerfilLocal.usuario.correo;
        this.telefono = Usuarios.PerfilLocal.usuario.telefono;
      },
      OnError() {

      },
      OnBorrado() {
        this.$router.push({ name: 'login', query: { from: this.$route.query.from } });
      }
    },
    computed: {
      FotoURL() {
        return Usuarios.FotoUsuarioURL(this.Usuario);

      },
      Nombre() {
        if (this.Usuario === undefined || this.Usuario.nombre === '') {
          return 'El Nombre';
        }
        return this.Usuario.nombre;
      },
      Etiqueta() {
        if (this.Usuario === undefined || this.Usuario.etiqueta === '') {
          return 'El Nombre';
        }
        return this.Usuario.etiqueta;
      },
      Telefono() {
        if (this.Usuario === undefined || this.Usuario.nombre === '') {
          return '...';
        }
        return this.Usuario.telefono;
      },
      Correo() {
        if (this.Usuario === undefined || this.Usuario.nombre === '') {
          return '...';
        }
        return this.Usuario.correo;
      },
    },
  };
</script>
<style></style>

<style lang="scss" scoped>
  .avatarUsuarioComp {
    width: 250px !important;
    height: 250px !important;
  }

  .author {
      h5 {
        margin-bottom: 0px !important;
      }
  }
</style>
