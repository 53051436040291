<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category mb-0">{{Categoria}}</h5>
      <h3 class="card-title mb-0">{{Titulo}}</h3>
      <span class="text-muted">{{Ayuda}}</span>
    </div>

    <template>
      <ValidationObserver v-slot="{ handleSubmit}">
        <form @submit.prevent="handleSubmit(nuevo)">
          <MazInput v-model="datos.etiqueta" :error="datos.etiqueta==''"
                    placeholder="Ej. Cumple de Andy"
                    :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                    class="maz-mb-2" required :loading="loading || cargando"
                    clearable>
            <template v-slot:icon-left>
              <i class="fas fa-tag"></i>
            </template>
          </MazInput>
          <MazInput v-model="datos.descripcion" class="maz-mb-2"
                    placeholder="Ej. Traer regalo" :loading="loading || cargando"
                    :autocomplete="autocompleteComentario" :disabled="loading || cargando"
                    left-icon-name="comment"
                    textarea />

          <MazPicker v-model="FHComienzo" :loading="loading || cargando" class="maz-mb-2"
                     nowTranslation="Hoy" ref="comienzo"
                     :minuteInterval="15" :format="Formato" formatted="ddd, DD/MMM/YY, h:mm a"
                     required :error="!ComienzoValido"
                     placeholder="Fecha y hora del evento">
            <template v-slot:icon-left>
              <i class="fas fa-calendar"></i>
            </template>
          </MazPicker>
          <MazInputTags v-model="datos.invitados" :error="InvitadosInValido" ref="invitados" required
                        placeholder="Nombre de cada invitado (enter p/confirmar)" size="sm"
                        :loading="cargando||loading" class="mb-2"
                        clearable />


            <TipoVisitaSelect placeholder="Tipo de visita" :loading="cargando" :todas="true"
                              position="top"
                              class="maz-mb-2" seleccionarUno required :clearable="false" :search="false"
                              v-model="datos.tipoVisitaID" />


          <ValidationProvider v-if="MostrarUbicaciones" name="UbicacionID"
                              rules="required"
                              v-slot="{ passed, failed, errors }">
            <UbicacionesSelect  placeholder="Sucursal / Ubicación" :loading="cargando" :todas="true"
                               position="top"
                               class="maz-mb-2" seleccionarUno required :clearable="false" :search="false" 
                               v-model="datos.ubicacionID" />
          </ValidationProvider>



          <hr class="my-md-4" />


          <Main-Cargando :cargando="cargando" :MsgError="MsgError">
            <base-button native-type="submit" type="primary" block>
              Registrar
            </base-button>
          </Main-Cargando>

          <div v-if="Cancelar" class="text-muted text-center">
            <router-link :to="{}" class="d-block mt-2" @click.native="Cerrar()">
              <i class="fas fa-chevron-left"> </i> Cancelar
            </router-link>

          </div>
        </form>
      </ValidationObserver>
    </template>

  </modal>
</template>

<script>
  import Servicio from '@/api-services/reuniones.service';
  import { MazPicker, MazInputTags } from 'maz-ui'
  import Base from '@/api-base/base';

  import UbicacionesSelect from '@/Vistas/Ubicaciones/Select.vue';
  import TipoVisitaSelect from '@/Vistas/TiposVisitas/Select.vue';
  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');

  export default {
    components: {
      MazPicker,
      MazInputTags,
      UbicacionesSelect,
      TipoVisitaSelect,
    },
    name: 'Reuniones-Nuevo',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Reuniones' },
      Titulo: { type: String, default: 'Nueva' },
      Ayuda: { type: String, default: 'Invita a personas a tu reunión.' },

      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
      Cancelar: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {
          etiqueta: '',
          comentarios: '',
          comienzo: '',
          tipoVisitaID: null,
          ubicacionID: null,
          invitados: [],
        },


        MostrarUbicaciones: true,

        Modal: false,
        cargando: false,
        Formato: 'YYYY-MM-DD hh:mm a',
        Erroneo: '',
        MsgError: ''
      }
    },
    computed: {
      ComienzoValido() {
        return (this.datos.etiqueta == "" || this.datos.comienzo != "");
      },
      InvitadosInValido() {
        return (this.datos.comienzo != "" && this.datos.invitados.length < 1);
      },
      FHComienzo: {
        // getter
        get() {
          if (this.datos.comienzo == "")
            return "";
          var date = moment(this.datos.comienzo);
          return date.format(this.Formato);
        },
        // setter
        set(newValue) {
          //console.debug(newValue);
          var date = moment(newValue, this.Formato);
          // console.debug(date);
          this.datos.comienzo = date.format();;
          //  console.debug(this.datos.comienzo);
        }
      },
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },

    },

    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;

    },
    created() {
    },
    methods: {
      OnFinalizado(Datos) {
        console.debug(Datos);
        if (Datos.listado.length < 2) {
          this.MostrarUbicaciones = false;
          if (Datos.listado.length == 1)
            this.datos.ubicacionID = Datos.listado[0].id;
        }
        else
          this.MostrarUbicaciones = true;
      },
      nuevo() {
        if (this.datos.comienzo == "") {
          this.$refs.comienzo.$el.focus();
          return;
        }
        console.debug(JSON.stringify(this.datos.comienzo));
        var invitados = this.$refs.invitados;
        if (invitados.inputValue != null && invitados.inputValue != "") {
          this.datos.invitados.push(invitados.inputValue);
          invitados.inputValue = "";
        }
        if (this.datos.invitados.length < 1) {
          invitados.isFocus = true;
          invitados.$el.focus();
          return;
        }
        console.debug(JSON.stringify(this.datos));
        Servicio.post(this, this.datos, (response) => {
          this.$emit('guardado', response.data);

          this.Cerrar();

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        this.datos = {
          etiqueta: '', descripcion: ''
        };
        this.Modal = false;
      },
    }
  }
</script>

<style scoped>
</style>
