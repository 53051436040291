<template>
  <modal :show.sync="Modal"
         :appendToBody="false"
         class="modal-black" centered
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <h5 class="card-category">Usuarios</h5>
      <h3 class="card-title">Nuevo</h3>
    </div>
    <template>

      <ValidationObserver v-slot="{ handleSubmit}">
        <form @submit.prevent="handleSubmit(Guardar)" class="mx-2">

          <div class="row pb-3">
            <div class="col  px-0  ">
              <ValidationProvider name="Nombre"
                                  rules="required"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.nombre"
                          placeholder="Nombre(s)"
                          autocomplete="nuevo-nombre" size="sm" :error="failed"
                          required :loading="cargando"
                          clearable>
                  <!--    <i slot="icon-left"
          class="tim-icons icon-single-02"></i>-->
                </MazInput>
              </ValidationProvider>
            </div>
          </div>




          <div class="row pb-3">
            <div class="col px-0 pr-1">
              <ValidationProvider name="ApellidoP"
                                  rules="required"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.apellidoP"
                          placeholder="Apellido Paterno"
                          autocomplete="nuevo-apellidop"
                          required :loading="cargando" size="sm" :error="failed"
                          clearable>

                </MazInput>
              </ValidationProvider>

            </div>
            <div class="col px-0 pl-1">
              <MazInput v-model="datos.apellidoM"
                        placeholder="Apellido Materno"
                        autocomplete="nuevo-apellidom"
                        :loading="cargando" size="sm"
                        clearable>

              </MazInput>

            </div>
          </div>




          <div class="row pb-3">
            <MazPhoneNumberInput v-model="datos.telefono" class="flex-fill"
                                 :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                                 :preferred-countries="MazPhoneNumber.PaisesPreferidos"
                                 showCodeOnList required
                                 :loading="cargando" placeholder="No. Celular Personal"
                                 @update="resultTelefono=$event" size="sm" />
          </div>



          <div class="row pb-0">
            <div class="col-sm-7  mb-3  px-0  pr-1">
              <ValidationProvider name="email"
                                  rules="email||required"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.correo" size="sm"
                          placeholder="email Personal"
                          autocomplete="nuevo-correo" :loading="cargando" required
                          type="email" :error="failed"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                          clearable>
                  <i slot="icon-left"
                     class="tim-icons icon-email-85">

                  </i>
                  <i slot="icon-right">

                  </i>
                </MazInput>
              </ValidationProvider>
            </div>
          </div>
          <TipoSelect v-model="datos.tipoUsuaria" />



          <hr class="my-md-4" />

          <base-button v-if="!cargando" native-type="submit" slot="footer" type="primary" block size="lg">
            Registrar
          </base-button>

          <div class="text-muted text-center">
            <p v-for="Correcto in Correctos" :key="Correcto" class="badge badge-success mr-2 text-wrap">
              {{ Correcto }}
            </p>
            <p v-for="Erroneo in Erroneos" :key="Erroneo" class="badge badge-danger mr-2 text-wrap">
              {{ Erroneo }}
            </p>
            <div v-if="cargando" class="text-center mt-7">
              <b-spinner type="grow" label="cargando..."></b-spinner>
              Cargando...
            </div>
            <a href="#" class="d-block mt-2" @click="Cerrar()">
              <i class="fas fa-chevron-left"> </i> Cancelar
            </a>

          </div>


        </form>
      </ValidationObserver>

    </template>

  </modal>
</template>

<script>
  import Base from '@/api-base/base';
  import Servicio from '@/api-services/usuarias.service';
  import { MazPhoneNumberInput } from 'maz-ui'
  import TipoSelect from './Tipo.select.vue'



  export default {

    name: 'personal-nuevo-modal',
    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
    },
    data() {
      return {
        datos: {
          nombre: '',
          apellidoP: '',
          apellidoM: '',
          correo: '',
          telefono: '',
          tipoUsuaria: 'Administrador',
        },
        Guardado: false,
        resultTelefono: {},
        Modal: false,
        cargando: false,




        GuardandoPos: 0,
        Erroneos: [],
        Correctos: [],

        NoCorrectos: 0,
      }
    },
    components: {
      MazPhoneNumberInput,
      TipoSelect,
    },
    computed: {
      MazPhoneNumber() {
        return Base.MazPhoneNumber;
      },
    },
    watch: {
      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;

    },
    created() {
    },
    methods: {


      Guardar() {

        //console.debug(isoDate);
        this.Erroneos = [];
        this.Correctos = [];
        if (this.datos.telefono == null || this.datos.telefono == "") {
          this.Erroneos.push("Se requiere un No. Teléfono válido");
          this.$appInsights.trackEvent({ name: this.$options.name }, { Guardar: "Sin Teléfono" });
          return;
        }
        if (this.datos.telefono != null && this.datos.telefono != "" && !this.resultTelefono.isValid) {
          this.Erroneos.push("No. de Teléfono invalido");
          this.$appInsights.trackEvent({ name: this.$options.name }, { Guardar: "No. de Teléfono invalido" });
          return;
        }


        /*  var isoDate = new Date(this.fechaLocal).toISOString();
          this.nuevo.FechaIngreso = isoDate;*/
        //console.debug(JSON.stringify(this.nuevo));
        Servicio.post(this, this.datos, (response) => {
          /*    Servicio.post(this, {
                "linkID": "string",
                "nombre": "string",
                "apellidoP": "string",
                "apellidoM": "string"
              }, (response) => {*/
          console.debug(response.data);
          //this.Cerrar();
          this.$emit('guardado', response.data);
          this.Cerrar();

        }, (MsgError, error) => {
          if (error.response != null)
            switch (error.response.status) {

              case 409:


                //var Campo = "No de emplead"
                switch (error.response.data) {
                  case "LinkID":
                    MsgError = "No. de empleado ya registrado, intente con otro no. o verifique en borrados";
                    break;
                  case "Correo":
                    MsgError = "Debe ser un correo electronico único y real";
                    break;
                  case "Telefono":
                    MsgError = "Debe ser un no. de teléfono único y real";
                    break;
                }
                break;
            }

          this.Erroneos.push(MsgError);
          return MsgError;
        });
      },
      Cerrar() {
        this.datos = {
          etiqueta: '', descripcion: ''
        };
        this.Modal = false;
      },
    }
  }
</script>

<style src="maz-ui/lib/css/index.css"></style>

<style src="maz-ui/lib/css/base.css"></style>
<style src="maz-ui/lib/css/maz-picker.css"></style>

<style scoped>
  .vti__dropdown-list {
    background-color: #000000 !important
  }
</style>
