import Axios from 'axios';
import LiAuth from '@/api-base/liauth.service'

const REUNIONES = `${Base.URL}/reuniones`;

import Base from '@/api-base/base'
const BaseURL = `Reuniones`;
import moment from 'moment'
//var moment = require('moment');
moment.locale('es-mx');

export default {
  EsFechaValida(value) {
    var Ahorita = moment();
    var Fecha = moment(value);
    var Desde = moment(value).subtract(Fecha.hour() * 60 + Fecha.minute(),"minutes");
    var Hasta = moment(value).add(1, 'days');
    console.debug(Desde);
    console.debug(Hasta);
    console.debug(Fecha);
    return Desde <= Ahorita && Hasta >= Ahorita;
  },
  //Formato fecha y hora para reuniones
  FFechaHora(value) {
    var Fecha = moment(value);
    return Fecha.format("ddd, DD/MMM/YY, h:mm:ss a");
  },
  ObtenUrlInvitacion(Reunion) {
    return window.location.protocol + "//" + window.location.host + "/#/ri?i=" + Reunion.reunionAlternoID;
  },
  ObtenUrlEdicion(Reunion) {
    return window.location.protocol + "//" + window.location.host + "/#/reuniones/edicion?i=" + Reunion.reunionAlternoID;
  },


  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null)
      OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(Vista, BaseURL, { PaginaLen: 1000 }, (response) => {
      this.GetsResponse = response;
      this.Listado = response.data.listado;
      if (OnCorrecto != undefined)
        OnCorrecto(response);

    }, OnErroneo, OnFinalizado);
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  PostPersona(Vista, ReunionID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(Vista, `${BaseURL}/${ReunionID}/Personas`, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(Vista, BaseURL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${id}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getConsulta(Vista,ReunionID, LinkID, OnCorrecto, OnErroneo, OnFinalizado)  {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${ReunionID}/Consulta?LinkID=${LinkID}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(Vista, `${BaseURL}/${id}`, OnCorrecto, OnErroneo, OnFinalizado);
  },
  SelfieIdentificarPatch(Vista, ReunionID, PersonaID, Archivo, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('Foto', Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ReunionID}/Selfie/Identificar?PersonaID=${PersonaID}`);
    return Base.patch(Vista, URL, formData, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },

    }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  PostAccion(Vista, ReunionID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(Vista, `${BaseURL}/${ReunionID}/Accion`, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  }
};
