<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-2 pt-md-8">
      <div class="container-fluid p-3">
        <div class="row align-items-center">
          <div class="col">
            <h1 class="mb-0">
              Nueva reunion
            </h1>
          </div>

        </div>
      </div>

      <form role="form">
        <div class="container p-3">
          <div class="row align-items-center">
            <div class="col-4 text-right">
              <h3>Titulo</h3>
            </div>
            <div class="col-8">
              <base-input class="input-group-alternative mb-3" addon-left-icon="ni ni-tag"
                          placeholder="Titulo de la reunion"
                          v-model.trim="titulo" :valid="$v.titulo.required && $v.titulo.minLength">
              </base-input>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-4 text-right">
              <h3>Descripcion</h3>
            </div>
            <div class="col-8">
              <base-input addon-left-icon="ni ni-support-16" class="mb-3">
                <textarea class="form-control " addon-left-icon="ni ni-collection" v-model.trim="descripcion"
                          id="exampleFormControlTextarea1" rows="3" placeholder="Descripción detallada ..."></textarea>
              </base-input>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-4 text-right">
              <h3>Comienza</h3>
            </div>
            <div class="col-8">
              <base-input addon-left-icon="ni ni-calendar-grid-58">
                <MazPicker v-model="comienza" :loading="cargando"
                            nowTranslation="Hoy"
                            autoClose
                          :required="true" 
                           placeholder="Fecha y hora" :disabled="false" 
                           :formatted="formatted" />



              </base-input>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-4 text-right">
              <h3>Finaliza</h3>
            </div>
            <div class="col-8">
              <base-input addon-left-icon="ni ni-calendar-grid-58">
 

              </base-input>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-4 text-right">
              <h3>Invitados</h3>
            </div>
            <div class="col-8">

              <MazInputTags v-model="tag"
                            placeholder="Nombre de cada invitado (enter p/confirmar)" size="sm"
                            :loading="cargando||loading" class="mb-3"
                            clearable />



            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-4 text-right">

            </div>
            <div class="col-8">

              <h6>Escriba el nombre de cada invitado, seguido de confirmación</h6>


            </div>
          </div>
          <!--
          <div class="row align-items-center">
              <div class="col-4 text-right">
                  <h3>Ubicación</h3>
              </div>
              <div class="col-8">
                  <base-input class="input-group-alternative mb-3" addon-left-icon="fa fa-map-marker"
                              placeholder="Ubicación"
                              v-model.trim="ubicacion" :valid="$v.ubicacion.required && $v.ubicacion.minLength">
                  </base-input>
              </div>
          </div>-->

        </div>

        <div class="container-fluid p-3">
          <div class="row align-items-center">
            <div class="col">
              <a href="javascript:history.go(-1)">
                <!--  <router-link :to="-1">
                    <base-button type="alternative" class="my-4">Regresar</base-button>
                </router-link>
                -->
                <base-button type="alternative" class="my-4">Regresar</base-button>
              </a>
            </div>
            <div class="col text-right">
              <base-button type="primary" v-on:click="crear" class="my-4" :disabled="!$v.titulo.required || !$v.comienza.required || !$v.finaliza.required">Crear reunion</base-button>
            </div>
          </div>
        </div>



      </form>


    </base-header>

  </div>
</template>
<script>
  import Reuniones from '@/api-services/reuniones.service';
  import { required, minLength } from 'vuelidate/lib/validators';

  import { MazInputTags, MazPicker} from 'maz-ui'

  export default {
    name: 'NuevaReunion',
    data() {
      return {
        tag: '',
        tags: [],
        separadores: [13, ',', ';', ':'],
        titulo: '',
        descripcion: '',
        comienza: '',
        finaliza: '',
        ubicacion: '',

        cargando: false,
        formatted: '',
      }
    },
    components: {
      MazInputTags,
      MazPicker
    },
    validations: {
      titulo: {
        required,
        minLength: minLength(5)
      },
      comienza: {
        required,
      },
      finaliza: {
        required,
      },
    },
    methods: {
      crear: function () {
        var Elementos = [];
        for (const t of this.tags) {
          Elementos.push(t.text);
        }
        if (this.tag !== undefined && this.tag !== '')
          Elementos.push(this.tag);
        var Invitados = JSON.stringify(Elementos);
        //console.log(Invitados);
        var N = 2;
        if (N == 1)
          return;
        if (this.$v.$invalid) {
          // this.modals.modal2 = true
          return;
        }
        //    var myDate4 = Date.parseExact(this.comienza, "yyyy-dd-MM HH:mm");
        //     console.log(this.comienza);
        var sComienza = `${this.comienza}`.replace(/-/g, "/");
        //     console.log(sComienza);
        //   console.log(new Date(sComienza).toISOString());
        var sFinaliza = `${this.finaliza}`.replace(/-/g, "/");
        var AEnviar = {
          ubicacionID: 2, titulo: this.titulo, descripcion: this.descripcion,
          comienzo: new Date(sComienza).toISOString(), finaliza: new Date(sFinaliza).toISOString(),
          invitados: Invitados
          //, suscripcionID: 1
        };
        console.log(AEnviar);
        try {

          Reuniones.postReunion(AEnviar).then((response) => {
            console.log(response.data);
            this.$router.push(`/ri?i=${response.data.reunionAlternoID}&p=true`);
          }).catch((error) => {
            if (error.response.data == undefined) {
              console.error(error.response);
              alert("Error desconocido");
            }
            else {
              console.error(error.response.data);
              alert(error.response.data.error.mensaje);
            }
          });
        }
        catch (error) {
          alert('Error desconocido, intente nuevamente');
          console.error(error);
          // expected output: ReferenceError: nonExistentFunction is not defined
          // Note - error messages will vary depending on browser
        }
      }
    },

  };</script>
<style></style>
