import Base from '@/api-base/base'
const BaseURL = `Accesos`;


export default {


  Color(Acceso) {
    /*
     *             Manual=-1,
            NoDefinido = 0,
            Correcto,
            Entrada,
            Salida,
            SalidaComer,
            RegresoComer,
            Incorrecto,
            RegresoDescanso,
            RegresoLlamada,
            AbrioPuerta,
            CerroPuerta,
            AlarmoPuerta,
            ActualizoFirmware,
            ReinicioDispositivo,*/
    switch (Acceso.tipoAcceso) {
      case "Manual":
        return "#15CD20";
      case "Correcto":
        return "#2ECC71";
      case "Entrada":
        return "#5DF566";
      // break;
      case "Salida":
        return "#1B591F";

      // break;
      //Pregunta
      case "Pregunta":
        return "#F7DC6F";
      // break; 
      ///Respuesta a la pregunta
      case "Respuesta":
        return "#F1C40F";
      //  break;
      ///No contesto
      case "SinRespuesta":
        return "#E6B0AA";
      case "Incorrecto":
        return "#C0392B";
      //  break;
    }
    return "#E9F7EF";
  },
  Icono(Acceso) {
    /*
     *             Manual=-1,
            NoDefinido = 0,
            Correcto,
            Entrada,
            Salida,
            SalidaComer,
            RegresoComer,
            Incorrecto,
            RegresoDescanso,
            RegresoLlamada,
            AbrioPuerta,
            CerroPuerta,
            AlarmoPuerta,
            ActualizoFirmware,
            ReinicioDispositivo,*/
    switch (Acceso.tipoAcceso) {
      case "Manual":
        return "fas fa-plus";
      case "Entrada":
        return "fas fa-sign-in-alt";
      // break;
      case "Salida":
        return "fas fa-sign-out-alt";

      // break;
      //Pregunta
      case "Pregunta":
        return "fas fa-question";
      // break; 
      ///Respuesta a la pregunta
      case "Respuesta":
        return "fas fa-comment";
      //  break;
      ///No contesto
      case "SinRespuesta":
        return "fas fa-microphone-slash";
      case "Incorrecto":
        return "fas fa-user-alt-slash";
      //  break;
    }
    return "fas fa-door-open";
  },
  sTipoAcceso(Acceso) {
    var Etiqueta = "Desconocido";

    switch (Acceso.tipoAcceso) {
      case "Manual":
        return "Manual";
      case "Correcto":
        return "Correcto";
      case "Entrada":
        return "Entrada";
      // break;
      case "Salida":
        return "Salida";
      case "Rondin":
        return "Rondin";
      // break;
      //Pregunta
      case "Pregunta":
        return "Pregunta";
      // break; 
      ///Respuesta a la pregunta
      case "Respuesta":
        return "Respuesta";
      //  break;
      ///No contesto
      case "SinRespuesta":
        return "Sin Respuesta";
      case "Incorrecto":
        return "Incorrecto";
      //  break;
    }

    return Etiqueta;
  },
  typeTipoAcceso(value) {
    switch (value.tipoAcceso) {
      case 3:
        return "default";
      // break;
      case "Manual":
        return "warning";
      // break;
      case 5:
        return "secondary";
      // break;
      case "Rondin":
      case "Correcto":
      case "Entrada":
      case "Salida":
        return "success";
      //  break;
      case "SinRespuesta":
      case "Incorrecto":
        return "danger";
      //  break;
    }
    return "danger";
  },
  //Obtiene la ultima checada por colaborador 
  GetUltimoXColaboradorGPS(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/UltimoXColaboradorGPS`, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //Obtiene la ultima checada de un colaborador
  getColaboradorUltimo(Vista, ColaboradorID, OnCorrecto, OnErroneo, OnFinalizado) {
    var Url = `${BaseURL}/Colaborador/Ultimo`;
    if (ColaboradorID != null && ColaboradorID != "")
      Url = `${BaseURL}/Colaborador/${ColaboradorID}/Ultimo`;
    return Base.getsBaseSuscripcion(Vista, Url, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getColaborador(Vista, ColaboradorID, Params,OnCorrecto, OnErroneo, OnFinalizado) {
    var Url = `${BaseURL}/Colaborador`;
    if (ColaboradorID != null && ColaboradorID != "")
      Url = `${BaseURL}/Colaborador/${ColaboradorID}`;
  //  var FechaHora = new Date();

    return Base.getsBaseSuscripcion(Vista, Url, Params, OnCorrecto, OnErroneo, OnFinalizado);
  },
  FiltroGets(Vista, Params, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/Filtro`, Params, OnCorrecto, OnErroneo, OnFinalizado);
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  Borrar(Vista, Parametros, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.putBaseSuscripcion(Vista, `${BaseURL}/Borrar`, Parametros, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  get(Vista, ID, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${ID}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  TotalesPorDiaGets(Vista, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/TotalesPor/Dia`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(Vista, BaseURL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },

  //Obtiene la plantilla de importaicón para la suscripción activa
  ExcelPlantillaGet(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel/Plantilla`);
    return Base.get(Vista, URL, { responseType: 'blob', params: { EsXls: false } }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //Importa un archivo de excel
  ExcelPlantillaPatch(Vista, DispositivoID, Archivo, OnUploadProgress, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('ArchivoExcel', Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel/${DispositivoID}`);
    return Base.patch(Vista, URL, formData, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: OnUploadProgress
    }, OnCorrecto, OnErroneo, OnFinalizado);
  },


};
