var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-7 mr-auto"},[_c('card',{staticClass:"card-register card-white"},[_c('template',{staticClass:"pb-2",slot:"header"},[_c('img',{staticClass:"card-img",attrs:{"src":"img/card-primary.png","alt":"Card image"}}),_c('h4',{staticClass:"card-title text-dark "},[_vm._v("Registro")])]),(_vm.estado=='capturando')?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.registrarse)}}},[_c('ValidationProvider',{attrs:{"name":"fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Nombre completo","addon-left-icon":"tim-icons icon-single-02","type":"text","error":errors[0]},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"whatsapp","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Whatsapp","addon-left-icon":"tim-icons icon-mobile","type":"phone","error":errors[0]},model:{value:(_vm.whatsapp),callback:function ($$v) {_vm.whatsapp=$$v},expression:"whatsapp"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Correo electrónico","addon-left-icon":"tim-icons icon-email-85","type":"email","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Contraseña","addon-left-icon":"tim-icons icon-lock-circle","type":"password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('base-checkbox',{staticClass:"text-left",model:{value:(_vm.terminos),callback:function ($$v) {_vm.terminos=$$v},expression:"terminos"}},[_vm._v(" Estoy de acuerdo con los "),_c('a',{attrs:{"href":"#something"}},[_vm._v("terminos y condiciones")]),_vm._v(". ")]),(!_vm.trabajando)?_c('base-button',{attrs:{"slot":"footer","native-type":"submit","disabled":!_vm.terminos,"type":"primary","round":"","block":"","size":"lg"},slot:"footer"},[_vm._v(" Registrarse ")]):_vm._e()],1)]}}],null,false,1333937425)})],1):_vm._e(),(_vm.estado=='validandoCorreo')?_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"envelope","variant":"primary","font-scale":"7.5"}}),_c('p',{staticClass:"text-dark mt-3"},[_vm._v(" Se te ha enviado un correo a "+_vm._s(_vm.email)+" con tu código de verificación, revisa correo no deseado. "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.corregirCorreo=true}}},[_vm._v("Cambiar correo")])]),_c('modal',{attrs:{"show":_vm.corregirCorreo,"body-classes":"p-0","modal-classes":"modal-dialog-centered modal-sm"},on:{"update:show":function($event){_vm.corregirCorreo=$event}}},[_c('card',{staticClass:"mb-0 text-center",attrs:{"type":"white","header-classes":"bg-white pb-5","body-classes":"px-lg-5 py-lg-5"}},[[_c('h2',{staticClass:"text-dark mb-4"},[_vm._v("Corrección de correo electrónico")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.correccionCorreo)}}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Correo electrónico","type":"email","error":errors[0]},model:{value:(_vm.nuevoEmail),callback:function ($$v) {_vm.nuevoEmail=$$v},expression:"nuevoEmail"}})]}}],null,true)}),(_vm.error)?_c('base-alert',{attrs:{"type":"danger"}},[_c('strong',[_vm._v("Error:")]),_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.trabajando)?_c('b-spinner',{attrs:{"type":"grow","label":"Loading..."}}):_vm._e(),(!_vm.trabajando)?_c('base-button',{staticClass:"my-4",attrs:{"type":"primary","native-type":"submit"}},[_vm._v("Enviar nuevo código")]):_vm._e()],1)]}}],null,false,2904358852)}),_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.corregirCorreo=false}}},[_vm._v("Cancelar")])]],2)],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validarCorreo)}}},[_c('ValidationProvider',{attrs:{"name":"codigoCorreo","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Ingresa el código","addon-left-icon":"tim-icons icon-lock-circle","type":"phone","error":errors[0]},model:{value:(_vm.codigoCorreo),callback:function ($$v) {_vm.codigoCorreo=$$v},expression:"codigoCorreo"}})]}}],null,true)}),(!_vm.trabajando)?_c('base-button',{attrs:{"slot":"footer","native-type":"submit","type":"primary","round":"","block":"","size":"lg"},slot:"footer"},[_vm._v(" Validar ")]):_vm._e()],1)]}}],null,false,297370658)})],1):_vm._e(),(_vm.estado=='validandoWhatsapp')?_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"phone","variant":"primary","font-scale":"7.5"}}),_c('p',{staticClass:"text-dark  mt-3"},[_vm._v(" Se te envio un SMS a "+_vm._s(_vm.whatsapp)+" con tu código de verificación. "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.corregirWhatsapp=true}}},[_vm._v("Cambiar número o reenviar código")])]),_c('modal',{attrs:{"show":_vm.corregirWhatsapp,"body-classes":"p-0","modal-classes":"modal-dialog-centered modal-sm"},on:{"update:show":function($event){_vm.corregirWhatsapp=$event}}},[_c('card',{staticClass:"mb-0 text-center",attrs:{"type":"white","header-classes":"bg-white pb-5","body-classes":"px-lg-5 py-lg-5"}},[[_c('h2',{staticClass:"text-dark mb-4"},[_vm._v("Corrección de número teléfonico")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.correccionWhatsapp)}}},[_c('ValidationProvider',{attrs:{"name":"whatsapp","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"número telefónico","type":"phone","error":errors[0]},model:{value:(_vm.nuevoWhatsapp),callback:function ($$v) {_vm.nuevoWhatsapp=$$v},expression:"nuevoWhatsapp"}})]}}],null,true)}),(_vm.error)?_c('base-alert',{attrs:{"type":"danger"}},[_c('strong',[_vm._v("Error:")]),_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.trabajando)?_c('b-spinner',{attrs:{"type":"grow","label":"Loading..."}}):_vm._e(),(_vm.segundosPendientes>0)?_c('p',[_vm._v("Espere "+_vm._s(_vm.segundosPendientes)+" segundos")]):_vm._e(),(!_vm.trabajando && _vm.segundosPendientes<=0)?_c('base-button',{staticClass:"my-4",attrs:{"type":"primary","native-type":"submit"}},[_vm._v("Enviar nuevo código")]):_vm._e()],1)]}}],null,false,997125442)}),_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.corregirWhatsapp=false}}},[_vm._v("Cancelar")])]],2)],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validarTelefono)}}},[_c('ValidationProvider',{attrs:{"name":"codigoWhatsapp","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Ingresa el código","addon-left-icon":"tim-icons icon-lock-circle","type":"phone","error":errors[0]},model:{value:(_vm.codigoWhatsapp),callback:function ($$v) {_vm.codigoWhatsapp=$$v},expression:"codigoWhatsapp"}})]}}],null,true)}),(!_vm.trabajando)?_c('base-button',{attrs:{"slot":"footer","native-type":"submit","type":"primary","round":"","block":"","size":"lg"},slot:"footer"},[_vm._v(" Validar ")]):_vm._e()],1)]}}],null,false,1231388980)})],1):_vm._e(),_c('div',{staticClass:"text-center"},[(_vm.estado=='usuarioPreviamenteRegistrado' || _vm.estado=='registroErroneo')?_c('div',[_c('i',{staticClass:"fas fa-times-circle fa-7x"})]):_vm._e(),(_vm.estado=='registroErroneo')?_c('div',[_c('p',[_vm._v("No se pudo registrar, intente nuevamente o contacte a soporte técnico.")]),_c('a',{attrs:{"href":"#"},on:{"click":_vm.confirmarRegistro}},[_vm._v("Reintentar registro")])]):_vm._e()]),(_vm.trabajando)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"type":"grow","label":"Loading..."}})],1):_vm._e(),(_vm.error)?_c('base-alert',{attrs:{"type":"danger"}},[_c('strong',[_vm._v("Error:")]),_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.estado=='capturando' || _vm.estado=='usuarioPreviamenteRegistrado')?_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"pull-left"},[_c('h6',[_c('router-link',{staticClass:"link footer-link",attrs:{"to":"/login"}},[_vm._v(" Iniciar sesión ")])],1)]),_c('div',{staticClass:"pull-right"},[_c('h6',[_c('router-link',{staticClass:"link footer-link",attrs:{"to":"/recuperar"}},[_vm._v(" Recuperar contraseña ")])],1)])]):_vm._e()],2)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-5 ml-auto"},[_c('div',{staticClass:"info-area info-horizontal"},[_c('div',{staticClass:"icon icon-info"},[_c('i',{staticClass:"tim-icons icon-tap-02"})]),_c('div',{staticClass:"description"},[_c('h3',{staticClass:"info-title"},[_vm._v("Seguridad y biometría")]),_c('p',{staticClass:"description"},[_vm._v(" Tu información esta segura, además validamos la identidad de tu personal por diversos métodos biométricos. ")])])]),_c('div',{staticClass:"info-area info-horizontal "},[_c('div',{staticClass:"icon icon-warning"},[_c('i',{staticClass:"tim-icons icon-world"})]),_c('div',{staticClass:"description"},[_c('h3',{staticClass:"info-title"},[_vm._v("Solución en la nube")]),_c('p',{staticClass:"description"},[_vm._v(" Administra la puntualidad de tu persona sin importar en que sucursal se encuentre. ")])])]),_c('div',{staticClass:"info-area info-horizontal"},[_c('div',{staticClass:"icon icon-primary"},[_c('i',{staticClass:"tim-icons icon-refresh-02"})]),_c('div',{staticClass:"description"},[_c('h3',{staticClass:"info-title"},[_vm._v("En Tiempo Real")]),_c('p',{staticClass:"description"},[_vm._v(" Toda tu información al momento para ahorrarte tiempo que es dinero. ")])])])])}]

export { render, staticRenderFns }