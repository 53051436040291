<template>
    <div>
        <base-header v-if="!loading" class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                     style="min-height: 200px;  background-size: cover; background-position: center top;">
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col">
                        <h1 class="display-2 text-white">Invitación para ser {{Perfil}}</h1>
                        <p v-if="EsMia" class="text-white mt-0 mb-4">Otros pueden escanear el siguiente código para aceptar tu invitación o puedes compartir esta liga</p>
                        <p v-else class="text-white mt-0 mb-4">Te han enviado la siguiente invitación {{textoSuscripcion}}</p>
                        <a href="javascript:history.go(-1)" class="btn btn-info mb-5">Regresar</a>
                    </div>
                </div>
            </div>
        </base-header>
        <section v-if="errored">
            <p>Lo sentimos, no es posible obtener la información en este momento, por favor intente nuevamente mas tarde</p>
        </section>
        <section v-else>
            <div v-if="loading">Cargando...</div>

            <div v-else>
                <div class="container-fluid mt--7">
                    <div class="row justify-content-md-center">
                        <div class=" col-xl-8 order-xl-2 mb-5 mb-xl-0 justify-content-center">

                            <div class="card card-profile shadow">
                                <div class="row justify-content-center">
                                    <div class="col-lg-3 order-lg-2">
                                        <div class="card-profile-image">
                                            <qriously v-if="EsMia" :value="url" :size="200" :backgroundAlpha="1" />
                                            <a v-else href="#">
                                                <img src="img/theme/user512.png" class="rounded-circle">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div v-if="EsMia && !info.borrado" class="d-flex justify-content-between">
                                        <base-button size="sm" type="danger" v-on:click="borrar" class="mr-4">Borrar</base-button>

                                        <base-dropdown class=" " position="left">

                                            <base-button slot="title" type="default" size="sm" class="dropdown-toggle" >
                                                Compartir
                                            </base-button>
                                            <a class="dropdown-item " :href="compartirWhatsapp" data-action="share/whatsapp/share" target="_blank">
                                                <i class="fab  fa-whatsapp" aria-hidden="true"></i>Whatsapp
                                            </a>
                                            <a class="dropdown-item " :href="compartirMail" target="_blank">
                                                <i class="fa fa-envelope" aria-hidden="true"></i>Correo electrónico
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item " v-clipboard:copy="compartir"
                                               v-clipboard:success="onCopy">
                                                <i class="fa  fa-clipboard" aria-hidden="true"></i>Copiar al portapapeles
                                            </a>
                                        </base-dropdown>
                                    </div>

                                </div>

                                <div class="card-body pt-0 pt-md-4">

                                    <div class="row">
                                        <div class="col">
                                            <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                                <base-alert v-if="aceptada" type="success">
                                                    <strong>Aceptada!</strong> se ha aceptado la invitación
                                                </base-alert>



                                                <base-alert v-if="info.borrado" type="danger">
                                                    <strong>Borrado!</strong> Se ha eliminado la invitación actual!
                                                </base-alert>
                                                <base-button v-if="!EsMia && !info.borrado && !aceptada" size="sm" type="default" v-on:click="aceptar">Aceptar Invitación</base-button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        
                                        <base-button v-if="aceptada" size="sm" type="success" v-on:click="window.location.reload(true)" class="mb-4"><a href="javascript:window.location.reload(true)" >recargar</a></base-button>
                                        <h5>Anfitrion</h5>
                                        <h3>
                                            {{invitadoX}}
                                        </h3>
                                        <h4>
                                            <i class="fa fa-mobile mr-2"> </i>{{invitadoXTelefono}}
                                        </h4>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
  import Usuarios from '@/api-base/usuarios.service';
  import UsuariosInvitaciones from '@/api-base/usuarios.invitaciones.service';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
    //import VueClipboard from 'vue-clipboard2'
//  import VueQriously from 'vue-qriously'

//  const VueClipboard = () => import(/* webpackChunkName: "main" */ 'vue-clipboard2');
  const VueQriously = () => import(/* webpackChunkName: "main" */ 'vue-qriously');
    export default {
        name: 'user-profile',
        data() {
            return {
                url: window.location.href,
                EsMia: false,
                Perfil: '',
                info: null,
                invitadoX: '',
                invitadoXTelefono: '',
                loading: true,
                errored: false,
                compartir: '',
                compartirWhatsapp: '',
                compartirMail: '',
                textoSuscripcion: '',
                aceptada : false,
            }
    },
    components: {
  //    VueClipboard,
      VueQriously
    },
        mounted() {
            console.log(window.location.pathname);
            console.log(this.$route.query);
            if (this.$route.query.perfilID !== undefined) {
                ///Crear nueva invitación
                UsuariosInvitaciones.PostUsuarioInvitacion({ perfilID: this.$route.query.perfilID }).then((response) => {


                    try {
                        //    console.log(response);
                        if (response.data !== undefined) {
                            // console.log(response.data);
                            this.url = this.url.replace(`perfilID=${this.$route.query.perfilID}`, `i=${response.data.usuarioAlternoID}`);
                            this.url = this.url.replace(window.location.pathname, "/");
                            this.CargaInvitacion(response.data);
                        }
                        else
                            this.errored = true;
                    }
                    catch (error2) {
                        console.error(error2);
                        this.errored = true;
                    }

                    // this.$router.push('/reuniones');
                }).catch((error) => {

                    console.error(error.response);
                    this.errored = true;
                    //alert(error.response.data.error.mensaje);
                });

                return;
            }
            ///Ver si es la invitación
            if (this.$route.query.i !== undefined) {
                this.loading = true;
                UsuariosInvitaciones.getAlternoID(this.$route.query.i).then((response) => {
                    try {
                        //    console.log(response);
                        if (response.data !== undefined) {
                            // console.log(response.data);

                            this.CargaInvitacion(response.data);
                            //  console.log(this.info);
                        }
                        else
                            this.errored = true;
                    }
                    catch (error2) {
                        console.error(error2);
                        this.errored = true;
                    }

                    // this.$router.push('/reuniones');
                }).catch((error) => {
                    this.errored = true;
                    console.error(error.response);

                    //alert(error.response.data.error.mensaje);
                });

            }
            return;

        },
        methods: {
            CargaInvitacion(invitacion) {
                //   console.log(invitacion);
                if (invitacion !== undefined) {

                    this.info = invitacion;
                }
                console.log(this.info);
                this.loading = false;
                this.invitadoX = this.info.usuario.nombre;
                this.invitadoXTelefono = this.info.usuario.telefono;
                if (Usuarios.EsUsuarioActual(this.info.usuario))
                    this.EsMia = true;
                this.Perfil = SuscripcionesUsuarios.ObtenPerfil(this.info.perfilID);
                this.textoSuscripcion = "";
                if (this.info.suscripcion !== undefined && this.info.suscripcion !== null && this.info.suscripcion.etiqueta !== null)
                    this.textoSuscripcion = `para ${this.info.suscripcion.etiqueta}`;
                this.compartir = `Me gustaría que te unieras como ${this.Perfil} para aceptar, ingresa a la siguiente liga: ${this.url}`;
                 this.compartirWhatsapp = "https://wa.me/?text=" + encodeURI(this.compartir.replace("/#/","/"));
                //this.compartirWhatsapp = `Me gustaría que te unieras como ${this.Perfil} para aceptar, ingresa a la siguiente liga: http://Prueba.com/esotraprueba/ls?i=ertyuiofdsadfghnvbcxzcvbfgdff`;

                this.compartirMail = "mailto:?subject=Invitación&body=" + encodeURI(this.compartir);
                if (this.$route.query.perfilID !== undefined) {
                    //this.$route.go(this.url);
                   // window.location.href = this.url;
                    this.$router.replace(`nuevo?i=${this.info.usuarioAlternoID}`);
                }
            },
            onCopy() {
                this.$notify({
                    type: 'success',
                    title: 'Se ha copiado al portapapeles'
                })
            },
            borrar: function () {

            },
            aceptar: function () {
                this.loading = true;
                ///Crear nueva invitación
                UsuariosInvitaciones.Aceptar(this.info.usuarioInvitacionID).then((response) => {

                    this.loading = false;
                    try {
                        //    console.log(response);
                        if (response.data !== undefined) {
                            this.aceptada = true;
                            
                        }
                        else
                            this.errored = true;
                    }
                    catch (error2) {
                        console.error(error2);
                        this.errored = true;
                    }

                    // this.$router.push('/reuniones');
                }).catch((error) => {

                    console.error(error.response);
                    this.errored = true;
                    //alert(error.response.data.error.mensaje);
                });
            },
        }
    };
</script>
<style></style>
